.popupVideoModal {
	min-height: 0vh !important;
	position: fixed !important;
}

meta.foundation-version {
	font-family: "/5.5.2/";
}

meta.foundation-mq-small {
	font-family: "/only screen/";
	width: 0em;
}

meta.foundation-mq-small-only {
	font-family: "/only screen and (max-width: 50em)/";
	width: 0em;
}

meta.foundation-mq-medium {
	font-family: "/only screen and (min-width:50.063em)/";
	width: 50.063em;
}

meta.foundation-mq-medium-only {
	font-family: "/only screen and (min-width:50.063em) and (max-width:64em)/";
	width: 50.063em;
}

meta.foundation-mq-large {
	font-family: "/only screen and (min-width:64.0625em)/";
	width: 64.0625em;
}

meta.foundation-mq-large-only {
	font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/";
	width: 64.0625em;
}

meta.foundation-mq-xlarge {
	font-family: "/only screen and (min-width:90.0625em)/";
	width: 90.0625em;
}

meta.foundation-mq-xlarge-only {
	font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
	width: 90.0625em;
}

meta.foundation-mq-xxlarge {
	font-family: "/only screen and (min-width:120.0625em)/";
	width: 120.0625em;
}

meta.foundation-data-attribute-namespace {
	font-family: false;
}

/* small displays */

/* medium displays */

/* large displays */

/* xlarge displays */

/* xxlarge displays */

/* Orientation targeting */

.show-for-landscape,
.hide-for-portrait {
	display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
	display: none !important;
}

/* Specific visibility for tables */

table.hide-for-landscape,
table.show-for-portrait {
	display: table !important;
}

thead.hide-for-landscape,
thead.show-for-portrait {
	display: table-header-group !important;
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
	display: table-row-group !important;
}

tr.hide-for-landscape,
tr.show-for-portrait {
	display: table-row !important;
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
	display: table-cell !important;
}

/* Touch-enabled device targeting */

.show-for-touch {
	display: none !important;
}

.hide-for-touch {
	display: inherit !important;
}

.touch .show-for-touch {
	display: inherit !important;
}

.touch .hide-for-touch {
	display: none !important;
}

/* Specific visibility for tables */

table.hide-for-touch {
	display: table !important;
}

.touch table.show-for-touch {
	display: table !important;
}

thead.hide-for-touch {
	display: table-header-group !important;
}

.touch thead.show-for-touch {
	display: table-header-group !important;
}

tbody.hide-for-touch {
	display: table-row-group !important;
}

.touch tbody.show-for-touch {
	display: table-row-group !important;
}

tr.hide-for-touch {
	display: table-row !important;
}

.touch tr.show-for-touch {
	display: table-row !important;
}

td.hide-for-touch {
	display: table-cell !important;
}

.touch td.show-for-touch {
	display: table-cell !important;
}

th.hide-for-touch {
	display: table-cell !important;
}

.touch th.show-for-touch {
	display: table-cell !important;
}

/* Screen reader-specific classes */

.show-for-sr {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

.show-on-focus {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

.show-on-focus:focus,
.show-on-focus:active {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

/*
       * Print styles.
       *
       * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
       * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
      */

.print-only {
	display: none !important;
}

/* Print visibility */

.popupVideoModal {
	min-height: 0vh !important;
	position: fixed !important;
}

h1.eprove-heading-large,
h2.eprove-heading-large,
h3.eprove-heading-large,
h4.eprove-heading-large,
h5.eprove-heading-large,
h6.eprove-heading-large {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	color: #333333;
	border: 0;
	font-size: 26px;
	line-height: 36px;
}

h1.eprove-heading-medium,
h2.eprove-heading-medium,
h3.eprove-heading-medium,
h4.eprove-heading-medium,
h5.eprove-heading-medium,
h6.eprove-heading-medium {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	color: #333333;
	border: 0;
	font-size: 22px;
	line-height: 30px;
}

h1.eprove-heading-small,
h2.eprove-heading-small,
h3.eprove-heading-small,
h4.eprove-heading-small,
h5.eprove-heading-small,
h6.eprove-heading-small {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	color: #333333;
	border: 0;
	font-size: 16px;
	line-height: 22px;
}

h1.eprove-subheading-large,
h2.eprove-subheading-large,
h3.eprove-subheading-large,
h4.eprove-subheading-large,
h5.eprove-subheading-large,
h6.eprove-subheading-large {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 300;
	color: #666;
	border: 0;
	font-size: 18px;
	line-height: 24px;
}

h1.eprove-subheading-medium,
h2.eprove-subheading-medium,
h3.eprove-subheading-medium,
h4.eprove-subheading-medium,
h5.eprove-subheading-medium,
h6.eprove-subheading-medium {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #AAAAAA;
	line-height: 22px;
	border: 0;
}

h1.eprove-subheading-small,
h2.eprove-subheading-small,
h3.eprove-subheading-small,
h4.eprove-subheading-small,
h5.eprove-subheading-small,
h6.eprove-subheading-small {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 300;
	color: #666;
	border: 0;
	font-size: 15px;
	line-height: 20px;
}

.text--padding-large {
	margin: 1.25rem 0;
}

.signup__subtitle--spacing {
	margin-bottom: 1.25rem;
}

.signup__title--padding {
	padding-bottom: 1.25rem;
}

.text-container--large {
	color: #999;
	font-size: 1rem;
	font-weight: 300;
	margin: 1.25rem 0 2.5rem;
}

.text--list {
	padding-left: 1.25rem;
}

.text--no-border {
	border: 0 !important;
}

.u-dark-text {
	color: #333;
}

.u-small-text {
	color: #aaa;
	font-size: 0.9375rem;
}

.u-light-text {
	color: #999;
}

.u-medium-color-text {
	color: #666;
}

.u-block-text {
	display: block;
}

.u-truncate-text {
	display: inline-block;
	width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.u-align-top {
	vertical-align: top;
}

.u-paragraph-text {
	font-weight: 400;
	color: #999;
	font-size: 1rem;
	line-height: 1.2;
}

.u-medium-text {
	font-size: 1rem;
}

.u-inline {
	display: inline-block;
}

.u-no-margin {
	margin: 0;
}

.u-no-padding {
	padding: 0;
}

.u-margin-bottom {
	margin-bottom: 0.625rem;
}

.u-add-margin-top {
	margin-top: 1rem;
}

.u-add-border-bottom,
h1.u-add-border-bottom,
h2.u-add-border-bottom,
h3.u-add-border-bottom,
h4.u-add-border-bottom,
h5.u-add-border-bottom,
h6.u-add-border-bottom {
	border-bottom: 1px solid #e5e5e5;
}

.link--eprove,
a.link--eprove {
	color: #0092DE;
	text-decoration: underline;
}

.link--eprove:hover,
.link--eprove:focus,
a.link--eprove:hover,
a.link--eprove:focus {
	color: #45bfff;
}

.link--eleot,
a.link--eleot {
	color: #06BFD1;
	text-decoration: underline;
}

.link--eleot:hover,
.link--eleot:focus,
a.link--eleot:hover,
a.link--eleot:focus {
	color: #43e9fa;
}

.link--surveys,
a.link--surveys {
	color: #6FC96F;
	text-decoration: underline;
}

.link--surveys:hover,
.link--surveys:focus,
a.link--surveys:hover,
a.link--surveys:focus {
	color: #b9e5b9;
}

.link--analytics,
a.link--analytics {
	color: #F4AA34;
	text-decoration: underline;
}

.link--analytics:hover,
.link--analytics:focus,
a.link--analytics:hover,
a.link--analytics:focus {
	color: #f9d395;
}

.link--diagnostics,
a.link--diagnostics {
	color: #3793D2;
	text-decoration: underline;
}

.link--diagnostics:hover,
.link--diagnostics:focus,
a.link--diagnostics:hover,
a.link--diagnostics:focus {
	color: #8ac0e5;
}

.link--strategies,
a.link--strategies {
	color: #DD6666;
	text-decoration: underline;
}

.link--strategies:hover,
.link--strategies:focus,
a.link--strategies:hover,
a.link--strategies:focus {
	color: #f0b9b9;
}

.link--workspace,
a.link--workspace {
	color: #883C8A;
	text-decoration: underline;
}

.link--workspace:hover,
.link--workspace:focus,
a.link--workspace:hover,
a.link--workspace:focus {
	color: #bd6dbf;
}

.link--secondary,
a.link--secondary {
	color: #999999;
	text-decoration: underline;
}

.link--secondary:hover,
.link--secondary:focus,
a.link--secondary:hover,
a.link--secondary:focus {
	color: #CCC;
}

.link--gray,
a.link--gray {
	color: #eee;
	text-decoration: underline;
}

.link--gray:hover,
.link--gray:focus,
a.link--gray:hover,
a.link--gray:focus {
	color: white;
}

link--eprove.mod-link,
a.link--eprove.mod-link,
link--eleot.mod-link,
a.link--eleot.mod-link,
link--surveys.mod-link,
a.link--surveys.mod-link,
link--analytics.mod-link,
a.link--analytics.mod-link,
link--diagnostics.mod-link,
a.link--diagnostics.mod-link,
link--strategies.mod-link,
a.link--strategies.mod-link,
link--workspace.mod-link,
a.link--workspace.mod-link,
link--secondary.mod-link,
a.link--secondary.mod-link,
link--gray.mod-link,
a.link--gray.mod-link {
	display: inline-block;
	margin-left: 10px;
}

.link--eleot.mod-more-licenses {
	margin-left: 0;
}

.link--eprove.mod-light-text,
.link--eleot.mod-light-text,
.link--surveys.mod-light-text,
.link--analytics.mod-light-text,
.link--diagnostics.mod-light-text,
.link--strategies.mod-light-text,
.link--workspace.mod-light-text,
.link--secondary.mod-light-text,
.link--gray.mod-light-text {
	color: #aaa;
}

.button--eprove {
	background-color: #0092DE;
	border-color: #0075b2;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eprove:hover {
	background-color: #19b0ff;
}

.button--eprove:focus {
	background-color: #0075b2;
}

.button--eprove:hover,
.button--eprove:focus {
	color: #fff;
}

.button--eprove.button--outline {
	background: transparent;
	color: #0092DE;
	border-width: 1px;
	border-color: #0092DE;
}

.button--eprove.disabled,
.button--eprove[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--eprove.disabled:hover,
.button--eprove[disabled]:hover {
	background-color: #d6d6d6;
}

.button--eprove.disabled:focus,
.button--eprove[disabled]:focus {
	background-color: #a3a3a3;
}

.button--eprove.disabled:hover,
.button--eprove.disabled:focus,
.button--eprove[disabled]:hover,
.button--eprove[disabled]:focus {
	color: #fff;
}

.button--eprove.disabled:hover,
.button--eprove.disabled:focus,
.button--eprove[disabled]:hover,
.button--eprove[disabled]:focus {
	background-color: #ccc;
}

.button--eprove.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eprove.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eprove.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eprove.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eprove.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eleot {
	background-color: #06BFD1;
	border-color: #0599a7;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eleot:hover {
	background-color: #1ae5f8;
}

.button--eleot:focus {
	background-color: #0599a7;
}

.button--eleot:hover,
.button--eleot:focus {
	color: #fff;
}

.button--eleot.button--outline {
	background: transparent;
	color: #06BFD1;
	border-width: 1px;
	border-color: #06BFD1;
}

.button--eleot.disabled,
.button--eleot[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--eleot.disabled:hover,
.button--eleot[disabled]:hover {
	background-color: #d6d6d6;
}

.button--eleot.disabled:focus,
.button--eleot[disabled]:focus {
	background-color: #a3a3a3;
}

.button--eleot.disabled:hover,
.button--eleot.disabled:focus,
.button--eleot[disabled]:hover,
.button--eleot[disabled]:focus {
	color: #fff;
}

.button--eleot.disabled:hover,
.button--eleot.disabled:focus,
.button--eleot[disabled]:hover,
.button--eleot[disabled]:focus {
	background-color: #ccc;
}

.button--eleot.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eleot.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eleot.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eleot.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--eleot.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--surveys {
	background-color: #6FC96F;
	border-color: #44b644;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--surveys:hover {
	background-color: #8cd48c;
}

.button--surveys:focus {
	background-color: #44b644;
}

.button--surveys:hover,
.button--surveys:focus {
	color: #fff;
}

.button--surveys.button--outline {
	background: transparent;
	color: #6FC96F;
	border-width: 1px;
	border-color: #6FC96F;
}

.button--surveys.disabled,
.button--surveys[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--surveys.disabled:hover,
.button--surveys[disabled]:hover {
	background-color: #d6d6d6;
}

.button--surveys.disabled:focus,
.button--surveys[disabled]:focus {
	background-color: #a3a3a3;
}

.button--surveys.disabled:hover,
.button--surveys.disabled:focus,
.button--surveys[disabled]:hover,
.button--surveys[disabled]:focus {
	color: #fff;
}

.button--surveys.disabled:hover,
.button--surveys.disabled:focus,
.button--surveys[disabled]:hover,
.button--surveys[disabled]:focus {
	background-color: #ccc;
}

.button--surveys.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--surveys.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--surveys.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--surveys.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--surveys.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--analytics {
	background-color: #F4AA34;
	border-color: #e18f0c;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--analytics:hover {
	background-color: #f6bb5d;
}

.button--analytics:focus {
	background-color: #e18f0c;
}

.button--analytics:hover,
.button--analytics:focus {
	color: #fff;
}

.button--analytics.button--outline {
	background: transparent;
	color: #F4AA34;
	border-width: 1px;
	border-color: #F4AA34;
}

.button--analytics.disabled,
.button--analytics[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--analytics.disabled:hover,
.button--analytics[disabled]:hover {
	background-color: #d6d6d6;
}

.button--analytics.disabled:focus,
.button--analytics[disabled]:focus {
	background-color: #a3a3a3;
}

.button--analytics.disabled:hover,
.button--analytics.disabled:focus,
.button--analytics[disabled]:hover,
.button--analytics[disabled]:focus {
	color: #fff;
}

.button--analytics.disabled:hover,
.button--analytics.disabled:focus,
.button--analytics[disabled]:hover,
.button--analytics[disabled]:focus {
	background-color: #ccc;
}

.button--analytics.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--analytics.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--analytics.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--analytics.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--analytics.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--diagnostics {
	background-color: #3793D2;
	border-color: #2777ad;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--diagnostics:hover {
	background-color: #5fa9db;
}

.button--diagnostics:focus {
	background-color: #2777ad;
}

.button--diagnostics:hover,
.button--diagnostics:focus {
	color: #fff;
}

.button--diagnostics.button--outline {
	background: transparent;
	color: #3793D2;
	border-width: 1px;
	border-color: #3793D2;
}

.button--diagnostics.disabled,
.button--diagnostics[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--diagnostics.disabled:hover,
.button--diagnostics[disabled]:hover {
	background-color: #d6d6d6;
}

.button--diagnostics.disabled:focus,
.button--diagnostics[disabled]:focus {
	background-color: #a3a3a3;
}

.button--diagnostics.disabled:hover,
.button--diagnostics.disabled:focus,
.button--diagnostics[disabled]:hover,
.button--diagnostics[disabled]:focus {
	color: #fff;
}

.button--diagnostics.disabled:hover,
.button--diagnostics.disabled:focus,
.button--diagnostics[disabled]:hover,
.button--diagnostics[disabled]:focus {
	background-color: #ccc;
}

.button--diagnostics.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--diagnostics.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--diagnostics.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--diagnostics.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--diagnostics.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--strategies {
	background-color: #DD6666;
	border-color: #d13131;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--strategies:hover {
	background-color: #e48585;
}

.button--strategies:focus {
	background-color: #d13131;
}

.button--strategies:hover,
.button--strategies:focus {
	color: #fff;
}

.button--strategies.button--outline {
	background: transparent;
	color: #DD6666;
	border-width: 1px;
	border-color: #DD6666;
}

.button--strategies.disabled,
.button--strategies[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--strategies.disabled:hover,
.button--strategies[disabled]:hover {
	background-color: #d6d6d6;
}

.button--strategies.disabled:focus,
.button--strategies[disabled]:focus {
	background-color: #a3a3a3;
}

.button--strategies.disabled:hover,
.button--strategies.disabled:focus,
.button--strategies[disabled]:hover,
.button--strategies[disabled]:focus {
	color: #fff;
}

.button--strategies.disabled:hover,
.button--strategies.disabled:focus,
.button--strategies[disabled]:hover,
.button--strategies[disabled]:focus {
	background-color: #ccc;
}

.button--strategies.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--strategies.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--strategies.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--strategies.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--strategies.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--workspace {
	background-color: #883C8A;
	border-color: #6d306e;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--workspace:hover {
	background-color: #b151b3;
}

.button--workspace:focus {
	background-color: #6d306e;
}

.button--workspace:hover,
.button--workspace:focus {
	color: #fff;
}

.button--workspace.button--outline {
	background: transparent;
	color: #883C8A;
	border-width: 1px;
	border-color: #883C8A;
}

.button--workspace.disabled,
.button--workspace[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--workspace.disabled:hover,
.button--workspace[disabled]:hover {
	background-color: #d6d6d6;
}

.button--workspace.disabled:focus,
.button--workspace[disabled]:focus {
	background-color: #a3a3a3;
}

.button--workspace.disabled:hover,
.button--workspace.disabled:focus,
.button--workspace[disabled]:hover,
.button--workspace[disabled]:focus {
	color: #fff;
}

.button--workspace.disabled:hover,
.button--workspace.disabled:focus,
.button--workspace[disabled]:hover,
.button--workspace[disabled]:focus {
	background-color: #ccc;
}

.button--workspace.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--workspace.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--workspace.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--workspace.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--workspace.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--secondary {
	background-color: #CCC;
	border-color: #a3a3a3;
	border-radius: 3px;
	box-shadow: none;
	color: #fff;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--secondary:hover {
	background-color: #d6d6d6;
}

.button--secondary:focus {
	background-color: #a3a3a3;
}

.button--secondary:hover,
.button--secondary:focus {
	color: #fff;
}

.button--secondary.button--outline {
	background: transparent;
	color: #CCC;
	border-width: 1px;
	border-color: #CCC;
}

.button--secondary.disabled,
.button--secondary[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--secondary.disabled:hover,
.button--secondary[disabled]:hover {
	background-color: #d6d6d6;
}

.button--secondary.disabled:focus,
.button--secondary[disabled]:focus {
	background-color: #a3a3a3;
}

.button--secondary.disabled:hover,
.button--secondary.disabled:focus,
.button--secondary[disabled]:hover,
.button--secondary[disabled]:focus {
	color: #fff;
}

.button--secondary.disabled:hover,
.button--secondary.disabled:focus,
.button--secondary[disabled]:hover,
.button--secondary[disabled]:focus {
	background-color: #ccc;
}

.button--secondary.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--secondary.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--secondary.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--secondary.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--secondary.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--gray {
	background-color: #eee;
	border-color: #bebebe;
	border-radius: 3px;
	box-shadow: none;
	color: #979797;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--gray:hover {
	background-color: #f1f1f1;
}

.button--gray:focus {
	background-color: #bebebe;
}

.button--gray:hover,
.button--gray:focus {
	color: #fff;
}

.button--gray:hover,
.button--gray:focus {
	color: #979797;
}

.button--gray.button--outline {
	background: transparent;
	color: #eee;
	border-width: 1px;
	border-color: #eee;
}

.button--gray.disabled,
.button--gray[disabled] {
	background-color: #ccc;
	border-color: #a3a3a3;
	box-shadow: none;
	cursor: default;
	opacity: .7;
	opacity: 1;
}

.button--gray.disabled:hover,
.button--gray[disabled]:hover {
	background-color: #d6d6d6;
}

.button--gray.disabled:focus,
.button--gray[disabled]:focus {
	background-color: #a3a3a3;
}

.button--gray.disabled:hover,
.button--gray.disabled:focus,
.button--gray[disabled]:hover,
.button--gray[disabled]:focus {
	color: #fff;
}

.button--gray.disabled:hover,
.button--gray.disabled:focus,
.button--gray[disabled]:hover,
.button--gray[disabled]:focus {
	background-color: #ccc;
}

.button--gray.mod-huge {
	font-size: 1.5rem;
	line-height: 54px;
	height: 54px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--gray.mod-large {
	font-size: 1.125rem;
	line-height: 48px;
	height: 48px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--gray.mod-standard {
	font-size: 0.875rem;
	line-height: 40px;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--gray.mod-small {
	font-size: 0.75rem;
	line-height: 32px;
	height: 32px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--gray.mod-mini {
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.button--outline.mod-inverse {
	background: transparent;
	color: #fff;
	border-width: 1px;
	border-color: #fff;
}

.button--light {
	font-weight: 300;
}

.button--normal {
	font-weight: 400;
}

.button--semibold {
	font-weight: 600;
}

.button.clear {
	background-color: transparent;
	border-color: transparent;
	color: #979797;
	border-color: transparent;
	box-shadow: none;
}

.button.clear:hover {
	background-color: rgba(51, 51, 51, 0);
}

.button.clear:focus {
	background-color: transparent;
}

.button.clear:hover,
.button.clear:focus {
	color: #fff;
}

.button.clear:hover,
.button.clear:active,
.button.clear:focus {
	color: #979797;
	background: transparent;
}

.button.mod-cancel {
	background-color: #F3F3F3;
}

.button.mod-cancel:hover {
	background: whitesmoke;
}

.button.mod-modal-cancel {
	font-size: 1.25rem;
}

.button.mod-text-size {
	font-size: 1.125rem;
	font-weight: bold;
}

.button.mod-cart-buttons {
	font-weight: 600;
	font-size: 0.9375rem;
}

.button.mod-remove-margin {
	margin-bottom: 0;
}

.eprove-button-bar:before,
.eprove-button-bar:after {
	content: " ";
	display: table;
}

.eprove-button-bar:after {
	clear: both;
}

.eprove-button-group {
	float: left;
	margin-right: 0.625rem;
	margin: 0;
}

.eprove-button-group div {
	overflow: hidden;
}

.eprove-button-group > li {
	display: inline-block;
	margin: 0 -2px;
}

.eprove-button-group > li > button,
.eprove-button-group > li .button {
	border-left: 1px solid;
	border-color: rgba(255, 255, 255, 0.5);
}

.eprove-button-group > li:first-child button,
.eprove-button-group > li:first-child .button {
	border-left: 0;
}

.eprove-button-group > li,
.eprove-button-group > li > a,
.eprove-button-group > li > button,
.eprove-button-group > li > .button {
	border-radius: 0;
}

.eprove-button-group > li:first-child,
.eprove-button-group > li:first-child > a,
.eprove-button-group > li:first-child > button,
.eprove-button-group > li:first-child > .button {
	-webkit-border-bottom-left-radius: 5px;
	-webkit-border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
}

.eprove-button-group > li:last-child,
.eprove-button-group > li:last-child > a,
.eprove-button-group > li:last-child > button,
.eprove-button-group > li:last-child > .button {
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
}

.eprove-button-group > li .button {
	border-color: #ccc;
	border-bottom: 1px solid #ccc;
}

.eprove-button-group > li:first-child .button {
	border-left: 1px solid #ccc;
	margin-left: 0;
}

.eprove-button-bar-button,
a.eprove-button-bar-button {
	background-color: #fafafa;
	border-color: #c8c8c8;
	color: #666;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 400;
	font-size: 1rem;
	border-width: 1px;
	box-shadow: none;
	margin: 0;
	margin-left: -1px;
}

.eprove-button-bar-button:hover,
a.eprove-button-bar-button:hover {
	background-color: #fbfbfb;
}

.eprove-button-bar-button:focus,
a.eprove-button-bar-button:focus {
	background-color: #c8c8c8;
}

.eprove-button-bar-button:hover,
.eprove-button-bar-button:focus,
a.eprove-button-bar-button:hover,
a.eprove-button-bar-button:focus {
	color: #fff;
}

.eprove-button-bar-button:hover,
a.eprove-button-bar-button:hover {
	color: #666;
}

.eprove-button-bar-button.selected,
a.eprove-button-bar-button.selected {
	background: #e3e3e3;
}

.global-text-input,
input.global-text-input[type="text"],
input.global-text-input[type="password"],
input.global-text-input[type="date"],
input.global-text-input[type="datetime"],
input.global-text-input[type="datetime-local"],
input.global-text-input[type="month"],
input.global-text-input[type="week"],
input.global-text-input[type="email"],
input.global-text-input[type="number"],
input.global-text-input[type="search"],
input.global-text-input[type="tel"],
input.global-text-input[type="time"],
input.global-text-input[type="url"],
input.global-text-input[type="color"],
textareainput.global-text-input {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 2px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 400;
	font-size: 15px;
	color: #777;
	line-height: 40px;
	height: 40px;
	-webkit-appearance: none;
	-moz-appearance: none;
	padding-top: 0;
	padding-bottom: 0;
}

.global-text-input ::-webkit-input-placeholder,
input.global-text-input[type="text"] ::-webkit-input-placeholder,
input.global-text-input[type="password"] ::-webkit-input-placeholder,
input.global-text-input[type="date"] ::-webkit-input-placeholder,
input.global-text-input[type="datetime"] ::-webkit-input-placeholder,
input.global-text-input[type="datetime-local"] ::-webkit-input-placeholder,
input.global-text-input[type="month"] ::-webkit-input-placeholder,
input.global-text-input[type="week"] ::-webkit-input-placeholder,
input.global-text-input[type="email"] ::-webkit-input-placeholder,
input.global-text-input[type="number"] ::-webkit-input-placeholder,
input.global-text-input[type="search"] ::-webkit-input-placeholder,
input.global-text-input[type="tel"] ::-webkit-input-placeholder,
input.global-text-input[type="time"] ::-webkit-input-placeholder,
input.global-text-input[type="url"] ::-webkit-input-placeholder,
input.global-text-input[type="color"] ::-webkit-input-placeholder,
textareainput.global-text-input ::-webkit-input-placeholder {
	color: #aaa;
}

.global-text-input :-moz-placeholder,
input.global-text-input[type="text"] :-moz-placeholder,
input.global-text-input[type="password"] :-moz-placeholder,
input.global-text-input[type="date"] :-moz-placeholder,
input.global-text-input[type="datetime"] :-moz-placeholder,
input.global-text-input[type="datetime-local"] :-moz-placeholder,
input.global-text-input[type="month"] :-moz-placeholder,
input.global-text-input[type="week"] :-moz-placeholder,
input.global-text-input[type="email"] :-moz-placeholder,
input.global-text-input[type="number"] :-moz-placeholder,
input.global-text-input[type="search"] :-moz-placeholder,
input.global-text-input[type="tel"] :-moz-placeholder,
input.global-text-input[type="time"] :-moz-placeholder,
input.global-text-input[type="url"] :-moz-placeholder,
input.global-text-input[type="color"] :-moz-placeholder,
textareainput.global-text-input :-moz-placeholder { /* Firefox 18- */
	color: #aaa;
}

.global-text-input ::-moz-placeholder,
input.global-text-input[type="text"] ::-moz-placeholder,
input.global-text-input[type="password"] ::-moz-placeholder,
input.global-text-input[type="date"] ::-moz-placeholder,
input.global-text-input[type="datetime"] ::-moz-placeholder,
input.global-text-input[type="datetime-local"] ::-moz-placeholder,
input.global-text-input[type="month"] ::-moz-placeholder,
input.global-text-input[type="week"] ::-moz-placeholder,
input.global-text-input[type="email"] ::-moz-placeholder,
input.global-text-input[type="number"] ::-moz-placeholder,
input.global-text-input[type="search"] ::-moz-placeholder,
input.global-text-input[type="tel"] ::-moz-placeholder,
input.global-text-input[type="time"] ::-moz-placeholder,
input.global-text-input[type="url"] ::-moz-placeholder,
input.global-text-input[type="color"] ::-moz-placeholder,
textareainput.global-text-input ::-moz-placeholder { /* Firefox 19+ */
	color: #aaa;
}

.global-text-input :-ms-input-placeholder,
input.global-text-input[type="text"] :-ms-input-placeholder,
input.global-text-input[type="password"] :-ms-input-placeholder,
input.global-text-input[type="date"] :-ms-input-placeholder,
input.global-text-input[type="datetime"] :-ms-input-placeholder,
input.global-text-input[type="datetime-local"] :-ms-input-placeholder,
input.global-text-input[type="month"] :-ms-input-placeholder,
input.global-text-input[type="week"] :-ms-input-placeholder,
input.global-text-input[type="email"] :-ms-input-placeholder,
input.global-text-input[type="number"] :-ms-input-placeholder,
input.global-text-input[type="search"] :-ms-input-placeholder,
input.global-text-input[type="tel"] :-ms-input-placeholder,
input.global-text-input[type="time"] :-ms-input-placeholder,
input.global-text-input[type="url"] :-ms-input-placeholder,
input.global-text-input[type="color"] :-ms-input-placeholder,
textareainput.global-text-input :-ms-input-placeholder {
	color: #aaa;
}

.global-text-input:disabled,
.global-text-input.is-disabled,
input.global-text-input:disabled[type="text"],
input.global-text-input:disabled[type="password"],
input.global-text-input:disabled[type="date"],
input.global-text-input:disabled[type="datetime"],
input.global-text-input:disabled[type="datetime-local"],
input.global-text-input:disabled[type="month"],
input.global-text-input:disabled[type="week"],
input.global-text-input:disabled[type="email"],
input.global-text-input:disabled[type="number"],
input.global-text-input:disabled[type="search"],
input.global-text-input:disabled[type="tel"],
input.global-text-input:disabled[type="time"],
input.global-text-input:disabled[type="url"],
input.global-text-input:disabled[type="color"],
textareainput.global-text-input:disabled,
input.global-text-input.is-disabled[type="text"],
input.global-text-input.is-disabled[type="password"],
input.global-text-input.is-disabled[type="date"],
input.global-text-input.is-disabled[type="datetime"],
input.global-text-input.is-disabled[type="datetime-local"],
input.global-text-input.is-disabled[type="month"],
input.global-text-input.is-disabled[type="week"],
input.global-text-input.is-disabled[type="email"],
input.global-text-input.is-disabled[type="number"],
input.global-text-input.is-disabled[type="search"],
input.global-text-input.is-disabled[type="tel"],
input.global-text-input.is-disabled[type="time"],
input.global-text-input.is-disabled[type="url"],
input.global-text-input.is-disabled[type="color"],
textareainput.global-text-input.is-disabled {
	background-color: #f6f6f6;
}

.global-text-input.mod-dark,
input.global-text-input.mod-dark[type="text"],
input.global-text-input.mod-dark[type="password"],
input.global-text-input.mod-dark[type="date"],
input.global-text-input.mod-dark[type="datetime"],
input.global-text-input.mod-dark[type="datetime-local"],
input.global-text-input.mod-dark[type="month"],
input.global-text-input.mod-dark[type="week"],
input.global-text-input.mod-dark[type="email"],
input.global-text-input.mod-dark[type="number"],
input.global-text-input.mod-dark[type="search"],
input.global-text-input.mod-dark[type="tel"],
input.global-text-input.mod-dark[type="time"],
input.global-text-input.mod-dark[type="url"],
input.global-text-input.mod-dark[type="color"],
textareainput.global-text-input.mod-dark {
	background-color: #f6f6f6;
}

.global-text-input.mod-large,
input.global-text-input.mod-large[type="text"],
input.global-text-input.mod-large[type="password"],
input.global-text-input.mod-large[type="date"],
input.global-text-input.mod-large[type="datetime"],
input.global-text-input.mod-large[type="datetime-local"],
input.global-text-input.mod-large[type="month"],
input.global-text-input.mod-large[type="week"],
input.global-text-input.mod-large[type="email"],
input.global-text-input.mod-large[type="number"],
input.global-text-input.mod-large[type="search"],
input.global-text-input.mod-large[type="tel"],
input.global-text-input.mod-large[type="time"],
input.global-text-input.mod-large[type="url"],
input.global-text-input.mod-large[type="color"],
textareainput.global-text-input.mod-large {
	line-height: 47px;
	height: 47px;
}

.global-text-input.mod-small,
input.global-text-input.mod-small[type="text"],
input.global-text-input.mod-small[type="password"],
input.global-text-input.mod-small[type="date"],
input.global-text-input.mod-small[type="datetime"],
input.global-text-input.mod-small[type="datetime-local"],
input.global-text-input.mod-small[type="month"],
input.global-text-input.mod-small[type="week"],
input.global-text-input.mod-small[type="email"],
input.global-text-input.mod-small[type="number"],
input.global-text-input.mod-small[type="search"],
input.global-text-input.mod-small[type="tel"],
input.global-text-input.mod-small[type="time"],
input.global-text-input.mod-small[type="url"],
input.global-text-input.mod-small[type="color"],
textareainput.global-text-input.mod-small {
	line-height: 33px;
	height: 33px;
	font-size: 13px;
}

.global-text-input.is-alert,
input.global-text-input.is-alert[type="text"],
input.global-text-input.is-alert[type="password"],
input.global-text-input.is-alert[type="date"],
input.global-text-input.is-alert[type="datetime"],
input.global-text-input.is-alert[type="datetime-local"],
input.global-text-input.is-alert[type="month"],
input.global-text-input.is-alert[type="week"],
input.global-text-input.is-alert[type="email"],
input.global-text-input.is-alert[type="number"],
input.global-text-input.is-alert[type="search"],
input.global-text-input.is-alert[type="tel"],
input.global-text-input.is-alert[type="time"],
input.global-text-input.is-alert[type="url"],
input.global-text-input.is-alert[type="color"],
textareainput.global-text-input.is-alert {
	border: 1px solid #E03B3B;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
	display: inline-block;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;
	vertical-align: baseline;
}

.global-checkbox.mod-inline {
	display: inline-block;
}

.global-checkbox-input {
	display: none;
}

.global-checkbox-label:before {
	content: ' ';
	display: inline-block;
	width: 18px;
	height: 18px;
	background: #fafafa;
	border: 1px solid #ccc;
	vertical-align: middle;
	text-align: center;
	line-height: 18px;
	font-size: 0.8125rem;
	font-family: 'eleoticons';
	color: #666;
	margin-right: 0.9375rem;
	border-radius: 3px;
}

.global-checkbox:hover .global-checkbox-label:before {
	border: 1px solid #666;
}

.global-checkbox-input:disabled + .global-checkbox-label:before {
	color: #b3b3b3;
	background: #fdfdfd;
	border: 1px solid #e6e6e6;
}

.global-checkbox:hover .global-checkbox-input:disabled + .global-checkbox-label:before {
	content: ' ';
}

.global-checkbox-input:checked + .global-checkbox-label:before,
.global-checkbox:hover .global-checkbox-input:checked:disabled + .global-checkbox-label:before {
	content: '\0066';
}

.global-checkbox-label-text,
.create-observation label span.global-checkbox-label-text,
.admin-add-user label span.global-checkbox-label-text,
.admin-upload-roster label span.global-checkbox-label-text {
	display: inline-block;
	color: #333;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125rem;
	vertical-align: middle;
	float: none;
	margin: 0;
	font-style: normal;
}

.global-checkbox-label.mod-inline-block-label-text span {
	display: inline-block;
	width: 90%;
}

.global-checkbox-label.mod-inline-block-label-text:before {
	display: inline-block;
	vertical-align: top;
	margin-top: 5px;
}

.global-radio.mod-inline {
	display: inline-block;
}

.global-radio-input {
	display: none;
}

.global-radio-label:before {
	content: ' ';
	display: inline-block;
	width: 18px;
	height: 18px;
	background: #fafafa;
	border: 1px solid #ccc;
	vertical-align: middle;
	text-align: center;
	line-height: 15px;
	font-size: 1.125rem;
	color: #666;
	margin-right: 0.9375rem;
	border-radius: 50%;
	box-shadow: inset 0px 1px 0px 0px #ECECEC;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.global-radio:hover .global-radio-label:before {
	content: ' ';
	border: 1px solid #666;
}

.global-radio-input:disabled + .global-radio-label:before {
	color: #b3b3b3;
	background: #fdfdfd;
	border: 1px solid #e6e6e6;
	box-shadow: inset 0px 1px 0px 0px #f6f6f6;
}

.global-radio:hover .global-radio-input:disabled + .global-radio-label:before {
	content: ' ';
}

.global-radio-input:checked + .global-radio-label:before,
.global-radio:hover .global-radio-input:checked:disabled + .global-radio-label:before {
	content: '\25cf';
}

.global-radio-label-text {
	display: inline-block;
	color: #333;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125rem;
	vertical-align: middle;
	float: none;
	margin: 0;
	font-style: normal;
}

.eprove-content-container {
	padding-bottom: 180px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.eprove-content-container ::-webkit-input-placeholder {
	color: #aaa;
}

.eprove-content-container :-moz-placeholder { /* Firefox 18- */
	color: #aaa;
}

.eprove-content-container ::-moz-placeholder { /* Firefox 19+ */
	color: #aaa;
}

.eprove-content-container :-ms-input-placeholder {
	color: #aaa;
}

.eprove-content-container .row.fullwidth {
	max-width: none;
}

.row.mod-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.row.mod-vertical-center {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.eprove-hr,
hr.eprove-hr {
	border: solid #e5e5e5;
	border-width: 1px 0 0;
	clear: both;
	height: 0;
	margin: 0.9375rem 0;
}

.sidebar__footer {
	box-sizing: border-box;
}

.eprove-buttons-container {
	border-top: 1px solid #ddd;
	padding-top: 0.625rem;
}

.eprove-buttons-container .button {
	color: #333;
	font-size: 0.875rem;
	margin-right: 0.625rem;
	margin-bottom: 0;
}

.page-header.mod-license-management-header {
	border-bottom: none;
}

.page-header.mod-header-spacing {
	margin-top: 2.5rem;
	padding-bottom: 0;
}

.page-header.mod-header-spacing.u-add-border-bottom {
	padding-bottom: 15px;
}

.go-back {
	color: #777;
	padding: 1.875rem 0;
}

.go-back a {
	color: #777;
	font-size: 0.9375rem;
	font-weight: 300;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.go-back .icon {
	vertical-align: middle;
}

.go-back span:not(.icon) {
	text-decoration: underline;
}

.go-back.mod-padding {
	padding: 0.9375rem 0;
}

.app-bar + .page-container {
	padding-top: 50px;
	height: calc(100% - 70px);
}

.app-bar + .page-container.mod-nospace {
	padding-top: 0;
}

.app-bar + .page-container.mod-nospace .eprove-content-container {
	padding-bottom: 90px;
}

.top-bar.app-bar {
	text-align: center;
	background: #f8f8f8;
	height: 70px;
	line-height: 70px;
}

.top-bar.app-bar .title-area {
	position: absolute;
	height: 100%;
	float: left;
	margin: 0;
}

.top-bar.app-bar .title-area img {
	max-height: 40px;
	width: auto;
}

.top-bar.app-bar .name {
	padding: 0;
	height: 70px;
	text-align: left;
}

.top-bar.app-bar .name h1 {
	line-height: 70px;
}

.top-bar.app-bar .name h1 a {
	vertical-align: 0;
}

.top-bar.app-bar .top-bar-section.center {
	position: absolute;
	width: 400px;
	text-align: center;
	margin: auto;
	left: 0;
	right: 0;
}

.top-bar.app-bar .top-bar-section.center ul.center {
	width: 100%;
	display: block;
}

.top-bar.app-bar .top-bar-section.center ul.center li {
	float: none;
	color: #777;
	background: transparent;
	font-size: 1.5625rem;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 300;
	height: 70px;
	line-height: 70px;
}

.top-bar.app-bar .top-bar-section li {
	background: none;
}

.top-bar.app-bar .button.button--secondary {
	color: #999;
	font-size: 1rem;
	font-weight: 400;
	border: 2px solid #ccc;
	background: transparent;
	margin-top: 13px;
	height: auto;
	top: 0;
	border-radius: 3px;
	padding-top: 0;
	padding-bottom: 0;
}

.top-bar.app-bar .right {
	margin-right: 20px;
}

.top-bar .right-off-canvas-toggle {
	font-size: 0.75rem;
	line-height: 34px;
	height: 34px;
	font-weight: 600;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	top: 0;
	right: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

.top-bar.mod-no-observe .right {
	display: none;
}

.table-heading {
	cursor: pointer;
}

.table-heading.mod-selected {
	text-decoration: underline;
}

.table-heading-sort {
	display: inline-block;
	vertical-align: middle;
	height: 14px;
	line-height: 14px;
	margin-left: 5px;
}

.table-row-data {
	color: #666;
	font-size: 1rem;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 400;
	vertical-align: top;
}

.table-data.mod-vertical-align-center,
.table-row-data.mod-vertical-center {
	vertical-align: middle;
}

.table-data,
.table-row-data.mod-vertical-top {
	vertical-align: top;
}

.table-data.mod-max-width {
	max-width: 170px;
}

.table-row-options-toggle {
	color: #aaa;
}

.table-row-options-toggle:hover {
	color: #999;
}

.table-row-options {
	display: none;
	left: -9999px;
	list-style: none;
	margin-left: 0;
	position: absolute;
	background: #fff;
	border: solid 0 #cccccc;
	font-size: 0.875rem;
	height: auto;
	max-height: none;
	width: 100%;
	z-index: 89;
	max-width: 200px;
	color: #666;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 400;
	box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.5);
	text-align: left;
	width: 100%;
	left: 0;
	background: #f6f6f6;
	margin: 0;
	list-style: none;
	box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.5), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
	border-radius: 2px;
}

.table-row-options.open {
	display: block;
}

.table-row-options > *:first-child {
	margin-top: 0;
}

.table-row-options > *:last-child {
	margin-bottom: 0;
}

.has-dropdown {
	position: relative;
}

.has-dropdown .table-row-options-item:after {
	content: '\0069';
	font-family: "eleoticons";
	display: inline-block;
	height: 18px;
	line-height: 18px;
	position: absolute;
	margin: auto;
	right: 5px;
	bottom: 0;
	top: 0;
}

.table-row-options-item {
	display: block;
	color: #333;
	padding: 0 0.625rem;
	border: 0;
	height: 3.125rem;
	line-height: 3.125rem;
	font-size: 1rem;
}

.table-row-options-item:hover {
	color: #333;
	background: #eee;
}

.table-row-options-icon {
	color: #000;
	font-size: 1.125rem;
	vertical-align: middle;
	margin-right: 1rem;
}

.table-row-options-icon.mod-normalize-larger {
	font-size: 1.375rem;
}

.table-row-options.is-nested {
	display: none;
	position: absolute;
	margin-left: 0;
	left: 150px;
	margin-top: -30px;
}

.right .table-row-options.is-nested {
	left: auto;
	right: 150px;
}

.table-row-options.is-nested li {
	position: relative;
}

.table-row-options.is-nested label {
	color: #666;
	font-size: 0.875rem;
	font-weight: 400;
	display: inline-block;
	padding: 0.3125rem 0.625rem;
}

li:hover .table-row-options.is-nested {
	display: block;
}

.table-row-options-edit {
	display: none;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	right: 10px;
	color: #999;
	font-size: 1.25rem;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
}

.table-row-options.is-nested li:hover .table-row-options-edit {
	display: block;
}

.table-row-options-checkbox,
input[type="checkbox"].table-row-options-checkbox {
	margin-right: 10px;
}

.table-row-options-actions {
	border-top: 1px solid #ccc;
}

.table-row-options-actions a {
	font-size: 0.9375rem;
	color: #4A90E2;
	padding: 0;
}

.table-row-options-actions .row {
	padding: 0.625rem;
}

.table-row-options-actions input[type="text"] {
	font-size: 0.75rem;
	border: 1px solid #aaa;
}

.table-row-options-actions .button {
	font-size: 0.75rem;
	font-weight: 400;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 31px;
	margin: 0;
}

.table-row-data-wrapper {
	position: relative;
	height: 100%;
	min-height: 42px;
}

.circle-container {
	position: absolute;
	width: 42px;
	height: 42px;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	float: none;
	overflow: hidden;
}

.search-filter__wrap {
	position: relative;
}

.table.mod-responsive .table-heading-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.table.mod-responsive .table-heading {
	color: #333;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 300;
	height: 35px;
	line-height: 35px;
	border: 0;
	box-shadow: none;
	background: #f7f7f7;
	padding: 0 5px;
	border-bottom: 1px solid #ddd;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.table.mod-responsive .table-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid #e5e5e5;
}

.table.mod-responsive .table-row-data {
	padding: 5px;
}

.table-heading.mod-width-5,
.table-row-data.mod-width-5 {
	width: 5%;
}

.table-heading.mod-width-10,
.table-row-data.mod-width-10 {
	width: 10%;
}

.table-heading.mod-width-15,
.table-row-data.mod-width-15 {
	width: 15%;
}

.table-heading.mod-width-20,
.table-row-data.mod-width-20 {
	width: 20%;
}

.table-row-section.mod-flex-v-center {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 85%;
	padding: 5px 0;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-5,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-5 {
	width: 50%;
	padding: 0 5px;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-10,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-10 {
	width: 50%;
	padding: 0 5px;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-15,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-15 {
	width: 50%;
	padding: 0 5px;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-20,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-20 {
	width: 50%;
	padding: 0 5px;
}

.table-row-data.mod-table-checkbox .global-checkbox {
	text-align: center;
}

.table-row-data.mod-table-checkbox .global-checkbox-label:before {
	content: ' ';
	display: inline-block;
	width: 20px;
	height: 20px;
	background: #fff;
	border: 1px solid #fff;
	vertical-align: middle;
	text-align: center;
	line-height: 20px;
	font-size: 1.25rem;
	font-family: 'eleoticons';
	color: #06BFD1;
	margin-right: 0;
	border-radius: 3px;
}

.table-row-data.mod-table-checkbox .global-checkbox:hover .global-checkbox-label:before {
	border: 1px solid #fff;
}

.table-row-data.mod-table-checkbox .global-checkbox-input:disabled + .global-checkbox-label:before {
	color: #06BFD1;
	background: #fff;
	border: 1px solid #fff;
}

.table-row-data.mod-table-checkbox .global-checkbox:hover .global-checkbox-input:disabled + .global-checkbox-label:before {
	content: ' ';
}

.table-row-data.mod-table-checkbox .global-checkbox-input:checked + .global-checkbox-label:before,
.table-row-data.mod-table-checkbox .global-checkbox:hover .global-checkbox-input:checked:disabled + .global-checkbox-label:before {
	content: '\0066';
}

.global-table-checkbox {
	text-align: center;
	vertical-align: middle;
}

.global-table-checkbox input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: 1px solid #ccc;
	padding: 7px;
	border-radius: 2px;
	display: inline-block;
	position: relative;
	margin: 0;
	height: auto;
	height: 1.875rem;
	border: none;
	line-height: 1.4;
	cursor: default;
}

.global-table-checkbox input[type="checkbox"]:focus {
	outline: none;
}

.global-table-checkbox input[type="checkbox"]:checked {
	padding: 7px;
	color: #ccc;
	height: 1.875rem;
	cursor: default;
}

.global-table-checkbox input[type="checkbox"]:checked:after {
	font-family: eleoticons;
	content: '\0066';
	color: #979797;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	font-size: 1.25rem;
	color: #00BFD1;
	line-height: 1.4;
}

.global-table-checkbox input[type="checkbox"]:checked:focus {
	outline: none;
}

.table-row-data.mod-table-radio .global-radio {
	text-align: center;
}

.table-row-data.mod-table-radio .global-radio-label:before {
	content: ' ';
	display: inline-block;
	width: 20px;
	height: 20px;
	background: #fff;
	border: 1px solid #fff;
	vertical-align: middle;
	text-align: center;
	line-height: 20px;
	font-size: 1.25rem;
	font-family: 'eleoticons';
	color: #06BFD1;
	margin-right: 0;
	border-radius: 3px;
	box-shadow: none;
}

.table-row-data.mod-table-radio .global-radio:hover .global-radio-label:before {
	border: 1px solid #fff;
}

.table-row-data.mod-table-radio .global-radio-input:disabled + .global-radio-label:before {
	color: #06BFD1;
	background: #fff;
	border: 1px solid #fff;
	box-shadow: none;
}

.table-row-data.mod-table-radio .global-radio:hover .global-radio-input:disabled + .global-radio-label:before {
	content: ' ';
}

.table-row-data.mod-table-radio .global-radio-input:checked + .global-radio-label:before,
.table-row-data.mod-table-radio .global-radio:hover .global-radio-input:checked:disabled + .global-radio-label:before {
	content: '\0066';
}

.global-table-radio {
	text-align: center;
	vertical-align: middle;
}

.global-table-radio input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: 1px solid #ccc;
	padding: 7px;
	border-radius: 2px;
	display: inline-block;
	position: relative;
	margin: 0;
	height: auto;
	height: 1.875rem;
	border: none;
	line-height: 1.4;
	cursor: default;
	vertical-align: middle;
}

.global-table-radio input[type="radio"]:focus {
	outline: none;
}

.global-table-radio input[type="radio"]:checked {
	padding: 7px;
	color: #ccc;
	height: 1.875rem;
	cursor: default;
}

.global-table-radio input[type="radio"]:checked:after {
	font-family: eleoticons;
	content: '\0066';
	color: #979797;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	font-size: 1.25rem;
	color: #00BFD1;
	line-height: 1.4;
}

.global-table-radio input[type="radio"]:checked:focus {
	outline: none;
}

.global-table-checkbox.mod-eprove input[type="checkbox"]:checked:after {
	color: #0092DE;
}

.global-table-radio.mod-eprove input[type="radio"]:checked:after {
	color: #0092DE;
}

.global-table-checkbox.mod-eleot input[type="checkbox"]:checked:after {
	color: #06BFD1;
}

.global-table-radio.mod-eleot input[type="radio"]:checked:after {
	color: #06BFD1;
}

.global-table-checkbox.mod-surveys input[type="checkbox"]:checked:after {
	color: #6FC96F;
}

.global-table-radio.mod-surveys input[type="radio"]:checked:after {
	color: #6FC96F;
}

.global-table-checkbox.mod-analytics input[type="checkbox"]:checked:after {
	color: #F4AA34;
}

.global-table-radio.mod-analytics input[type="radio"]:checked:after {
	color: #F4AA34;
}

.global-table-checkbox.mod-diagnostics input[type="checkbox"]:checked:after {
	color: #3793D2;
}

.global-table-radio.mod-diagnostics input[type="radio"]:checked:after {
	color: #3793D2;
}

.global-table-checkbox.mod-strategies input[type="checkbox"]:checked:after {
	color: #DD6666;
}

.global-table-radio.mod-strategies input[type="radio"]:checked:after {
	color: #DD6666;
}

.global-table-checkbox.mod-workspace input[type="checkbox"]:checked:after {
	color: #883C8A;
}

.global-table-radio.mod-workspace input[type="radio"]:checked:after {
	color: #883C8A;
}

.global-table-checkbox.mod-secondary input[type="checkbox"]:checked:after {
	color: #CCC;
}

.global-table-radio.mod-secondary input[type="radio"]:checked:after {
	color: #CCC;
}

.global-table-checkbox.mod-gray input[type="checkbox"]:checked:after {
	color: #eee;
}

.global-table-radio.mod-gray input[type="radio"]:checked:after {
	color: #eee;
}

.reveal-modal.small.eprove__modal {
	width: 500px;
	margin-left: -250px;
}

.reveal-modal:focus {
	outline: 0;
}

.eprove__modal h2 {
	font-size: 1.5625rem;
	padding-bottom: 0.9375rem;
	border-bottom: 1px solid #d8d8d8;
}

.eprove__modal p,
.eprove__modal .admin-upload-roster ul,
.admin-upload-roster .eprove__modal ul {
	font-weight: 400;
	line-height: 1.375rem;
}

.eprove__modal a {
	color: #4A90E2;
	font-weight: 400;
	text-decoration: underline;
}

.eprove__modal a.close-reveal-modal {
	text-decoration: none;
}

.eprove__modal .button.large {
	margin-top: 3.125rem;
}

.eprove__modal .close-reveal-modal {
	font-size: 1.75rem;
}

.reveal-modal-bg {
	background: #000000;
	background: rgba(0, 0, 0, 0.45);
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: auto;
	left: 0;
}

.reveal-modal-bg.mod-light {
	background: rgba(255, 255, 255, 0.9);
}

.reveal-modal.mod-light {
	background-color: transparent;
	padding: 0;
	border: 0;
	box-shadow: none;
}

.reveal-modal.mod-light:focus {
	outline: 0;
}

.reveal-modal.mod-wide {
	width: 750px;
	margin: 0 auto;
	left: 0;
	right: 0;
}

.filter__select--dropdown {
	position: relative;
}

.filter__select--dropdown span {
	box-sizing: border-box;
	display: inline-block;
	background-color: #eee;
	background-image: url("./images/select-down-arrow.svg");
	background-size: 15px 8px;
	background-position: right;
	background-repeat: no-repeat;
	border: 0;
	border-right: 10px solid #eee;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	height: 2.0625rem;
	line-height: 2.0625rem;
	padding-left: 0.625rem;
	padding-right: 1.25rem;
	font-weight: 300;
	font-size: 1rem;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	cursor: pointer;
	width: 100%;
	color: #333;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
}

.filter__select--dropdown span:hover {
	background-color: #eee;
	border-right: 10px solid #eee;
}

.filter__select--dropdown .dropdown {
	width: 100%;
	color: #666;
	font-size: 1rem;
	font-weight: 400;
	box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.5);
	left: 0;
	background: #f6f6f6;
	max-width: 280px;
	margin: 0;
	list-style: none;
	box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.5), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
	border-radius: 2px;
}

.filter__select--dropdown .dropdown:before,
.filter__select--dropdown .dropdown:after {
	content: none;
}

.filter__select--dropdown .dropdown li {
	font-size: 1rem;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-weight: 400;
}

.filter__select--dropdown .dropdown a {
	color: #666;
	display: block;
	padding: 0.3125rem 0.625rem;
	border: 0;
}

.filter__select--dropdown .dropdown a:hover {
	background: #eee;
}

.filter__select--dropdown .dropdown .has-dropdown {
	position: relative;
}

.filter__select--dropdown .dropdown .has-dropdown:after {
	content: '\0069';
	font-family: "eleoticons";
	display: inline-block;
	height: 18px;
	line-height: 18px;
	position: absolute;
	margin: auto;
	right: 5px;
	bottom: 0;
	top: 0;
}

.filter__select--dropdown .dropdown .nested {
	display: none;
	left: -9999px;
	list-style: none;
	margin-left: 0;
	position: absolute;
	background: #fff;
	border: solid 0 #cccccc;
	font-size: 0.875rem;
	height: auto;
	max-height: none;
	width: 100%;
	z-index: 89;
	margin-top: 2px;
	max-width: 200px;
	display: none;
	position: absolute;
	margin-left: 0;
	left: 60px;
	margin-top: -20px;
	box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.7);
}

.filter__select--dropdown .dropdown .nested.open {
	display: block;
}

.filter__select--dropdown .dropdown .nested > *:first-child {
	margin-top: 0;
}

.filter__select--dropdown .dropdown .nested > *:last-child {
	margin-bottom: 0;
}

.filter__select--dropdown .dropdown .nested:before {
	border: inset 6px;
	content: "";
	display: block;
	height: 0;
	width: 0;
	border-color: transparent transparent #fff transparent;
	border-bottom-style: solid;
	position: absolute;
	top: -12px;
	left: 10px;
	z-index: 89;
}

.filter__select--dropdown .dropdown .nested:after {
	border: inset 7px;
	content: "";
	display: block;
	height: 0;
	width: 0;
	border-color: transparent transparent #cccccc transparent;
	border-bottom-style: solid;
	position: absolute;
	top: -14px;
	left: 9px;
	z-index: 88;
}

.filter__select--dropdown .dropdown .nested.right:before {
	left: auto;
	right: 10px;
}

.filter__select--dropdown .dropdown .nested.right:after {
	left: auto;
	right: 9px;
}

.filter__select--dropdown .dropdown .nested:before,
.filter__select--dropdown .dropdown .nested:after {
	content: none;
}

.filter__select--dropdown .dropdown li:hover .nested {
	display: block;
}

.filter__select select {
	background-color: #f3f3f3;
	background-image: url("./images/select-down-arrow.svg");
	background-size: 15px 8px;
	background-position: right;
	background-repeat: no-repeat;
	border: 0;
	border-right: 10px solid #f3f3f3;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	height: 2.0625rem;
	line-height: 2.0625rem;
	padding: 0 10px;
	font-weight: 300;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.filter__select select::-ms-expand {
	display: none;
}

.filter__select select:hover {
	background-color: #f6f6f6;
	border-right: 10px solid #f6f6f6;
}

.filter__select.rtl select {
	background-position: 5px;
}

.filter__select--dropdown.mod-margin-bottom {
	margin-bottom: 15px;
}

.dropdown.mod-width {
	width: 300px;
}

.filter__select.mod-small select {
	font-size: 1rem;
}

.filter__select.mod-fullwidth select {
	width: 100%;
}

.filter__select.mod-margin-top {
	margin-top: 40px;
}

.eprove-filter-search-container {
	height: 40px;
	z-index: 10;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.eprove-filter-search {
	position: absolute;
	bottom: 0.3125rem;
	width: 40px;
	height: 33px;
	right: 20px;
	top: 0;
	color: #000;
	height: 2.125rem;
	line-height: 2.125rem;
	background: inherit;
	overflow: hidden;
}

.eprove-filter-search.mod-right {
	right: 0;
}

.eprove-filter-search.is-open {
	width: 100%;
	border: 2px solid #aaa;
	border-radius: 3px;
	padding: 0 0.3125rem;
	right: 0;
}

.eprove-filter-search.is-open .eprove-filter-search-icon-wrapper {
	font-size: 1.5rem;
}

.eprove-filter-search-prefix {
	color: #aaa;
	font-size: 1rem;
	font-weight: 300;
	height: 33px;
	line-height: 33px;
}

.eprove-filter-search-input,
input[type="text"].eprove-filter-search-input {
	background: #fff;
	color: #444;
	font-size: 1rem;
	font-weight: 400;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	border: 0;
	padding: 0;
	height: 30px;
	line-height: 30px;
	margin-bottom: 0;
}

.eprove-filter-search-input:focus,
input[type="text"].eprove-filter-search-input:focus {
	outline: 0;
	border: 0;
	box-shadow: none;
}

.eprove-filter-search-icon-wrapper {
	font-size: 2.0625rem;
	color: #ccc;
	line-height: 33px;
}

.eprove-filter-search-icon {
	display: inline-block;
	vertical-align: -2px;
}

.eprove-filter-close-wrapper {
	font-size: 1.125rem;
	color: #ccc;
	line-height: 33px;
}

.eprove-filter-close {
	display: inline-block;
	vertical-align: -1px;
}

.eprove-search-dropdown {
	position: absolute;
	z-index: 120;
	top: 38px;
	right: 0;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.6);
	border-radius: 3px;
	width: 100%;
	max-height: calc(100vh - 38px);
}

.eprove-search-dropdown-list {
	list-style: none;
	color: #333;
	font-size: 1rem;
	font-weight: 400;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	margin: 0;
}

.eprove-search-dropdown-item {
	padding: 1.25rem 0.625rem;
	border-top: 1px solid #ccc;
}

.eprove-search-dropdown-item:first-child {
	border-top: 0;
}

.eprove-search-dropdown-item:hover {
	cursor: pointer;
	background: #f6f6f6;
}

.footer__link--wrap.mod-spacing {
	margin-bottom: 15px;
}

.add-note-icon,
.edit-note-icon {
	font-size: 1.75rem;
	vertical-align: -8px;
}

.new-user-guide-toggle {
	border-top: 0;
	margin-top: 20px;
}

.alert-box.secondary.mod-action-complete-alert {
	border: none;
	background-color: #FFFDEB;
	font-size: 0.875rem;
	padding: 0.875rem 1.5rem 0.875rem 0.875rem;
}

.alert-box.secondary.mod-action-complete-alert .alert-text {
	position: absolute;
	top: 12px;
	left: 35px;
	font-size: 14px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	line-height: 1.5;
	color: #333;
}

.alert-box.secondary.mod-action-complete-alert .alert-text span {
	color: #333;
}

.alert-box.secondary.mod-action-complete-alert .close {
	right: 0.25rem;
	background: inherit;
	color: #333333;
	font-size: 1.375rem;
	line-height: .9;
	margin-top: -0.6875rem;
	opacity: 0.3;
	padding: 0 6px 4px;
	position: absolute;
	top: 50%;
}

.icon-circle-check.mod-action-complete-alert:before {
	font-size: 1rem;
	color: #6FC96F;
}

.admin__tag-item {
	display: inline-block;
	color: #666;
	background-color: #f6f6f6;
	font-size: 0.75rem;
	font-weight: 400;
	padding: 0 0.3125rem;
	margin-right: 0.625rem;
	margin-bottom: 0.625rem;
	border-radius: 0.125rem;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
	padding: 0.3125rem;
	margin-left: 0.625rem;
	font-size: 0.9375rem;
	box-shadow: none;
	border: 1px solid #979797;
	border-radius: 2px;
	padding-left: 0.875rem;
	padding-right: 0.625rem;
	margin-left: 0;
	margin-right: 1.25rem;
	cursor: pointer;
}

.admin__tag-item:hover {
	color: #666;
	border: 1px solid #979797;
}

.admin__tag-item .icon-close {
	color: #ccc;
	font-size: 0.625rem;
	margin-left: 0.3125rem;
}

.rtl .admin__tag-item {
	text-align: right;
}

.rtl .admin__tag-item .icon-close {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.admin__tag-item.pending {
	opacity: 0.5;
}

.admin__tag-item span.icon {
	color: #9B9B9B;
	font-size: 1.375rem;
	margin-left: 0.3125rem;
}

.admin__tag-item span.icon.icon-close {
	color: #666;
	font-size: 1.0625rem;
}

.admin__tag-item .has-tip {
	border: 0;
}

.editDesignation__form {
	margin-top: 3.125rem;
	margin-bottom: 1.5625rem;
}

.offcanvas__form {
	padding: 0.9375rem;
}

.offcanvas__form h3 {
	margin-bottom: 2.5rem;
}

.offcanvas__form .filter__select select {
	background-color: #fafafa;
	width: 224px;
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
	border-right: 10px solid #fafafa;
}

.offcanvas__form .filter__select.mod-fullwidth select {
	width: 100%;
}

.offcanvas__form input[type="text"] {
	font-size: 1.0625rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.offcanvas__form p,
.offcanvas__form .admin-upload-roster ul,
.admin-upload-roster .offcanvas__form ul {
	color: #666;
	font-size: 0.875rem;
	margin-bottom: 0.875rem;
}

.offcanvas__form .plain {
	font-weight: 300;
	color: #666;
	font-size: 0.875rem;
}

.offcanvas-school-info {
	margin-bottom: 3.75rem;
}

.offcanvas-school-info h5 {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 1.0625rem;
	margin: 0;
}

.offcanvas-school-info span {
	display: block;
	color: #999;
	font-size: 0.9375rem;
	font-weight: 400;
}

.offcanvas-school-info .offcanvas-school-info-link {
	color: #0092DE;
	text-decoration: underline;
	font-size: 0.9375rem;
	font-family: 'Open Sans', sans-serif;
}

.eprove-change-school {
	position: relative;
	margin-bottom: 3.75rem;
}

.eprove-change-school input[type="text"] {
	position: relative;
	z-index: 11;
	margin: 0;
	box-shadow: none;
}

.eprove-change-school input[type="text"]:focus {
	border: 1px solid #CCCCCC;
}

.eprove-change-school .autofill-container {
	display: none;
	padding-top: 10px;
	background: #fafafa;
	border: 1px solid #CCCCCC;
	box-shadow: none;
	border-radius: 0 0 5px 5px;
	font-size: 1rem;
	color: #333333;
	line-height: 1.375rem;
	position: relative;
	z-index: 10;
	margin-top: -5px;
	left: 0;
	right: 0;
	max-height: 104px;
	overflow-y: scroll;
}

.eprove-change-school .autofill-container ul {
	margin: 0;
	list-style: none;
}

.eprove-change-school .autofill-container ul li {
	border-top: 1px solid #ccc;
	padding: 0.625rem;
	font-size: 1rem;
	font-weight: 400;
	color: #333;
}

.eprove-change-school .autofill-container ul li:first-child {
	border-top: 0;
}

.eprove-change-school .autofill-container ul li:hover {
	background: #f2f2f2;
}

.eprove-change-school .autofill-container ul li a {
	color: inherit;
}

.admin-upload-roster h3 {
	margin-bottom: 1.25rem;
}

.admin-upload-roster p,
.admin-upload-roster ul {
	color: #666;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.3125rem;
	font-weight: 300;
}

.admin-upload-roster ul {
	margin-top: 1.875rem;
	margin-bottom: 1.875rem;
}

.admin-upload-roster .admin-upload__dd {
	padding-bottom: 1.25rem;
}

.admin-upload-roster .admin-upload__dd h3 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 1.4375rem;
}

.admin-upload-roster .admin-upload__dd h3 span {
	color: #6FC96F;
}

.right-off-canvas-menu {
	box-sizing: border-box;
}

.institution-select__input {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid #aaa;
	border-radius: 3px;
	margin-top: 1.5625rem;
	margin-bottom: 0.625rem;
	overflow: hidden;
	background: #fff;
}

.institution-select__input span {
	color: #ccc;
	font-size: 1.5rem;
	line-height: 1.5rem;
	padding-top: 0.25rem;
	padding-left: 0.3125rem;
}

.institution-select__input input {
	background: #fff;
	box-shadow: none;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	margin: 0;
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
	height: 31px;
}

.institution-select__input input:focus {
	background: #fff;
	box-shadow: none;
	border: 0;
}

.institution-select__input.mod-spacing {
	margin-top: 0.625rem;
}

.institution-select__group {
	max-height: 260px;
	overflow-y: scroll;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin: 0;
	padding: 0;
}

.institution-select__group::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

.institution-select__group::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.institution-select__group li {
	position: relative;
	list-style: none;
	width: 100%;
	background: #fff;
}

.institution-select__group > li {
	border-bottom: 1px solid #ccc;
}

.institution-select__group > li:last-child {
	border-bottom: 0;
}

.institution-select__group ul {
	display: none;
	margin: 0;
}

.institution-select__group li li > .institution-select__wrap {
	padding-left: 20px;
}

.institution-select__group li li li > .institution-select__wrap {
	padding-left: 40px;
}

.institution-select__group li li li li > .institution-select__wrap {
	padding-left: 60px;
}

.institution-select__group .selected {
	background: #f6f6f6;
}

.institution-select__group input[type="checkbox"] + label {
	position: relative;
	color: #666;
	font-size: 0.875rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	margin: 0;
	width: 95%;
	padding-top: 10px;
	padding-right: 0;
	padding-bottom: 10px;
	padding-left: 10px;
}

.institution-select__group input[type="checkbox"]:checked + label:after {
	content: '\0066';
	color: #06BFD1;
	font-family: 'eleoticons';
	font-size: 1.25rem;
	position: absolute;
	margin: auto;
	top: 5px;
	right: 10px;
}

.institution-select__group input {
	visibility: hidden;
	margin: 0;
	position: absolute;
	top: 0;
	right: 0;
}

.institution-select__group .toggle {
	color: #aaa;
	float: left;
	width: 5%;
	font-size: 1.125rem;
	text-align: center;
	line-height: 2.5625rem;
	height: 2.5625rem;
	padding-left: 0.625rem;
}

.institution-select-toggle-icon {
	vertical-align: -4px;
}

.institution-select__buttons {
	padding-top: 0.625rem;
}

.institution-select__wrap input[type="checkbox"] + label {
	font-weight: 600;
}

.institution-select__wrap input[type="checkbox"]:checked + label:after {
	color: #00BFD1;
}

.institution-select__wrap input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-eprove input[type="checkbox"]:checked + label:after {
	color: #0092DE;
}

.institution-select__wrap.mod-eprove input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-eleot input[type="checkbox"]:checked + label:after {
	color: #06BFD1;
}

.institution-select__wrap.mod-eleot input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-surveys input[type="checkbox"]:checked + label:after {
	color: #6FC96F;
}

.institution-select__wrap.mod-surveys input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-analytics input[type="checkbox"]:checked + label:after {
	color: #F4AA34;
}

.institution-select__wrap.mod-analytics input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-diagnostics input[type="checkbox"]:checked + label:after {
	color: #3793D2;
}

.institution-select__wrap.mod-diagnostics input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-strategies input[type="checkbox"]:checked + label:after {
	color: #DD6666;
}

.institution-select__wrap.mod-strategies input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-workspace input[type="checkbox"]:checked + label:after {
	color: #883C8A;
}

.institution-select__wrap.mod-workspace input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-secondary input[type="checkbox"]:checked + label:after {
	color: #CCC;
}

.institution-select__wrap.mod-secondary input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-gray input[type="checkbox"]:checked + label:after {
	color: #eee;
}

.institution-select__wrap.mod-gray input[type="checkbox"]:disabled:checked + label:after {
	color: #ccc;
}

.institution-select__wrap.mod-border-bottom {
	border-bottom: 1px solid #ccc;
}

.page-container.mod-cart {
	padding-bottom: 65px;
}

.alert-box.secondary.mod-welcome-alert {
	margin-bottom: 0;
	font-size: 1rem;
}

.alert-box.secondary.mod-welcome-alert:before {
	font-size: 1.5rem;
}

.alert-text.mod-welcome-alert-text {
	padding: 3px 10px;
}

.eprove-content-container.mod-cart {
	padding-bottom: 50px;
}

.eprove-cart th {
	background: #fff;
	padding: 0;
	padding-bottom: 10px;
	color: #777;
	font-size: 0.875rem;
}

.eprove-cart th h4 {
	color: #555;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 0.875rem;
	margin: 0;
	border: 0;
}

.eprove-cart th p,
.eprove-cart th .admin-upload-roster ul,
.admin-upload-roster .eprove-cart th ul {
	color: #666;
	margin-top: 10px;
	margin-bottom: 0;
}

.eprove-cart th p a,
.eprove-cart th .admin-upload-roster ul a,
.admin-upload-roster .eprove-cart th ul a {
	color: #666;
	text-decoration: underline;
	margin-left: 5px;
}

.eprove-cart td {
	vertical-align: top;
}

.eprove-cart td span {
	display: block;
	line-height: 2.5rem;
}

.eprove-cart td span.purchased {
	color: #999;
}

.eprove-cart td span.purchased .icon-check {
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #999;
	border-radius: 50%;
	width: 21px;
	height: 21px;
	line-height: 21px;
	text-align: center;
	margin-right: 5px;
}

.eprove-cart td .payment-table-text {
	font-size: 1.1875rem;
}

.eprove-cart td p.small,
.eprove-cart td .admin-upload-roster ul.small,
.admin-upload-roster .eprove-cart td ul.small {
	color: #999;
}

.eprove-cart input[type="text"] {
	display: inline-block;
	background: #fcfcfc;
	border: 1px solid #bbb;
	border-radius: 4px;
	width: 50px;
	text-align: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	vertical-align: -12px;
	height: 31px;
}

.eprove-cart__product-img {
	box-shadow: 0 2 0 0 rgba(0, 0, 0, 0.1);
}

span.eprove-cart__product-name {
	display: block;
	color: #666;
	font-weight: 400;
	margin-top: 0.3125rem;
}

.eprove-cart__description {
	width: 500px;
}

.eprove-cart__description a {
	color: #999;
	text-decoration: underline;
	margin-left: 1.875rem;
	line-height: 2.5rem;
}

.eprove-cart__description > div {
	float: left;
	width: 85%;
	text-align: right;
}

.is-purchase .eprove-cart__description > div {
	float: none;
	width: 100%;
}

.eprove-cart__description p,
.eprove-cart__description .admin-upload-roster ul,
.admin-upload-roster .eprove-cart__description ul {
	margin: 0;
	line-height: 2.5rem;
	font-size: 0.875rem;
}

.eprove-cart__discount,
.eprove-cart__purchase-total {
	color: #666;
	padding: 0 0 1.25rem;
}

.eprove-cart__discount p,
.eprove-cart__purchase-total p,
.eprove-cart__discount .admin-upload-roster ul,
.admin-upload-roster .eprove-cart__discount ul,
.eprove-cart__purchase-total .admin-upload-roster ul,
.admin-upload-roster .eprove-cart__purchase-total ul {
	font-size: 0.875rem;
	color: #666;
}

.eprove-cart__discount p.lead,
.eprove-cart__purchase-total p.lead,
.eprove-cart__discount .admin-upload-roster ul.lead,
.admin-upload-roster .eprove-cart__discount ul.lead,
.eprove-cart__purchase-total .admin-upload-roster ul.lead,
.admin-upload-roster .eprove-cart__purchase-total ul.lead {
	font-size: 1.125rem;
	margin-bottom: 5px;
}

.eprove-cart__discount span,
.eprove-cart__purchase-total span {
	color: #666;
	line-height: 2.375rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
}

.eprove-cart__discount input[type="text"],
.eprove-cart__purchase-total input[type="text"] {
	background: #fcfcfc;
	border: 1px solid #bbb;
	border-radius: 4px;
	padding-top: 0;
	padding-bottom: 0;
	height: 38px;
	line-height: 38px;
	width: 100%;
}

.eprove-cart__discount input.cart-discount-code-input[type="text"],
.eprove-cart__purchase-total input.cart-discount-code-input[type="text"] {
	display: inline-block;
	width: 180px;
}

.eprove-cart__discount.mod-tax-section,
.mod-tax-section.eprove-cart__purchase-total {
	padding-top: 1.25rem;
}

.eprove-cart__total {
	color: #666;
	font-size: 1.1875rem;
}

.eprove-cart__total .label {
	width: 60%;
	text-align: right;
	line-height: 2.0625rem;
	padding: 0;
	background: transparent;
}

.eprove-cart__total .label.small {
	font-size: 1.125rem;
}

.eprove-cart__total .price {
	width: 40%;
	text-align: right;
	line-height: 2.0625rem;
}

.eprove-cart__total .label.mod-light-text,
.eprove-cart__total .price.mod-light-text {
	color: #999;
}

.eprove-cart__price-group {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.eprove-cart__footer p,
.eprove-cart__footer .admin-upload-roster ul,
.admin-upload-roster .eprove-cart__footer ul {
	color: #999;
	margin: 0;
}

.eprove-cart__footer p.lead,
.eprove-cart__footer .admin-upload-roster ul.lead,
.admin-upload-roster .eprove-cart__footer ul.lead {
	color: #666;
	font-size: 0.8125rem;
}

.eprove-cart__footer p.eprove-cart__contact,
.eprove-cart__footer .admin-upload-roster ul.eprove-cart__contact,
.admin-upload-roster .eprove-cart__footer ul.eprove-cart__contact {
	color: #666;
	font-weight: 300;
	line-height: 1.5rem;
}

.eprove-cart__footer p.eprove-cart__contact a,
.eprove-cart__footer .admin-upload-roster ul.eprove-cart__contact a,
.admin-upload-roster .eprove-cart__footer ul.eprove-cart__contact a {
	color: #999;
	text-decoration: underline;
}

.eprove-cart__footer.is-loggedin,
.assign-school-admin__footer {
	background: #f8f8f8;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1.25rem;
	padding-bottom: 0;
	height: 70px;
}

.eprove-cart__footer.is-loggedin a,
.assign-school-admin__footer a {
	display: block;
	color: #999;
	font-size: 1rem;
	font-weight: 400;
	border-top: 3px solid #ddd;
	padding-top: 0.625rem;
}

.eprove-cart__footer.is-loggedin a.current,
.assign-school-admin__footer a.current {
	color: #0092DE;
	border-top: 3px solid #0092DE;
}

.eprove-cart__footer.is-loggedin a.completed,
.assign-school-admin__footer a.completed {
	color: #B4CCE4;
	border-top: 3px solid #ABD1F6;
}

.eprove-cart__cta {
	font-weight: 400;
}

.eprove-cart__tag {
	display: inline-block;
	color: #666;
	background-color: #f5f5f5;
	text-transform: uppercase;
	font-weight: 400;
	border: 1px solid #979797;
	border-radius: 4px;
	padding-left: 0.875rem;
	padding-right: 0.625rem;
	margin-right: 1.25rem;
	cursor: pointer;
	white-space: nowrap;
}

.eprove-cart__tag .icon-close {
	display: inline-block;
	vertical-align: middle;
	color: #666;
	margin-left: 0.3125rem;
}

/* #eProve_addSeats {
  h2 {
    color: #666;
    font-weight: 700;
    margin-bottom: rem-calc(30);
  }
  .row {
    padding-bottom: rem-calc(30);
  }
  input.inline {
    width: 60px;
    text-align: center;
    border: 0;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    height: 40px;
    line-height: 40px;
  }
  span {
    display: block;
    color: #999;
    &:first-of-type {
      color: #666;
      font-size: rem-calc(24);
    }
  }
  .button-group {
    .button {
      border: 1px solid #bbb;
      color: #aaa;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      line-height: 40px;
    }
  }
} */

.eprove-cart__purchase-total {
	border-bottom: 0;
}

.eprove-cart__payment-details h5 {
	color: #999;
	font-weight: 300;
	font-family: 'Open Sans', sans-serif;
	border: 0;
}

.cart-discount-code {
	font-size: 1.125rem;
	margin-right: 10px;
}

.credit-card-options {
	padding-left: 2.0625rem;
	height: 30px;
}

.top-bar-section.right.mod-top-bar-button {
	margin-right: 0.625rem;
}

.top-bar-section.right.mod-top-bar-button ul.right {
	margin-right: 0;
}

.global-radio-label.mod-payment-label:before {
	line-height: 13px;
}

.global-radio-label.mod-payment-label .global-radio-label-text {
	line-height: 1.5;
}

.global-radio-label.mod-payment-label .global-radio-label-text.mod-send-invoice {
	line-height: 3.5;
}

.price.mod-total-price {
	position: relative;
}

.asterisk {
	position: absolute;
	top: -5px;
	left: 333px;
}

.icon.icon-info.mod-payment-info-icon {
	font-size: 1.375rem;
	color: #ccc;
	display: inline-block;
	padding-left: 5px;
}

.icon.icon-info.mod-payment-info-icon:before {
	vertical-align: middle;
}

.order-summary-image {
	padding: 1.875rem 1.25rem;
}

.payment-table-data.mod-padding {
	padding-right: 0;
}

.eprove-cart .cart-table-heading {
	color: #666;
}

.eprove-cart .cart-table-heading.mod-text-right {
	padding-right: 0.625rem;
}

.eprove-cart__discount.mod-padding,
.mod-padding.eprove-cart__purchase-total {
	padding-bottom: 0;
}

.order-summary-image.mod-padding {
	padding-bottom: 0.9375rem;
	padding-top: 1.25rem;
}

.alert-box.secondary.mod-cart-header-alert {
	display: block;
	position: absolute;
	width: 100%;
	top: 68px;
	height: 60px;
	z-index: 999;
}

.alert-box.secondary.mod-cart-header-alert:before {
	position: absolute;
	left: 14px;
	top: 19px;
	color: #6FC96F;
}

.alert-box.secondary.mod-cart-header-alert .alert-text.mod-welcome-alert-text.mod-cart-header-alert-text {
	position: absolute;
	top: -6px;
	left: 35px;
	color: #333;
}

.alert-box.secondary.mod-cart-header-alert .alert-text.mod-welcome-alert-text.mod-cart-header-alert-text.mod-position {
	top: 17px;
}

.alert-box.secondary.mod-cart-header-alert .alert-box.secondary.mod-cart-header-alert.icon-circle-check.mod-green:before {
	color: #6FC96F;
}

.alert-box.secondary.mod-cart-header-alert .close.icon-close.mod-header-alert-close {
	right: 1.25rem;
	background: inherit;
	color: #333333;
	font-size: 1.375rem;
	line-height: .9;
	margin-top: -0.6875rem;
	opacity: 0.3;
	padding: 0 6px 4px;
	position: absolute;
	top: 50%;
}

.payment-information-heading {
	font-weight: 500;
}

.applied-text {
	margin: 0;
	line-height: 2.375;
}

input.global-text-input.mod-payment-table-input[type="text"] {
	line-height: 1.5;
}

a.add-training-seats-link {
	font-size: 0.875rem;
}

.discount-applied {
	margin-bottom: 10px !important;
}

.eprove-signup p,
.eprove-signup .admin-upload-roster ul,
.admin-upload-roster .eprove-signup ul {
	color: #999;
	font-size: 0.875rem;
	font-family: "Open Sans", sans-serif;
	line-height: 1.1875rem;
	font-weight: 400;
}

.eprove-signup .joyride-tip-guide p,
.eprove-signup .joyride-tip-guide .admin-upload-roster ul,
.admin-upload-roster .eprove-signup .joyride-tip-guide ul {
	color: #fff;
}

h1.signup__title,
h2.signup__title,
h3.signup__title,
h4.signup__title,
h5.signup__title,
h6.signup__title,
h1.thanks__title,
h2.thanks__title,
h3.thanks__title,
h4.thanks__title,
h5.thanks__title,
h6.thanks__title {
	color: #555;
	font-size: 1.5rem;
	border-bottom: 1px solid #e5e5e5;
}

h1.signup__subtitle,
h2.signup__subtitle,
h3.signup__subtitle,
h4.signup__subtitle,
h5.signup__subtitle,
h6.signup__subtitle {
	color: #777;
	font-size: 1.25rem;
	margin-top: 50px;
	border: 0;
}

h1.signup__subtitle .icon-info,
h2.signup__subtitle .icon-info,
h3.signup__subtitle .icon-info,
h4.signup__subtitle .icon-info,
h5.signup__subtitle .icon-info,
h6.signup__subtitle .icon-info {
	color: #ccc;
	font-size: 1.25rem;
	vertical-align: middle;
	margin-left: 10px;
}

.signup__subtitle.mod-password {
	margin-top: 10px;
}

.signup__subtitle.signup__subtitle--no-space {
	margin-top: 20px;
}

.signup__eula {
	background: #fafafa;
	border: 1px solid #ccc;
	border-radius: 0.3125rem;
	padding: 0.625rem;
	height: 390px;
	overflow-y: scroll;
	color: #000;
	font-weight: 300;
	font-size: 0.8125rem;
	line-height: 1.1875rem;
}

.signup__eula p,
.signup__eula .admin-upload-roster ul,
.admin-upload-roster .signup__eula ul {
	color: #000;
	font-weight: 300;
	font-size: 0.8125rem;
	line-height: 1.1875rem;
}

.signup__agree {
	color: #777;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	margin: 1.25rem 0;
}

.signup__agree input[type="checkbox"] {
	float: left;
	margin-bottom: 1.25rem;
	margin-right: 0.625rem;
	vertical-align: top;
	height: 1.1875rem;
	line-height: 1.1875rem;
}

.signup__agree a {
	color: inherit;
	text-decoration: underline;
}

input.signup__input--text[type="text"],
input.signup__input--text[type="password"],
input.signup__input--text[type="date"],
input.signup__input--text[type="datetime"],
input.signup__input--text[type="datetime-local"],
input.signup__input--text[type="month"],
input.signup__input--text[type="week"],
input.signup__input--text[type="email"],
input.signup__input--text[type="number"],
input.signup__input--text[type="search"],
input.signup__input--text[type="tel"],
input.signup__input--text[type="time"],
input.signup__input--text[type="url"],
input.signup__input--text[type="color"],
textareainput.signup__input--text {
	background: #fafafa;
	border: 1px solid #ccc;
	color: #333;
	font-size: 1.0625rem;
	font-weight: 400;
	box-shadow: none;
	height: 2.4375rem;
	line-height: 2.4375rem;
	line-height: 1.0625rem;
	border-radius: 4px;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	position: relative;
	z-index: 10;
	margin-bottom: 0;
}

p.signup__footer-text,
.admin-upload-roster ul.signup__footer-text {
	color: #777;
	margin: 1.5625rem 0;
	font-size: 1rem;
}

p.signup__footer-text a,
.admin-upload-roster ul.signup__footer-text a {
	color: inherit;
	text-decoration: underline;
}

.signup__selected {
	color: #777;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 1.875rem 0;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 1.875rem;
}

.signup__selected span {
	display: block;
}

.signup__selected strong {
	color: #333;
	font-weight: 600;
	font-size: 1.125rem;
}

.signup__selected.mod-padding {
	padding-top: 0;
}

.signup__autofill {
	display: none;
	background: #fafafa;
	box-shadow: none;
	margin-top: -7px;
	padding-top: 7px;
	z-index: 8;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	font-size: 1rem;
	color: #333333;
	line-height: 1.375rem;
	position: absolute;
	right: 0.3125rem;
}

.signup__autofill ul {
	margin: 0;
	list-style: none;
}

.signup__autofill ul li {
	font-size: 1.125rem;
	border-top: 0;
	padding: 0.625rem;
	font-weight: 400;
	color: #333;
}

.signup__autofill ul li:first-child {
	border-top: 0;
}

.signup__autofill ul li:hover {
	background: #f7f7f7;
}

.signup__autofill ul li.autofill__no-choice {
	border-top: 1px solid #ccc;
}

.signup__autofill ul li.autofill__no-choice a {
	color: #777;
	font-size: 0.8125rem;
}

.signup__autofill ul li a {
	color: inherit;
}

.signup__autofill span {
	color: #777;
	font-size: 0.8125rem;
}

.signup__select {
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 1.25rem;
}

.signup__select select {
	background-color: #fafafa;
	background-image: url("./images/select-down-arrow.svg");
	background-size: 15px 8px;
	background-position: right;
	background-repeat: no-repeat;
	border: 0;
	border-right: 10px solid #fafafa;
	color: #333;
	font-size: 1.0625rem;
	font-weight: 400;
	height: 2.4375rem;
	line-height: 2.4375rem;
	border-radius: 4px;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	padding-top: 0;
	padding-bottom: 0;
	box-shadow: none;
	margin: 0;
}

.signup__text-container {
	color: #777;
	border: 1px solid #ccc;
	border-radius: 0.3125rem;
	padding: 1.25rem 0.9375rem;
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 1.875rem;
}

.signup__text-container a {
	color: #999;
	text-decoration: underline;
}

.signup__text-container span {
	display: block;
}

.signup__text-container span.dark {
	color: #444;
}

.signup__text-container strong {
	color: #333;
	font-size: 1.125rem;
	font-weight: 600;
}

.signup__text-container em {
	color: #aaa;
	display: block;
}

.signup__text-container p,
.signup__text-container .admin-upload-roster ul,
.admin-upload-roster .signup__text-container ul {
	color: #aaa;
	margin-top: 0.625rem;
	margin-bottom: 0;
}

.signup__footer--products {
	margin-top: 3.125rem;
}

.signup__footer--products p,
.signup__footer--products .admin-upload-roster ul,
.admin-upload-roster .signup__footer--products ul {
	color: #999;
}

.signup__footer--products img {
	margin: 0 0.625rem;
	max-width: 28px;
}

input.signup__form--text[type="text"],
input.signup__form--text[type="password"],
input.signup__form--text[type="date"],
input.signup__form--text[type="datetime"],
input.signup__form--text[type="datetime-local"],
input.signup__form--text[type="month"],
input.signup__form--text[type="week"],
input.signup__form--text[type="email"],
input.signup__form--text[type="number"],
input.signup__form--text[type="search"],
input.signup__form--text[type="tel"],
input.signup__form--text[type="time"],
input.signup__form--text[type="url"],
input.signup__form--text[type="color"],
textareainput.signup__form--text {
	margin-bottom: 1.25rem;
}

.signup__tagline a {
	color: #0092DE;
	text-decoration: underline;
}

.signup__help-links {
	font-size: 0.875rem;
}

.signup__help-links a {
	font-weight: 400;
	color: #aaa;
	text-decoration: underline;
}

.signup__container {
	background: #f3f3f3;
	padding: 1.25rem;
	margin-bottom: 1.875rem;
}

.signup__product {
	height: 100px;
	position: relative;
}

.signup__product img {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
}

.signup__product img.indented {
	margin-left: 0.625rem;
}

.signup__footer {
	border-top: 1px solid #e5e5e5;
	padding-top: 30px;
}

.signup__footer p,
.signup__footer .admin-upload-roster ul,
.admin-upload-roster .signup__footer ul {
	color: #ccc;
	font-size: 0.8125rem;
	line-height: 1.0625rem;
	font-weight: 400;
}

.signup__form--error {
	display: block;
	background: #f36a54;
	border-radius: 5px;
	font-size: 0.875rem;
	font-weight: normal;
	margin-top: -10px;
	margin-bottom: 1.25rem;
	padding: 0.375rem 0.5625rem;
	color: #fff;
}

.survey-add-q__select-dropdown {
	display: none;
	left: -9999px;
	list-style: none;
	margin-left: 0;
	position: absolute;
	background: #fff;
	border: solid 0 #cccccc;
	font-size: 0.875rem;
	height: auto;
	max-height: none;
	width: 100%;
	z-index: 89;
	max-width: none;
	width: 100%;
	background: transparent;
	margin-top: 5px;
}

.survey-add-q__select-dropdown.open {
	display: block;
}

.survey-add-q__select-dropdown > *:first-child {
	margin-top: 0;
}

.survey-add-q__select-dropdown > *:last-child {
	margin-bottom: 0;
}

.select-dropdown__item,
li.select-dropdown__item {
	padding: 0;
	margin: 5px 0;
}

.select-dropdown__item a,
li.select-dropdown__item a {
	padding: 0;
}

.select-dropdown__choice {
	display: block;
	background: #fafafa;
	font-size: 0.9375rem;
	height: 42px;
	line-height: 42px;
	border: 1px solid #999;
	border-radius: 3px;
	padding: 0;
}

.select-dropdown__choice span {
	display: inline-block;
	line-height: 42px;
}

.select-dropdown__choice-icon {
	width: 42px;
	border-right: 1px solid #999;
	margin-right: 0.625rem;
	text-align: center;
	font-size: 1.625rem !important;
	line-height: 48px !important;
	vertical-align: top;
	height: 40px;
}

.survey-pagination-button,
.survey-edit__content .button.survey-pagination-button {
	font-weight: 700;
	font-size: 1.25rem;
	margin: 0;
}

.survey-pagination-button-icon {
	display: inline-block;
	font-size: 1.25rem;
	vertical-align: middle;
}

h1.thanks__title,
h2.thanks__title,
h3.thanks__title,
h4.thanks__title,
h5.thanks__title,
h6.thanks__title {
	color: #555;
	padding-bottom: 1.25rem;
}

.thanks__text {
	color: #777;
	font-size: 1.1875rem;
	line-height: 1.5625rem;
	margin: 1.25rem 0;
}

.thanks__buttons {
	padding-top: 2.5rem;
}

.thanks__buttons .button {
	color: #fff;
	font-weight: 400;
	font-size: 1.125rem;
}

.invite__text-container {
	color: #999;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	border-radius: 0.3125rem;
	padding: 1.25rem 0;
	font-size: 1rem;
	font-weight: 400;
	margin-top: -1px;
}

.invite__text-container span {
	display: block;
}

.invite__text-container span.dark {
	color: #777;
}

.invite__text-container strong {
	color: #333;
	font-size: 1.125rem;
	font-weight: 600;
}

.upload-multiple-schools {
	padding: 0;
	margin-bottom: 1.875rem;
}

.upload-multiple-schools .admin-upload__dd {
	background: #fafafa;
	border: 2px dashed #ccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 0 0 #ececec;
	padding-top: 3.125rem;
	padding-bottom: 0;
}

.upload-multiple-schools .admin-upload__dd h3 {
	color: #999;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 1.1875rem;
	border: 0;
}

.upload-multiple-schools .admin-upload__dd h3 span {
	display: block;
	margin-top: 0.9375rem;
	color: #999;
	font-size: 4.125rem;
	margin-left: 0;
}

.payment-placeholder {
	margin-top: 1.125rem;
}

.assign-school-admin__entry {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding: 2.5rem 0 1.25rem;
}

.assign-school-admin__entry + .assign-school-admin__entry {
	border-top: 0;
}

.assign-school-admin__entry h5 {
	color: #333;
	font-size: 1.125rem;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border-bottom: 0;
}

.assign-school-admin__entry input[type="text"] {
	background: #fafafa;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 1.125rem;
	font-weight: 400;
	padding-top: 0;
	padding-bottom: 0;
	height: 2.5rem;
	line-height: 2.5rem;
	margin-bottom: 1.25rem;
}

.assign-school-admin__entry.mod-single {
	padding-bottom: initial;
	border: 0;
}

.assign-school-admin__add-row {
	display: inline-block;
	padding: 1.25rem 0;
	font-size: 1rem;
	font-weight: 400;
}

.eprove__list {
	border-top: 1px solid #666;
	border-bottom: 1px solid #ccc;
}

.eprove__list thead {
	background: #fff;
}

.eprove__list tr {
	border-bottom: 0;
}

.eprove__list th {
	color: #777;
	font-size: 0.9375rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	border-bottom: 1px solid #ccc;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.eprove__list td {
	border-bottom: 1px solid #ccc;
	padding-top: 1.875rem;
	padding-bottom: 1.875rem;
}

.eprove__list .subitem td:first-child {
	border-bottom: 0;
}

ul.eprove__inline-list {
	list-style: none;
	margin: 0;
}

ul.eprove__inline-list li {
	display: inline-block;
	margin: 0 1rem;
	font-size: 1rem;
	font-weight: 400;
}

input.licenses__count {
	color: #777;
	width: 100%;
	background: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 3px;
	text-align: center;
	font-size: 0.9375rem;
	font-weight: 400;
	box-shadow: none;
	height: 31px;
	margin: 0;
}

input.licenses__count:focus {
	box-shadow: none;
}

.list--distribute-licenses strong,
.list--invite-admins strong {
	color: #333;
	font-size: 1.1875rem;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
}

.list--distribute-licenses .subitem strong,
.list--invite-admins .subitem strong {
	color: #777;
}

.list--distribute-licenses p,
.list--invite-admins p,
.list--distribute-licenses .admin-upload-roster ul,
.admin-upload-roster .list--distribute-licenses ul,
.list--invite-admins .admin-upload-roster ul,
.admin-upload-roster .list--invite-admins ul {
	color: #999;
	font-size: 0.875rem;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	margin-top: 0.625rem;
	margin-bottom: 0;
}

a.licenses__select-all {
	color: #777;
	font-size: 0.9375rem;
	font-weight: 300;
	text-decoration: underline;
	margin-left: 0.625rem;
}

a.licenses__select-all:hover {
	color: #333;
}

.list__list-toggle {
	color: #999;
	font-size: 1.5rem;
}

input[type="checkbox"].eprove__checkbox {
	display: none;
}

input[type="checkbox"].eprove__checkbox + .eprove__label {
	position: relative;
	display: block;
	margin: 0 auto;
	text-align: center;
	width: 28px;
	height: 28px;
	line-height: 28px;
	border-radius: 3px;
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 0 0 #ececec;
}

input[type="checkbox"].eprove__checkbox + .eprove__label:after {
	font-family: 'eleoticons';
	font-size: 1rem;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

input[type="checkbox"].eprove__checkbox:checked + .eprove__label:after {
	content: '\0066';
	color: #979797;
}

input[type="checkbox"].eprove__checkbox:disabled:checked + .eprove__label:after {
	content: '\0066';
	color: #ddd;
}

.licenses__search-container {
	padding-top: 5.625rem;
}

.licenses__search-input {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	background: #fafafa;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-top: 1.5625rem;
	margin-bottom: 0.625rem;
	overflow: hidden;
}

.licenses__search-input span {
	color: #ccc;
	font-size: 1.5rem;
	line-height: 2.5rem;
	padding-top: 0.25rem;
	padding-left: 0.625rem;
}

.licenses__search-input input {
	background: #fafafa;
	box-shadow: none;
	font-size: 1.125rem;
	height: 40px;
	line-height: 40px;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	margin-bottom: 0;
	border: 0;
}

.licenses__search-input input:focus {
	background: #fafafa;
	box-shadow: none;
	border: 0;
}

.licenses__product-name {
	display: block;
	color: #666;
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 0.9375rem;
}

.eprove__page-header p,
.eprove__page-header .admin-upload-roster ul,
.admin-upload-roster .eprove__page-header ul {
	font-size: 1rem;
	margin: 1.25rem 0 0.625rem;
}

.licenses__tag-container {
	margin-top: 1.25rem;
}

.licenses__tag-container .admin__tag-item {
	margin-bottom: 0;
}

.licenses__tag-container .admin__tag-item span.icon {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.125rem;
}

.list__add-admin {
	color: #999;
	font-size: 0.875rem;
	font-weight: 400;
	text-decoration: underline;
}

.licenses__checkbox-container {
	display: -webkit-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: space-between;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.list--manage-licenses strong {
	color: #333;
	font-size: 1.125rem;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}

.list--manage-licenses p,
.list--manage-licenses .admin-upload-roster ul,
.admin-upload-roster .list--manage-licenses ul {
	color: #777;
	font-size: 0.8125rem;
	font-weight: 300;
	font-family: 'Open Sans', sans-serif;
	margin-top: 0.3125rem;
	margin-bottom: 0;
}

.list--manage-licenses input[type="checkbox"].eprove__checkbox + .eprove__label {
	width: 29px;
	height: 29px;
	line-height: 29px;
	margin-left: 10px;
}

.list--manage-licenses input[type="checkbox"].eprove__checkbox + .eprove__label:after {
	font-size: 1rem;
}

.list--manage-licenses a.licenses__select-all {
	font-size: 0.8125rem;
}

.list--manage-licenses .list__list-toggle {
	color: #aaa;
	font-size: 0.8125rem;
}

.list--manage-licenses th {
	color: #777;
	font-size: 0.9375rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	border-bottom: 1px solid #ccc;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.list--manage-licenses td {
	border-bottom: 1px solid #ccc;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.list--manage-licenses input.licenses__count {
	color: #777;
	width: 100%;
	background: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px;
	text-align: center;
	font-size: 0.9375rem;
	font-weight: 400;
	box-shadow: none;
	width: 48px;
}

.list--manage-licenses input.licenses__count:focus {
	box-shadow: none;
}

.licenses__product-container {
	float: right;
	padding-right: 0.625rem;
	text-align: center;
	width: 110px;
}

.list--invite-admins {
	border-bottom: 0;
}

.transfer-training p,
.transfer-training .admin-upload-roster ul,
.admin-upload-roster .transfer-training ul {
	font-weight: 400;
}

.transfer-training .institution-select__group > li {
	border-bottom: none;
}

.transfer-training .institution-select__wrap {
	border-bottom: 1px solid #ccc;
}

.transfer-training-seats {
	line-height: inherit;
}

.institution-select__input.mod-transfer-training-select,
.transfer-training-remaining {
	margin-top: 0.625rem;
	margin-bottom: 0.625rem;
	text-align: right;
}

.institution-select__input.mod-transfer-training-select {
	margin-left: 5px;
}

.transfer-training-remaining {
	padding-top: 8px;
}

.transfer-training-seats.mod-link {
	margin-bottom: 25px;
}

.institution-select__group.mod-transfer-training-select .institution-select__school {
	margin: 10px;
}

.institution-select__group.mod-transfer-training-select .toggle {
	line-height: 0;
}

.institution-select__group.mod-transfer-training-select input[type="text"] + label {
	position: relative;
	color: #666;
	font-size: 0.875rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	margin: 0;
	width: 95%;
	padding-top: 0px;
	padding-right: 0;
	padding-bottom: 0px;
	padding-left: 32px;
}

.institution-select__group.mod-transfer-training-select input {
	color: #666;
	font-size: 0.875rem;
	visibility: visible;
	margin: 0 5px 0 0;
	position: absolute;
	top: 0;
	right: 0;
	border: 1px solid #aaa;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: none;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	height: 22px;
	width: 33px;
	padding: 5px;
	text-align: right;
}

.institution-select__group.mod-transfer-training-select input:focus {
	background: #fff;
	box-shadow: none;
}

.transfer-training-confirm {
	background-color: #fff;
	padding: 0.625rem;
}

.transfer-training-confirm p,
.transfer-training-confirm .admin-upload-roster ul,
.admin-upload-roster .transfer-training-confirm ul {
	font-weight: 400;
}

.page-container.license-management {
	padding-top: 0;
}

.license-management .license-management-filter {
	margin-top: 1.25rem;
}

.license-management .license-management-filter span.icon {
	font-size: 2rem;
}

.license-management .icon {
	color: #999;
}

.license-management .display-institutions-select-input {
	margin-left: 0.625rem;
}

.license-management .display-institutions-select-input label {
	margin-left: 1.25rem;
	font-weight: 300;
	color: #999;
	font-size: 1rem;
	padding-bottom: 1.25rem;
	margin-left: 1.5rem;
}

.license-info.mod-institution-info {
	margin-top: 1.25rem;
	margin-bottom: 1.875rem;
	line-height: 1.5;
}

.institutions-table-nav {
	margin-top: 1.25rem;
}

.number-selected {
	padding: 8px;
}

.assign-licenses.mod-align {
	text-align: right;
}

.button-table-row {
	text-align: right;
}

.button-table {
	background-color: #eee;
	border-radius: 5px;
	text-align: center;
	padding: 0.5rem;
	font-size: 1rem;
	color: #333;
	cursor: pointer;
}

.button-table.mod-inline {
	display: inline-block;
	width: 130px;
}

.button-table.mod-inline:first-child {
	margin-right: 0.625rem;
}

.institutions-table-header {
	border-bottom: 1px solid #e5e5e5;
	margin-top: 2.8125rem;
}

.license-management table {
	margin-top: 0;
}

.license-info {
	line-height: 1.5;
}

.license-info span:first-child {
	color: #666;
}

.status-category {
	text-align: right;
}

.right-click__dropdown {
	text-align: right;
}

.right-click__dropdown a {
	color: #666;
}

.right-click__dropdown .dropdown {
	text-align: left;
	width: 139px;
	color: #666;
	font-size: 1rem;
	font-weight: 400;
	box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.5);
	left: 0;
	background: #f6f6f6;
	max-width: 280px;
	margin: 0;
	list-style: none;
	box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.5), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
	border-radius: 2px;
}

.institution-location {
	color: #999;
}

.license-info {
	margin-bottom: 5px;
}

.license-info.mod-inline {
	display: inline-block;
}

.license-info.mod-align {
	text-align: right;
	padding: 8px;
}

.license-management input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 7px;
	border-radius: 2px;
	display: inline-block;
	position: relative;
	margin: 0;
}

.license-management input[type="checkbox"]:checked {
	padding: 7px;
	color: #ccc;
}

.license-management input[type="checkbox"]:checked:after {
	font-family: eleoticons;
	content: '\0066';
	color: #979797;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	font-size: 15px;
}

.license-management .table-row-options-toggle span {
	color: #666;
}

.license-management .table-row-options.mod-open-left {
	left: 813px;
	font-size: 0.9375rem;
	color: #666;
}

.table-row-options.mod-status-open-left {
	left: 766px !important;
	font-size: 0.9375rem;
}

.table-row-options-item.mod-text-color {
	color: #666;
}

@font-face {
	font-family: "eleoticons";
	src: url("fonts/eleoticons/eleoticons.eot");
	src: url("fonts/eleoticons/eleoticons.eot?#iefix") format("embedded-opentype"), url("fonts/eleoticons/eleoticons.woff") format("woff"), url("fonts/eleoticons/eleoticons.ttf") format("truetype"), url("fonts/eleoticons/eleoticons.svg#eleoticons") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: "eleoticons" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: "eleoticons" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
	content: "a";
}

.icon-arrow-left:before {
	content: "b";
}

.icon-arrow-right:before {
	content: "c";
}

.icon-arrow-up:before {
	content: "d";
}

.icon-check:before {
	content: "f";
}

.icon-chevron-down:before {
	content: "g";
}

.icon-chevron-left:before {
	content: "h";
}

.icon-chevron-right:before {
	content: "i";
}

.icon-chevron-up:before {
	content: "j";
}

.icon-close:before {
	content: "k";
}

.icon-doc:before {
	content: "l";
}

.icon-eye:before {
	content: "m";
}

.icon-update:before {
	content: "n";
}

.icon-search:before {
	content: "o";
}

.icon-pencil:before {
	content: "p";
}

.icon-offline:before {
	content: "q";
}

.icon-note:before {
	content: "r";
}

.icon-menu:before {
	content: "s";
}

.icon-key:before {
	content: "t";
}

.icon-info:before {
	content: "u";
}

.icon-certified:before {
	content: "e";
}

.icon-phone:before {
	content: "v";
}

.icon-reporting:before {
	content: "w";
}

.icon-settings:before {
	content: "x";
}

.icon-users:before {
	content: "y";
}

.icon-reset:before {
	content: "z";
}

.icon-certified-alt:before {
	content: "A";
}

.icon-cloud-upload:before {
	content: "B";
}

.icon-youtube:before {
	content: "C";
}

.icon-twitter:before {
	content: "D";
}

.icon-facebook:before {
	content: "E";
}

.icon-email-envelope:before {
	content: "F";
}

.icon-source-down-arrow:before {
	content: "G";
}

.icon-linkedin:before {
	content: "H";
}

.icon-copy:before {
	content: "L";
}

.icon-delete:before {
	content: "M";
}

.icon-dropdown:before {
	content: "N";
}

.icon-message:before {
	content: "O";
}

.icon-opinion-scale:before {
	content: "P";
}

.icon-options:before {
	content: "Q";
}

.icon-page-break:before {
	content: "R";
}

.icon-pick-multiple:before {
	content: "S";
}

.icon-pick-one:before {
	content: "T";
}

.icon-question:before {
	content: "U";
}

.icon-text-field:before {
	content: "V";
}

.icon-section:before {
	content: "X";
}

.icon-add:before {
	content: "I";
}

.icon-archive:before {
	content: "J";
}

.icon-branching:before {
	content: "K";
}

.icon-lock:before {
	content: "W";
}

.icon-opinion-5-strongly-agree:before {
	content: "0";
}

.icon-opinion-4-agree:before {
	content: "Z";
}

.icon-opinion-3-neutral:before {
	content: "1";
}

.icon-opinion-2-disagree:before {
	content: "2";
}

.icon-opinion-1-strongly-disagree:before {
	content: "3";
}

.icon-block:before {
	content: "4";
}

.icon-unlock:before {
	content: "5";
}

.icon-calendar:before {
	content: "6";
}

.icon-survey:before {
	content: "Y";
}

.icon-systems:before {
	content: "7";
}

.icon-clock:before {
	content: "8";
}

.icon-minus:before {
	content: "9";
}

.icon-circle-check:before {
	content: "!";
}

.icon-certified-1:before {
	content: "\"";
}

.icon-download:before {
	content: "#";
}

.icon-institutions:before {
	content: "$";
}

.page-header.mod-assign-licenses {
	margin: 50px 0 40px;
}

.page-container.assign-licenses {
	width: 100%;
	padding-top: 0;
}

.page-container.assign-licenses .institution-select__group > li {
	border-bottom: none;
}

.page-container.assign-licenses .institution-select__wrap {
	border-bottom: 1px solid #ccc;
}

.page-container.assign-licenses p,
.page-container.assign-licenses .admin-upload-roster ul,
.admin-upload-roster .page-container.assign-licenses ul {
	font-weight: 400;
}

.top-bar.mod-assign-licenses-arrow:before {
	font-family: eleoticons;
	content: 'b';
	font-size: 1.5rem;
	padding-left: 10px;
}

.top-bar.mod-assign-licenses-arrow .menu-icon {
	display: none;
}

/* header-wrap.mod-user-management-header {
  margin-top: 20px;
} */

.page-container.user-management {
	padding-top: 0 !important;
}

.user-management {
	padding-bottom: 100px;
}

.user-management .filter__select--dropdown.mod-apply-selector {
	width: auto;
}

.user-management p,
.user-management .admin-upload-roster ul,
.admin-upload-roster .user-management ul {
	margin-bottom: 0;
}

.user-management td {
	font-weight: 400;
	font-size: 0.875rem;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.user-management .mod-user-management-filter {
	margin-top: 1.25rem;
}

.user-management .mod-user-management-filter .f-dropdown {
	margin-top: 4px;
}

.user-management .mod-user-management-filter .f-dropdown:after,
.user-management .mod-user-management-filter .f-dropdown:before {
	display: none;
}

.user-management .button-table {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
	color: #333;
	font-size: 1rem;
	cursor: pointer;
	height: 2.0625rem;
	font-weight: 300;
}

.user-management ul {
	list-style: none;
}

.user-management-count {
	margin-left: 7px;
	margin-top: 5px;
}

.user-management-table {
	margin-top: 13px;
}

.user-management-table .global-checkbox-label:before {
	vertical-align: top;
	margin-top: 3px;
}

.mod-right-align-dropdown .mod-right-menu {
	left: -145px;
	width: 228%;
}

.mod-right-align-dropdown .mod-right-menu:before {
	left: 226;
}

.mod-right-align-dropdown .mod-right-menu:after {
	left: 225;
}

.institutions-table-nav.mod-user-management {
	margin-top: 0;
}

.secondary-text {
	font-weight: 300;
	color: #999;
}

.global-checkbox.mod-table-checkbox {
	width: 55px;
}

.global-checkbox-label.mod-inline-paragraph {
	width: 100%;
}

.global-checkbox-label.mod-inline-paragraph span {
	display: inline-block;
	width: 81%;
}

.global-checkbox-label.mod-inline-paragraph:before {
	vertical-align: top;
}

.popover-icon.mod-aside-popover .joyride-tip-guide {
	width: 215px;
}

.popover-icon.mod-aside-popover .joyride-tip-guide p,
.popover-icon.mod-aside-popover .joyride-tip-guide .admin-upload-roster ul,
.admin-upload-roster .popover-icon.mod-aside-popover .joyride-tip-guide ul {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	margin: 0;
}

.user-management .popover-icon,
.add-user-form .popover-icon {
	display: inline-block;
	position: absolute;
}

.user-management .global-table-checkbox,
.add-user-form .global-table-checkbox {
	text-align: center;
}

.user-management .global-table-checkbox input[type="checkbox"],
.add-user-form .global-table-checkbox input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 7px;
	border-radius: 2px;
	display: inline-block;
	position: relative;
	margin: 0;
	height: auto;
	height: 1.875rem;
	border: none;
	top: 8px;
}

.user-management .global-table-checkbox input[type="checkbox"]:focus,
.add-user-form .global-table-checkbox input[type="checkbox"]:focus {
	outline: none;
}

.user-management .global-table-checkbox input[type="checkbox"]:checked,
.add-user-form .global-table-checkbox input[type="checkbox"]:checked {
	padding: 7px;
	color: #ccc;
	height: 1.875rem;
}

.user-management .global-table-checkbox input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox input[type="checkbox"]:checked:after {
	font-family: eleoticons;
	content: '\0066';
	color: #979797;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	font-size: 1.25rem;
	color: #00BFD1;
}

.user-management .global-table-checkbox input[type="checkbox"]:checked:focus,
.add-user-form .global-table-checkbox input[type="checkbox"]:checked:focus {
	outline: none;
}

.user-management .global-table-checkbox input.role-selection-check[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox input.role-selection-check[type="checkbox"]:checked:after {
	margin-top: 3px;
}

.user-management .global-table-checkbox.mod-eprove input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-eprove input[type="checkbox"]:checked:after {
	color: #0092DE;
}

.user-management .global-table-checkbox.mod-eleot input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-eleot input[type="checkbox"]:checked:after {
	color: #06BFD1;
}

.user-management .global-table-checkbox.mod-surveys input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-surveys input[type="checkbox"]:checked:after {
	color: #6FC96F;
}

.user-management .global-table-checkbox.mod-analytics input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-analytics input[type="checkbox"]:checked:after {
	color: #F4AA34;
}

.user-management .global-table-checkbox.mod-diagnostics input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-diagnostics input[type="checkbox"]:checked:after {
	color: #3793D2;
}

.user-management .global-table-checkbox.mod-strategies input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-strategies input[type="checkbox"]:checked:after {
	color: #DD6666;
}

.user-management .global-table-checkbox.mod-workspace input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-workspace input[type="checkbox"]:checked:after {
	color: #883C8A;
}

.user-management .global-table-checkbox.mod-secondary input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-secondary input[type="checkbox"]:checked:after {
	color: #CCC;
}

.user-management .global-table-checkbox.mod-gray input[type="checkbox"]:checked:after,
.add-user-form .global-table-checkbox.mod-gray input[type="checkbox"]:checked:after {
	color: #eee;
}

.certified-table {
	width: 200px;
}

.certified-table p,
.certified-table .admin-upload-roster ul,
.admin-upload-roster .certified-table ul {
	margin-bottom: -15px;
	font-size: 0.9375rem;
}

.icon-certified-alt.mod-user-badge {
	font-size: 1.5rem;
	color: #00BFD1;
}

.icon-certified-alt.mod-user-badge.mod-eprove {
	font-size: 1.5rem;
	color: #0092DE;
}

.icon-certified-alt.mod-user-badge.mod-eleot {
	font-size: 1.5rem;
	color: #06BFD1;
}

.icon-certified-alt.mod-user-badge.mod-surveys {
	font-size: 1.5rem;
	color: #6FC96F;
}

.icon-certified-alt.mod-user-badge.mod-analytics {
	font-size: 1.5rem;
	color: #F4AA34;
}

.icon-certified-alt.mod-user-badge.mod-diagnostics {
	font-size: 1.5rem;
	color: #3793D2;
}

.icon-certified-alt.mod-user-badge.mod-strategies {
	font-size: 1.5rem;
	color: #DD6666;
}

.icon-certified-alt.mod-user-badge.mod-workspace {
	font-size: 1.5rem;
	color: #883C8A;
}

.icon-certified-alt.mod-user-badge.mod-secondary {
	font-size: 1.5rem;
	color: #CCC;
}

.icon-certified-alt.mod-user-badge.mod-gray {
	font-size: 1.5rem;
	color: #eee;
}

.icon-certified.mod-user-badge {
	font-size: 1.5rem;
	color: #999;
}

.right-click__dropdown.mod-table-data {
	text-align: left;
}

.table-data.mod-institution-data {
	width: 12.5rem;
}

.global-table-checkbox.table-data.mod-padding {
	padding-right: 25px;
}

.unchecked-label {
	color: #ccc;
	vertical-align: top;
}

.dropdown-option-heading:hover {
	cursor: default;
	background-color: #F6F6F6;
}

.dropdown-option-heading.mod-first-option {
	margin-top: 10px;
}

.dropdown-option-heading.mod-first-option:hover {
	cursor: pointer;
	background-color: #eee;
}

.dropdown.f-dropdown .dropdown-sub-option {
	padding-left: 1.25rem;
}

.dropdown-horizontal-separator {
	margin: 0.625rem 0;
}

.dropdown.f-dropdown.mod-open-left {
	left: -147px !important;
}

.dropdown.f-dropdown.mod-open-left:before {
	left: 226px;
}

.dropdown.f-dropdown.mod-open-left:after {
	left: 225px;
}

.add-user-form {
	padding: 0.9375rem;
	background-color: #fff;
	color: #06BFD1;
}

.add-user-form .icon.icon-info.mod-inline {
	display: inline-block;
	outline: none;
	cursor: pointer;
	position: relative;
	left: -8px;
}

.add-user-form .icon.mod-icon-access:before {
	margin-left: 1.125rem;
	font-size: 1.5rem;
	color: #ccc;
}

.add-user-form label.mod-inline {
	display: inline-block;
}

.add-user-form label {
	line-height: 1.2;
	overflow: hidden;
	margin-top: 1.5625rem;
}

.add-user-form .sub-label {
	font-weight: 300;
	color: #333;
	margin-top: 0.3125rem;
}

.add-user-form input[type="text"] {
	background: #f6f6f6;
	height: auto;
	color: #333;
	border-radius: 5px;
	margin-bottom: 0.625rem;
	padding: 0.625rem;
	font-weight: 400;
}

.add-user-form input.bottom-input {
	margin-bottom: 1.875rem;
}

.role-selection-box {
	border: 1px solid #ccc;
	border-radius: 4px;
}

.role-selection-option {
	height: 2.75rem;
	line-height: 2.75rem;
}

.role-selection-option.mod-border-bottom {
	border-bottom: 1px solid #ccc;
}

.sub-label.mod-role-selection-label {
	line-height: 2;
	padding-left: 0.4375rem;
}

.global-checkbox.mod-role-selection-input .global-checkbox-label,
.global-checkbox.mod-role-selection-input .global-radio-label,
.global-radio.mod-role-selection-input .global-checkbox-label,
.global-radio.mod-role-selection-input .global-radio-label {
	margin-top: 0;
	vertical-align: middle;
}

.global-checkbox.mod-role-selection-input .global-checkbox-label:before,
.global-checkbox.mod-role-selection-input .global-radio-label:before,
.global-radio.mod-role-selection-input .global-checkbox-label:before,
.global-radio.mod-role-selection-input .global-radio-label:before {
	background: #fff;
	border: 1px solid #fff;
	font-size: 1.25rem;
	width: 20px;
	height: 20px;
	font-family: "eleoticons";
	box-shadow: none;
	color: #06BFD1;
	margin-right: 0.625rem;
}

.global-checkbox.mod-role-selection-input .global-checkbox:hover .global-checkbox-label:before,
.global-checkbox.mod-role-selection-input .global-radio:hover .global-radio-label:before,
.global-radio.mod-role-selection-input .global-checkbox:hover .global-checkbox-label:before,
.global-radio.mod-role-selection-input .global-radio:hover .global-radio-label:before {
	border: 1px solid #fff;
}

.global-checkbox.mod-role-selection-input .global-checkbox-input:disabled + .global-checkbox-label:before,
.global-checkbox.mod-role-selection-input .global-radio-input:disabled + .global-radio-label:before,
.global-radio.mod-role-selection-input .global-checkbox-input:disabled + .global-checkbox-label:before,
.global-radio.mod-role-selection-input .global-radio-input:disabled + .global-radio-label:before {
	color: #b3b3b3;
	background: #fff;
	border: 1px solid #fff;
	box-shadow: none;
}

.global-checkbox.mod-role-selection-input .global-checkbox-input:checked + .global-checkbox-label:before,
.global-checkbox.mod-role-selection-input .global-checkbox:hover .global-checkbox-input:checked:disabled + .global-checkbox-label:before,
.global-checkbox.mod-role-selection-input .global-radio-input:checked + .global-radio-label:before,
.global-checkbox.mod-role-selection-input .global-radio:hover .global-radio-input:checked:disabled + .global-radio-label:before,
.global-radio.mod-role-selection-input .global-checkbox-input:checked + .global-checkbox-label:before,
.global-radio.mod-role-selection-input .global-checkbox:hover .global-checkbox-input:checked:disabled + .global-checkbox-label:before,
.global-radio.mod-role-selection-input .global-radio-input:checked + .global-radio-label:before,
.global-radio.mod-role-selection-input .global-radio:hover .global-radio-input:checked:disabled + .global-radio-label:before {
	content: '\0066';
}

.global-checkbox.mod-role-selection-input.mod-surveys .global-checkbox-label:before,
.global-checkbox.mod-role-selection-input.mod-surveys .global-radio-label:before,
.global-radio.mod-role-selection-input.mod-surveys .global-checkbox-label:before,
.global-radio.mod-role-selection-input.mod-surveys .global-radio-label:before {
	color: #6FC96F;
}

.link--secondary.mod-select-link {
	color: #999;
	font-weight: 400;
}

.error.mod-select-institution {
	padding: 0;
	background-color: transparent;
	color: red;
}

.icon.mod-user-added-dismiss {
	position: absolute;
	top: -20px;
	right: -20px;
	color: #999;
}

.icon.mod-user-added-dismiss:before {
	font-size: 1.5rem;
}

.u-no-padding {
	padding: 0;
}

.institution-select__group.mod-height {
	max-height: 275px;
}

.added-user-institution-select.option {
	margin-bottom: 0.3125rem;
	margin-top: 0.9375rem;
	clear: both;
}

.paragraph-small-note {
	font-size: 0.875rem;
	margin-top: 0.625rem;
	display: inline-block;
}

.paragraph-small-note.mod-small-note-text {
	width: 98%;
	float: right;
}

.global-radio-label-text.mod-light-text {
	color: #999;
}

.select-link-inline {
	margin-left: 0.625rem;
}

/* .mod-checkbox-margin:before {
  margin-right: 8px;
}
 */

.global-radio-label-text.mod-small-radio-text,
.global-checkbox-label-text.mod-small-checkbox-text {
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.2;
}

.table-heading.mod-heading-label {
	vertical-align: middle;
	font-size: 14px;
	line-height: 1.125rem;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #333;
	font-style: normal;
	font-weight: 400;
	padding-top: 12px;
}

.global-checkbox-label-text.mod-heading-label {
	font-size: 14px;
}

.global-checkbox-label.mod-margin,
.text-left.mod-inline.mod-margin {
	margin-top: 0;
}

.global-checkbox-label.mod-margin:before {
	margin-top: 3px;
}

.global-radio-label.mod-access-label {
	margin-top: 0;
	margin-bottom: -5px;
}

.access-sub-labels {
	padding-left: 2.375rem;
	line-height: 2;
	margin-top: -15px;
}

.access-sub-labels span {
	margin-bottom: 0;
}

.columns.text-left.mod-system-wide-access-label {
	margin-top: -17px;
}

.role-label.mod-margin {
	margin-top: 0;
	margin-bottom: 0.625rem;
}

.button.mod-side-bar-button {
	font-size: 1rem;
	height: 2.125rem;
}

.button.mod-bottom-button {
	margin-bottom: 1.25rem;
	height: 40px;
	position: relative;
}

.add-user-aside {
	border-left: 1px solid #ccc;
}

.add-user-roles-table {
	border: 1px solid #e8e8e8;
}

.institution-select__buttons.mod-button-spacing {
	padding-top: 0;
}

.u-block-text.mod-county-margin {
	margin-top: -10px;
}

.user-management .table-row-options-toggle span {
	color: #666;
}

.user-management .table-row-options.mod-open-left {
	left: 813px !important;
	font-size: 0.9375rem;
	color: #666;
}

.table-row-options-item.mod-text-color {
	color: #666;
}

.user-management.mod-top-section {
	padding-bottom: 0;
}

.expiration-date,
.confirmation-text {
	font-weight: 400;
}

.user-added-table {
	margin-bottom: 45px;
}

input.unchecked-table-checkbox[type="checkbox"] + label.unchecked-label {
	vertical-align: top;
}

.user-added-heading {
	margin-bottom: 1.25rem;
}

.user-management-selected {
	line-height: 33px;
	height: 33px;
}

.page-container.mod-cart-summary {
	padding-top: 10px;
}

.eprove-content-container.mod-cart-summary {
	padding-bottom: 150px;
}

.icon.icon-chevron-down.mod-margin,
.icon.icon-chevron-up.mod-margin {
	margin-left: 0.625rem;
}

.eprove-subheading-large.mod-review-purchase-subheading {
	margin-top: 1.5625rem;
}

.eprove-content-container.mod-cart .payment-summary-info {
	font-weight: 300;
}

.payment-summary-info-container {
	line-height: 1.5;
}

.payment-summary-info.mod-purchase-order {
	line-height: 2.5;
}

.eprove-cart__description.mod-cart-summary-description {
	width: 500px;
}

.eprove-cart__description.mod-cart-summary-description > div {
	width: 85%;
}

.text-right.mod-right-end {
	padding-right: 0;
}

.cart-summary-section {
	margin-top: 4.0625rem;
}

.cart-summary-box {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 16px;
}

.cart-summary-box-order-info {
	padding-top: 30px;
}

.cart-summary-box-order-info p,
.cart-summary-box-order-info .admin-upload-roster ul,
.admin-upload-roster .cart-summary-box-order-info ul {
	line-height: 2.5;
}

.icon.icon-info.mod-inline-info-icon {
	font-size: 1.5rem;
	vertical-align: middle;
	color: #ccc;
}

.billing-address-label {
	margin: 5px 0;
}

.eprove-subheading-large.mod-cart-summary-heading {
	margin-bottom: 1.5625rem;
}

.eprove-content-container.mod-cart.mod-cart-summary .institution-address-block {
	margin: 15px 0 35px 0;
	color: #666;
	line-height: 1.5;
}

.eprove-content-container.mod-cart.mod-cart-summary .address-text {
	font-weight: 300;
}

.terms-and-conditions-text {
	color: #666;
	font-size: 0.9375rem;
	font-weight: 300;
}

.order-summary-image.mod-padding {
	padding-bottom: 10px;
}

.total-line-text {
	padding-bottom: 0;
	padding-top: 0;
}

.eprove-cart td .payment-table-text.mod-small-text {
	font-size: 1rem;
	line-height: 1.7;
}

.row.echo-admin__add-admin-form.mod-margin-top {
	margin-top: 40px !important;
}

@media only screen {

.show-for-small-only,
.show-for-small-up,
.show-for-small,
.show-for-small-down,
.hide-for-medium-only,
.hide-for-medium-up,
.hide-for-medium,
.show-for-medium-down,
.hide-for-large-only,
.hide-for-large-up,
.hide-for-large,
.show-for-large-down,
.hide-for-xlarge-only,
.hide-for-xlarge-up,
.hide-for-xlarge,
.show-for-xlarge-down,
.hide-for-xxlarge-only,
.hide-for-xxlarge-up,
.hide-for-xxlarge,
.show-for-xxlarge-down {
	display: inherit !important;
}

.hide-for-small-only,
.hide-for-small-up,
.hide-for-small,
.hide-for-small-down,
.show-for-medium-only,
.show-for-medium-up,
.show-for-medium,
.hide-for-medium-down,
.show-for-large-only,
.show-for-large-up,
.show-for-large,
.hide-for-large-down,
.show-for-xlarge-only,
.show-for-xlarge-up,
.show-for-xlarge,
.hide-for-xlarge-down,
.show-for-xxlarge-only,
.show-for-xxlarge-up,
.show-for-xxlarge,
.hide-for-xxlarge-down {
	display: none !important;
}

.visible-for-small-only,
.visible-for-small-up,
.visible-for-small,
.visible-for-small-down,
.hidden-for-medium-only,
.hidden-for-medium-up,
.hidden-for-medium,
.visible-for-medium-down,
.hidden-for-large-only,
.hidden-for-large-up,
.hidden-for-large,
.visible-for-large-down,
.hidden-for-xlarge-only,
.hidden-for-xlarge-up,
.hidden-for-xlarge,
.visible-for-xlarge-down,
.hidden-for-xxlarge-only,
.hidden-for-xxlarge-up,
.hidden-for-xxlarge,
.visible-for-xxlarge-down {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

.hidden-for-small-only,
.hidden-for-small-up,
.hidden-for-small,
.hidden-for-small-down,
.visible-for-medium-only,
.visible-for-medium-up,
.visible-for-medium,
.hidden-for-medium-down,
.visible-for-large-only,
.visible-for-large-up,
.visible-for-large,
.hidden-for-large-down,
.visible-for-xlarge-only,
.visible-for-xlarge-up,
.visible-for-xlarge,
.hidden-for-xlarge-down,
.visible-for-xxlarge-only,
.visible-for-xxlarge-up,
.visible-for-xxlarge,
.hidden-for-xxlarge-down {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

table.show-for-small-only,
table.show-for-small-up,
table.show-for-small,
table.show-for-small-down,
table.hide-for-medium-only,
table.hide-for-medium-up,
table.hide-for-medium,
table.show-for-medium-down,
table.hide-for-large-only,
table.hide-for-large-up,
table.hide-for-large,
table.show-for-large-down,
table.hide-for-xlarge-only,
table.hide-for-xlarge-up,
table.hide-for-xlarge,
table.show-for-xlarge-down,
table.hide-for-xxlarge-only,
table.hide-for-xxlarge-up,
table.hide-for-xxlarge,
table.show-for-xxlarge-down {
	display: table !important;
}

thead.show-for-small-only,
thead.show-for-small-up,
thead.show-for-small,
thead.show-for-small-down,
thead.hide-for-medium-only,
thead.hide-for-medium-up,
thead.hide-for-medium,
thead.show-for-medium-down,
thead.hide-for-large-only,
thead.hide-for-large-up,
thead.hide-for-large,
thead.show-for-large-down,
thead.hide-for-xlarge-only,
thead.hide-for-xlarge-up,
thead.hide-for-xlarge,
thead.show-for-xlarge-down,
thead.hide-for-xxlarge-only,
thead.hide-for-xxlarge-up,
thead.hide-for-xxlarge,
thead.show-for-xxlarge-down {
	display: table-header-group !important;
}

tbody.show-for-small-only,
tbody.show-for-small-up,
tbody.show-for-small,
tbody.show-for-small-down,
tbody.hide-for-medium-only,
tbody.hide-for-medium-up,
tbody.hide-for-medium,
tbody.show-for-medium-down,
tbody.hide-for-large-only,
tbody.hide-for-large-up,
tbody.hide-for-large,
tbody.show-for-large-down,
tbody.hide-for-xlarge-only,
tbody.hide-for-xlarge-up,
tbody.hide-for-xlarge,
tbody.show-for-xlarge-down,
tbody.hide-for-xxlarge-only,
tbody.hide-for-xxlarge-up,
tbody.hide-for-xxlarge,
tbody.show-for-xxlarge-down {
	display: table-row-group !important;
}

tr.show-for-small-only,
tr.show-for-small-up,
tr.show-for-small,
tr.show-for-small-down,
tr.hide-for-medium-only,
tr.hide-for-medium-up,
tr.hide-for-medium,
tr.show-for-medium-down,
tr.hide-for-large-only,
tr.hide-for-large-up,
tr.hide-for-large,
tr.show-for-large-down,
tr.hide-for-xlarge-only,
tr.hide-for-xlarge-up,
tr.hide-for-xlarge,
tr.show-for-xlarge-down,
tr.hide-for-xxlarge-only,
tr.hide-for-xxlarge-up,
tr.hide-for-xxlarge,
tr.show-for-xxlarge-down {
	display: table-row;
}

th.show-for-small-only,
td.show-for-small-only,
th.show-for-small-up,
td.show-for-small-up,
th.show-for-small,
td.show-for-small,
th.show-for-small-down,
td.show-for-small-down,
th.hide-for-medium-only,
td.hide-for-medium-only,
th.hide-for-medium-up,
td.hide-for-medium-up,
th.hide-for-medium,
td.hide-for-medium,
th.show-for-medium-down,
td.show-for-medium-down,
th.hide-for-large-only,
td.hide-for-large-only,
th.hide-for-large-up,
td.hide-for-large-up,
th.hide-for-large,
td.hide-for-large,
th.show-for-large-down,
td.show-for-large-down,
th.hide-for-xlarge-only,
td.hide-for-xlarge-only,
th.hide-for-xlarge-up,
td.hide-for-xlarge-up,
th.hide-for-xlarge,
td.hide-for-xlarge,
th.show-for-xlarge-down,
td.show-for-xlarge-down,
th.hide-for-xxlarge-only,
td.hide-for-xxlarge-only,
th.hide-for-xxlarge-up,
td.hide-for-xxlarge-up,
th.hide-for-xxlarge,
td.hide-for-xxlarge,
th.show-for-xxlarge-down,
td.show-for-xxlarge-down {
	display: table-cell !important;
}

}

@media only screen and (orientation: landscape) {

.show-for-landscape,
.hide-for-portrait {
	display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
	display: none !important;
}

/* Specific visibility for tables */

table.show-for-landscape,
table.hide-for-portrait {
	display: table !important;
}

thead.show-for-landscape,
thead.hide-for-portrait {
	display: table-header-group !important;
}

tbody.show-for-landscape,
tbody.hide-for-portrait {
	display: table-row-group !important;
}

tr.show-for-landscape,
tr.hide-for-portrait {
	display: table-row !important;
}

td.show-for-landscape,
td.hide-for-portrait,
th.show-for-landscape,
th.hide-for-portrait {
	display: table-cell !important;
}

}

@media only screen and (orientation: portrait) {

.show-for-portrait,
.hide-for-landscape {
	display: inherit !important;
}

.hide-for-portrait,
.show-for-landscape {
	display: none !important;
}

/* Specific visibility for tables */

table.show-for-portrait,
table.hide-for-landscape {
	display: table !important;
}

thead.show-for-portrait,
thead.hide-for-landscape {
	display: table-header-group !important;
}

tbody.show-for-portrait,
tbody.hide-for-landscape {
	display: table-row-group !important;
}

tr.show-for-portrait,
tr.hide-for-landscape {
	display: table-row !important;
}

td.show-for-portrait,
td.hide-for-landscape,
th.show-for-portrait,
th.hide-for-landscape {
	display: table-cell !important;
}

}

@media only screen and (min-width: 50.063em) {

.hide-for-small-only,
.show-for-small-up,
.hide-for-small,
.hide-for-small-down,
.show-for-medium-only,
.show-for-medium-up,
.show-for-medium,
.show-for-medium-down,
.hide-for-large-only,
.hide-for-large-up,
.hide-for-large,
.show-for-large-down,
.hide-for-xlarge-only,
.hide-for-xlarge-up,
.hide-for-xlarge,
.show-for-xlarge-down,
.hide-for-xxlarge-only,
.hide-for-xxlarge-up,
.hide-for-xxlarge,
.show-for-xxlarge-down {
	display: inherit !important;
}

.show-for-small-only,
.hide-for-small-up,
.show-for-small,
.show-for-small-down,
.hide-for-medium-only,
.hide-for-medium-up,
.hide-for-medium,
.hide-for-medium-down,
.show-for-large-only,
.show-for-large-up,
.show-for-large,
.hide-for-large-down,
.show-for-xlarge-only,
.show-for-xlarge-up,
.show-for-xlarge,
.hide-for-xlarge-down,
.show-for-xxlarge-only,
.show-for-xxlarge-up,
.show-for-xxlarge,
.hide-for-xxlarge-down {
	display: none !important;
}

.hidden-for-small-only,
.visible-for-small-up,
.hidden-for-small,
.hidden-for-small-down,
.visible-for-medium-only,
.visible-for-medium-up,
.visible-for-medium,
.visible-for-medium-down,
.hidden-for-large-only,
.hidden-for-large-up,
.hidden-for-large,
.visible-for-large-down,
.hidden-for-xlarge-only,
.hidden-for-xlarge-up,
.hidden-for-xlarge,
.visible-for-xlarge-down,
.hidden-for-xxlarge-only,
.hidden-for-xxlarge-up,
.hidden-for-xxlarge,
.visible-for-xxlarge-down {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

.visible-for-small-only,
.hidden-for-small-up,
.visible-for-small,
.visible-for-small-down,
.hidden-for-medium-only,
.hidden-for-medium-up,
.hidden-for-medium,
.hidden-for-medium-down,
.visible-for-large-only,
.visible-for-large-up,
.visible-for-large,
.hidden-for-large-down,
.visible-for-xlarge-only,
.visible-for-xlarge-up,
.visible-for-xlarge,
.hidden-for-xlarge-down,
.visible-for-xxlarge-only,
.visible-for-xxlarge-up,
.visible-for-xxlarge,
.hidden-for-xxlarge-down {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

table.hide-for-small-only,
table.show-for-small-up,
table.hide-for-small,
table.hide-for-small-down,
table.show-for-medium-only,
table.show-for-medium-up,
table.show-for-medium,
table.show-for-medium-down,
table.hide-for-large-only,
table.hide-for-large-up,
table.hide-for-large,
table.show-for-large-down,
table.hide-for-xlarge-only,
table.hide-for-xlarge-up,
table.hide-for-xlarge,
table.show-for-xlarge-down,
table.hide-for-xxlarge-only,
table.hide-for-xxlarge-up,
table.hide-for-xxlarge,
table.show-for-xxlarge-down {
	display: table !important;
}

thead.hide-for-small-only,
thead.show-for-small-up,
thead.hide-for-small,
thead.hide-for-small-down,
thead.show-for-medium-only,
thead.show-for-medium-up,
thead.show-for-medium,
thead.show-for-medium-down,
thead.hide-for-large-only,
thead.hide-for-large-up,
thead.hide-for-large,
thead.show-for-large-down,
thead.hide-for-xlarge-only,
thead.hide-for-xlarge-up,
thead.hide-for-xlarge,
thead.show-for-xlarge-down,
thead.hide-for-xxlarge-only,
thead.hide-for-xxlarge-up,
thead.hide-for-xxlarge,
thead.show-for-xxlarge-down {
	display: table-header-group !important;
}

tbody.hide-for-small-only,
tbody.show-for-small-up,
tbody.hide-for-small,
tbody.hide-for-small-down,
tbody.show-for-medium-only,
tbody.show-for-medium-up,
tbody.show-for-medium,
tbody.show-for-medium-down,
tbody.hide-for-large-only,
tbody.hide-for-large-up,
tbody.hide-for-large,
tbody.show-for-large-down,
tbody.hide-for-xlarge-only,
tbody.hide-for-xlarge-up,
tbody.hide-for-xlarge,
tbody.show-for-xlarge-down,
tbody.hide-for-xxlarge-only,
tbody.hide-for-xxlarge-up,
tbody.hide-for-xxlarge,
tbody.show-for-xxlarge-down {
	display: table-row-group !important;
}

tr.hide-for-small-only,
tr.show-for-small-up,
tr.hide-for-small,
tr.hide-for-small-down,
tr.show-for-medium-only,
tr.show-for-medium-up,
tr.show-for-medium,
tr.show-for-medium-down,
tr.hide-for-large-only,
tr.hide-for-large-up,
tr.hide-for-large,
tr.show-for-large-down,
tr.hide-for-xlarge-only,
tr.hide-for-xlarge-up,
tr.hide-for-xlarge,
tr.show-for-xlarge-down,
tr.hide-for-xxlarge-only,
tr.hide-for-xxlarge-up,
tr.hide-for-xxlarge,
tr.show-for-xxlarge-down {
	display: table-row;
}

th.hide-for-small-only,
td.hide-for-small-only,
th.show-for-small-up,
td.show-for-small-up,
th.hide-for-small,
td.hide-for-small,
th.hide-for-small-down,
td.hide-for-small-down,
th.show-for-medium-only,
td.show-for-medium-only,
th.show-for-medium-up,
td.show-for-medium-up,
th.show-for-medium,
td.show-for-medium,
th.show-for-medium-down,
td.show-for-medium-down,
th.hide-for-large-only,
td.hide-for-large-only,
th.hide-for-large-up,
td.hide-for-large-up,
th.hide-for-large,
td.hide-for-large,
th.show-for-large-down,
td.show-for-large-down,
th.hide-for-xlarge-only,
td.hide-for-xlarge-only,
th.hide-for-xlarge-up,
td.hide-for-xlarge-up,
th.hide-for-xlarge,
td.hide-for-xlarge,
th.show-for-xlarge-down,
td.show-for-xlarge-down,
th.hide-for-xxlarge-only,
td.hide-for-xxlarge-only,
th.hide-for-xxlarge-up,
td.hide-for-xxlarge-up,
th.hide-for-xxlarge,
td.hide-for-xxlarge,
th.show-for-xxlarge-down,
td.show-for-xxlarge-down {
	display: table-cell !important;
}

.table-row-options-item {
	height: 2.5rem;
	line-height: 2.5rem;
	font-size: 0.875rem;
}

.table-row-section.mod-flex-v-center {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	padding: 0;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-5,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-5 {
	width: 5.9%;
	padding: 5px;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-10,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-10 {
	width: 11.8%;
	padding: 5px;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-15,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-15 {
	width: 17.7%;
	padding: 5px;
}

.table-row-section.mod-flex-v-center .table-heading.mod-width-20,
.table-row-section.mod-flex-v-center .table-row-data.mod-width-20 {
	width: 23.6%;
	padding: 5px;
}

.reveal-modal.mod-light {
	left: 0;
	margin: 0 auto;
	max-width: 62.5rem;
	right: 0;
	width: 40%;
}

.reveal-modal.mod-light {
	top: 100px;
}

.reveal-modal.mod-wide {
	top: 170px;
}

.eprove-search-dropdown-item {
	padding: 0.625rem;
}

.new-user-guide-toggle {
	margin-top: 30px;
}

}

@media only screen and (min-width: 64.0625em) {

.hide-for-small-only,
.show-for-small-up,
.hide-for-small,
.hide-for-small-down,
.hide-for-medium-only,
.show-for-medium-up,
.hide-for-medium,
.hide-for-medium-down,
.show-for-large-only,
.show-for-large-up,
.show-for-large,
.show-for-large-down,
.hide-for-xlarge-only,
.hide-for-xlarge-up,
.hide-for-xlarge,
.show-for-xlarge-down,
.hide-for-xxlarge-only,
.hide-for-xxlarge-up,
.hide-for-xxlarge,
.show-for-xxlarge-down {
	display: inherit !important;
}

.show-for-small-only,
.hide-for-small-up,
.show-for-small,
.show-for-small-down,
.show-for-medium-only,
.hide-for-medium-up,
.show-for-medium,
.show-for-medium-down,
.hide-for-large-only,
.hide-for-large-up,
.hide-for-large,
.hide-for-large-down,
.show-for-xlarge-only,
.show-for-xlarge-up,
.show-for-xlarge,
.hide-for-xlarge-down,
.show-for-xxlarge-only,
.show-for-xxlarge-up,
.show-for-xxlarge,
.hide-for-xxlarge-down {
	display: none !important;
}

.hidden-for-small-only,
.visible-for-small-up,
.hidden-for-small,
.hidden-for-small-down,
.hidden-for-medium-only,
.visible-for-medium-up,
.hidden-for-medium,
.hidden-for-medium-down,
.visible-for-large-only,
.visible-for-large-up,
.visible-for-large,
.visible-for-large-down,
.hidden-for-xlarge-only,
.hidden-for-xlarge-up,
.hidden-for-xlarge,
.visible-for-xlarge-down,
.hidden-for-xxlarge-only,
.hidden-for-xxlarge-up,
.hidden-for-xxlarge,
.visible-for-xxlarge-down {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

.visible-for-small-only,
.hidden-for-small-up,
.visible-for-small,
.visible-for-small-down,
.visible-for-medium-only,
.hidden-for-medium-up,
.visible-for-medium,
.visible-for-medium-down,
.hidden-for-large-only,
.hidden-for-large-up,
.hidden-for-large,
.hidden-for-large-down,
.visible-for-xlarge-only,
.visible-for-xlarge-up,
.visible-for-xlarge,
.hidden-for-xlarge-down,
.visible-for-xxlarge-only,
.visible-for-xxlarge-up,
.visible-for-xxlarge,
.hidden-for-xxlarge-down {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

table.hide-for-small-only,
table.show-for-small-up,
table.hide-for-small,
table.hide-for-small-down,
table.hide-for-medium-only,
table.show-for-medium-up,
table.hide-for-medium,
table.hide-for-medium-down,
table.show-for-large-only,
table.show-for-large-up,
table.show-for-large,
table.show-for-large-down,
table.hide-for-xlarge-only,
table.hide-for-xlarge-up,
table.hide-for-xlarge,
table.show-for-xlarge-down,
table.hide-for-xxlarge-only,
table.hide-for-xxlarge-up,
table.hide-for-xxlarge,
table.show-for-xxlarge-down {
	display: table !important;
}

thead.hide-for-small-only,
thead.show-for-small-up,
thead.hide-for-small,
thead.hide-for-small-down,
thead.hide-for-medium-only,
thead.show-for-medium-up,
thead.hide-for-medium,
thead.hide-for-medium-down,
thead.show-for-large-only,
thead.show-for-large-up,
thead.show-for-large,
thead.show-for-large-down,
thead.hide-for-xlarge-only,
thead.hide-for-xlarge-up,
thead.hide-for-xlarge,
thead.show-for-xlarge-down,
thead.hide-for-xxlarge-only,
thead.hide-for-xxlarge-up,
thead.hide-for-xxlarge,
thead.show-for-xxlarge-down {
	display: table-header-group !important;
}

tbody.hide-for-small-only,
tbody.show-for-small-up,
tbody.hide-for-small,
tbody.hide-for-small-down,
tbody.hide-for-medium-only,
tbody.show-for-medium-up,
tbody.hide-for-medium,
tbody.hide-for-medium-down,
tbody.show-for-large-only,
tbody.show-for-large-up,
tbody.show-for-large,
tbody.show-for-large-down,
tbody.hide-for-xlarge-only,
tbody.hide-for-xlarge-up,
tbody.hide-for-xlarge,
tbody.show-for-xlarge-down,
tbody.hide-for-xxlarge-only,
tbody.hide-for-xxlarge-up,
tbody.hide-for-xxlarge,
tbody.show-for-xxlarge-down {
	display: table-row-group !important;
}

tr.hide-for-small-only,
tr.show-for-small-up,
tr.hide-for-small,
tr.hide-for-small-down,
tr.hide-for-medium-only,
tr.show-for-medium-up,
tr.hide-for-medium,
tr.hide-for-medium-down,
tr.show-for-large-only,
tr.show-for-large-up,
tr.show-for-large,
tr.show-for-large-down,
tr.hide-for-xlarge-only,
tr.hide-for-xlarge-up,
tr.hide-for-xlarge,
tr.show-for-xlarge-down,
tr.hide-for-xxlarge-only,
tr.hide-for-xxlarge-up,
tr.hide-for-xxlarge,
tr.show-for-xxlarge-down {
	display: table-row;
}

th.hide-for-small-only,
td.hide-for-small-only,
th.show-for-small-up,
td.show-for-small-up,
th.hide-for-small,
td.hide-for-small,
th.hide-for-small-down,
td.hide-for-small-down,
th.hide-for-medium-only,
td.hide-for-medium-only,
th.show-for-medium-up,
td.show-for-medium-up,
th.hide-for-medium,
td.hide-for-medium,
th.hide-for-medium-down,
td.hide-for-medium-down,
th.show-for-large-only,
td.show-for-large-only,
th.show-for-large-up,
td.show-for-large-up,
th.show-for-large,
td.show-for-large,
th.show-for-large-down,
td.show-for-large-down,
th.hide-for-xlarge-only,
td.hide-for-xlarge-only,
th.hide-for-xlarge-up,
td.hide-for-xlarge-up,
th.hide-for-xlarge,
td.hide-for-xlarge,
th.show-for-xlarge-down,
td.show-for-xlarge-down,
th.hide-for-xxlarge-only,
td.hide-for-xxlarge-only,
th.hide-for-xxlarge-up,
td.hide-for-xxlarge-up,
th.hide-for-xxlarge,
td.hide-for-xxlarge,
th.show-for-xxlarge-down,
td.show-for-xxlarge-down {
	display: table-cell !important;
}

}

@media only screen and (min-width: 90.0625em) {

.hide-for-small-only,
.show-for-small-up,
.hide-for-small,
.hide-for-small-down,
.hide-for-medium-only,
.show-for-medium-up,
.hide-for-medium,
.hide-for-medium-down,
.hide-for-large-only,
.show-for-large-up,
.hide-for-large,
.hide-for-large-down,
.show-for-xlarge-only,
.show-for-xlarge-up,
.show-for-xlarge,
.show-for-xlarge-down,
.hide-for-xxlarge-only,
.hide-for-xxlarge-up,
.hide-for-xxlarge,
.show-for-xxlarge-down {
	display: inherit !important;
}

.show-for-small-only,
.hide-for-small-up,
.show-for-small,
.show-for-small-down,
.show-for-medium-only,
.hide-for-medium-up,
.show-for-medium,
.show-for-medium-down,
.show-for-large-only,
.hide-for-large-up,
.show-for-large,
.show-for-large-down,
.hide-for-xlarge-only,
.hide-for-xlarge-up,
.hide-for-xlarge,
.hide-for-xlarge-down,
.show-for-xxlarge-only,
.show-for-xxlarge-up,
.show-for-xxlarge,
.hide-for-xxlarge-down {
	display: none !important;
}

.hidden-for-small-only,
.visible-for-small-up,
.hidden-for-small,
.hidden-for-small-down,
.hidden-for-medium-only,
.visible-for-medium-up,
.hidden-for-medium,
.hidden-for-medium-down,
.hidden-for-large-only,
.visible-for-large-up,
.hidden-for-large,
.hidden-for-large-down,
.visible-for-xlarge-only,
.visible-for-xlarge-up,
.visible-for-xlarge,
.visible-for-xlarge-down,
.hidden-for-xxlarge-only,
.hidden-for-xxlarge-up,
.hidden-for-xxlarge,
.visible-for-xxlarge-down {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

.visible-for-small-only,
.hidden-for-small-up,
.visible-for-small,
.visible-for-small-down,
.visible-for-medium-only,
.hidden-for-medium-up,
.visible-for-medium,
.visible-for-medium-down,
.visible-for-large-only,
.hidden-for-large-up,
.visible-for-large,
.visible-for-large-down,
.hidden-for-xlarge-only,
.hidden-for-xlarge-up,
.hidden-for-xlarge,
.hidden-for-xlarge-down,
.visible-for-xxlarge-only,
.visible-for-xxlarge-up,
.visible-for-xxlarge,
.hidden-for-xxlarge-down {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

table.hide-for-small-only,
table.show-for-small-up,
table.hide-for-small,
table.hide-for-small-down,
table.hide-for-medium-only,
table.show-for-medium-up,
table.hide-for-medium,
table.hide-for-medium-down,
table.hide-for-large-only,
table.show-for-large-up,
table.hide-for-large,
table.hide-for-large-down,
table.show-for-xlarge-only,
table.show-for-xlarge-up,
table.show-for-xlarge,
table.show-for-xlarge-down,
table.hide-for-xxlarge-only,
table.hide-for-xxlarge-up,
table.hide-for-xxlarge,
table.show-for-xxlarge-down {
	display: table !important;
}

thead.hide-for-small-only,
thead.show-for-small-up,
thead.hide-for-small,
thead.hide-for-small-down,
thead.hide-for-medium-only,
thead.show-for-medium-up,
thead.hide-for-medium,
thead.hide-for-medium-down,
thead.hide-for-large-only,
thead.show-for-large-up,
thead.hide-for-large,
thead.hide-for-large-down,
thead.show-for-xlarge-only,
thead.show-for-xlarge-up,
thead.show-for-xlarge,
thead.show-for-xlarge-down,
thead.hide-for-xxlarge-only,
thead.hide-for-xxlarge-up,
thead.hide-for-xxlarge,
thead.show-for-xxlarge-down {
	display: table-header-group !important;
}

tbody.hide-for-small-only,
tbody.show-for-small-up,
tbody.hide-for-small,
tbody.hide-for-small-down,
tbody.hide-for-medium-only,
tbody.show-for-medium-up,
tbody.hide-for-medium,
tbody.hide-for-medium-down,
tbody.hide-for-large-only,
tbody.show-for-large-up,
tbody.hide-for-large,
tbody.hide-for-large-down,
tbody.show-for-xlarge-only,
tbody.show-for-xlarge-up,
tbody.show-for-xlarge,
tbody.show-for-xlarge-down,
tbody.hide-for-xxlarge-only,
tbody.hide-for-xxlarge-up,
tbody.hide-for-xxlarge,
tbody.show-for-xxlarge-down {
	display: table-row-group !important;
}

tr.hide-for-small-only,
tr.show-for-small-up,
tr.hide-for-small,
tr.hide-for-small-down,
tr.hide-for-medium-only,
tr.show-for-medium-up,
tr.hide-for-medium,
tr.hide-for-medium-down,
tr.hide-for-large-only,
tr.show-for-large-up,
tr.hide-for-large,
tr.hide-for-large-down,
tr.show-for-xlarge-only,
tr.show-for-xlarge-up,
tr.show-for-xlarge,
tr.show-for-xlarge-down,
tr.hide-for-xxlarge-only,
tr.hide-for-xxlarge-up,
tr.hide-for-xxlarge,
tr.show-for-xxlarge-down {
	display: table-row;
}

th.hide-for-small-only,
td.hide-for-small-only,
th.show-for-small-up,
td.show-for-small-up,
th.hide-for-small,
td.hide-for-small,
th.hide-for-small-down,
td.hide-for-small-down,
th.hide-for-medium-only,
td.hide-for-medium-only,
th.show-for-medium-up,
td.show-for-medium-up,
th.hide-for-medium,
td.hide-for-medium,
th.hide-for-medium-down,
td.hide-for-medium-down,
th.hide-for-large-only,
td.hide-for-large-only,
th.show-for-large-up,
td.show-for-large-up,
th.hide-for-large,
td.hide-for-large,
th.hide-for-large-down,
td.hide-for-large-down,
th.show-for-xlarge-only,
td.show-for-xlarge-only,
th.show-for-xlarge-up,
td.show-for-xlarge-up,
th.show-for-xlarge,
td.show-for-xlarge,
th.show-for-xlarge-down,
td.show-for-xlarge-down,
th.hide-for-xxlarge-only,
td.hide-for-xxlarge-only,
th.hide-for-xxlarge-up,
td.hide-for-xxlarge-up,
th.hide-for-xxlarge,
td.hide-for-xxlarge,
th.show-for-xxlarge-down,
td.show-for-xxlarge-down {
	display: table-cell !important;
}

}

@media only screen and (min-width: 120.0625em) {

.hide-for-small-only,
.show-for-small-up,
.hide-for-small,
.hide-for-small-down,
.hide-for-medium-only,
.show-for-medium-up,
.hide-for-medium,
.hide-for-medium-down,
.hide-for-large-only,
.show-for-large-up,
.hide-for-large,
.hide-for-large-down,
.hide-for-xlarge-only,
.show-for-xlarge-up,
.hide-for-xlarge,
.hide-for-xlarge-down,
.show-for-xxlarge-only,
.show-for-xxlarge-up,
.show-for-xxlarge,
.show-for-xxlarge-down {
	display: inherit !important;
}

.show-for-small-only,
.hide-for-small-up,
.show-for-small,
.show-for-small-down,
.show-for-medium-only,
.hide-for-medium-up,
.show-for-medium,
.show-for-medium-down,
.show-for-large-only,
.hide-for-large-up,
.show-for-large,
.show-for-large-down,
.show-for-xlarge-only,
.hide-for-xlarge-up,
.show-for-xlarge,
.show-for-xlarge-down,
.hide-for-xxlarge-only,
.hide-for-xxlarge-up,
.hide-for-xxlarge,
.hide-for-xxlarge-down {
	display: none !important;
}

.hidden-for-small-only,
.visible-for-small-up,
.hidden-for-small,
.hidden-for-small-down,
.hidden-for-medium-only,
.visible-for-medium-up,
.hidden-for-medium,
.hidden-for-medium-down,
.hidden-for-large-only,
.visible-for-large-up,
.hidden-for-large,
.hidden-for-large-down,
.hidden-for-xlarge-only,
.visible-for-xlarge-up,
.hidden-for-xlarge,
.hidden-for-xlarge-down,
.visible-for-xxlarge-only,
.visible-for-xxlarge-up,
.visible-for-xxlarge,
.visible-for-xxlarge-down {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

.visible-for-small-only,
.hidden-for-small-up,
.visible-for-small,
.visible-for-small-down,
.visible-for-medium-only,
.hidden-for-medium-up,
.visible-for-medium,
.visible-for-medium-down,
.visible-for-large-only,
.hidden-for-large-up,
.visible-for-large,
.visible-for-large-down,
.visible-for-xlarge-only,
.hidden-for-xlarge-up,
.visible-for-xlarge,
.visible-for-xlarge-down,
.hidden-for-xxlarge-only,
.hidden-for-xxlarge-up,
.hidden-for-xxlarge,
.hidden-for-xxlarge-down {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

table.hide-for-small-only,
table.show-for-small-up,
table.hide-for-small,
table.hide-for-small-down,
table.hide-for-medium-only,
table.show-for-medium-up,
table.hide-for-medium,
table.hide-for-medium-down,
table.hide-for-large-only,
table.show-for-large-up,
table.hide-for-large,
table.hide-for-large-down,
table.hide-for-xlarge-only,
table.show-for-xlarge-up,
table.hide-for-xlarge,
table.hide-for-xlarge-down,
table.show-for-xxlarge-only,
table.show-for-xxlarge-up,
table.show-for-xxlarge,
table.show-for-xxlarge-down {
	display: table !important;
}

thead.hide-for-small-only,
thead.show-for-small-up,
thead.hide-for-small,
thead.hide-for-small-down,
thead.hide-for-medium-only,
thead.show-for-medium-up,
thead.hide-for-medium,
thead.hide-for-medium-down,
thead.hide-for-large-only,
thead.show-for-large-up,
thead.hide-for-large,
thead.hide-for-large-down,
thead.hide-for-xlarge-only,
thead.show-for-xlarge-up,
thead.hide-for-xlarge,
thead.hide-for-xlarge-down,
thead.show-for-xxlarge-only,
thead.show-for-xxlarge-up,
thead.show-for-xxlarge,
thead.show-for-xxlarge-down {
	display: table-header-group !important;
}

tbody.hide-for-small-only,
tbody.show-for-small-up,
tbody.hide-for-small,
tbody.hide-for-small-down,
tbody.hide-for-medium-only,
tbody.show-for-medium-up,
tbody.hide-for-medium,
tbody.hide-for-medium-down,
tbody.hide-for-large-only,
tbody.show-for-large-up,
tbody.hide-for-large,
tbody.hide-for-large-down,
tbody.hide-for-xlarge-only,
tbody.show-for-xlarge-up,
tbody.hide-for-xlarge,
tbody.hide-for-xlarge-down,
tbody.show-for-xxlarge-only,
tbody.show-for-xxlarge-up,
tbody.show-for-xxlarge,
tbody.show-for-xxlarge-down {
	display: table-row-group !important;
}

tr.hide-for-small-only,
tr.show-for-small-up,
tr.hide-for-small,
tr.hide-for-small-down,
tr.hide-for-medium-only,
tr.show-for-medium-up,
tr.hide-for-medium,
tr.hide-for-medium-down,
tr.hide-for-large-only,
tr.show-for-large-up,
tr.hide-for-large,
tr.hide-for-large-down,
tr.hide-for-xlarge-only,
tr.show-for-xlarge-up,
tr.hide-for-xlarge,
tr.hide-for-xlarge-down,
tr.show-for-xxlarge-only,
tr.show-for-xxlarge-up,
tr.show-for-xxlarge,
tr.show-for-xxlarge-down {
	display: table-row;
}

th.hide-for-small-only,
td.hide-for-small-only,
th.show-for-small-up,
td.show-for-small-up,
th.hide-for-small,
td.hide-for-small,
th.hide-for-small-down,
td.hide-for-small-down,
th.hide-for-medium-only,
td.hide-for-medium-only,
th.show-for-medium-up,
td.show-for-medium-up,
th.hide-for-medium,
td.hide-for-medium,
th.hide-for-medium-down,
td.hide-for-medium-down,
th.hide-for-large-only,
td.hide-for-large-only,
th.show-for-large-up,
td.show-for-large-up,
th.hide-for-large,
td.hide-for-large,
th.hide-for-large-down,
td.hide-for-large-down,
th.hide-for-xlarge-only,
td.hide-for-xlarge-only,
th.show-for-xlarge-up,
td.show-for-xlarge-up,
th.hide-for-xlarge,
td.hide-for-xlarge,
th.hide-for-xlarge-down,
td.hide-for-xlarge-down,
th.show-for-xxlarge-only,
td.show-for-xxlarge-only,
th.show-for-xxlarge-up,
td.show-for-xxlarge-up,
th.show-for-xxlarge,
td.show-for-xxlarge,
th.show-for-xxlarge-down,
td.show-for-xxlarge-down {
	display: table-cell !important;
}

}

@media only screen and (max-width: 50em) and (max-width: 40em) {

.popupVideoModal {
	top: 50% !important; /* override inline style injected from JS */
	left: 50% !important;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.popupVideoModal {
	top: 50% !important; /* override inline style injected from JS */
	left: 50% !important;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

}

@media only screen and (max-width: 50em) {

.table-heading.mod-flex-order-1,
.table-row-data.mod-flex-order-1 {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.table-heading.mod-flex-order-2,
.table-row-data.mod-flex-order-2 {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

.table-heading.mod-flex-order-3,
.table-row-data.mod-flex-order-3 {
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
}

.table-heading.mod-flex-order-4,
.table-row-data.mod-flex-order-4 {
	-webkit-box-ordinal-group: 5;
	-webkit-order: 4;
	-ms-flex-order: 4;
	order: 4;
}

.table-heading.mod-flex-order-5,
.table-row-data.mod-flex-order-5 {
	-webkit-box-ordinal-group: 6;
	-webkit-order: 5;
	-ms-flex-order: 5;
	order: 5;
}

.table-row-section.mod-flex-v-center .table-row-data.mod-flex-order-1 {
	width: 100%;
}

.table-row-section.mod-flex-v-center .table-row-data.mod-width-20 .u-block-text {
	display: none;
}

.table-row-section.mod-flex-v-center .table-row-data.mod-width-10 {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 0.9375rem;
	color: #aaa;
}

.table-row-section.mod-flex-v-center .table-row-data.mod-width-10 .u-small-text {
	font-style: normal;
	font-size: inherit;
	color: inherit;
}

.table-row-section.mod-flex-v-center .table-row-data.mod-width-10 .u-small-text:after {
	content: ",\00a0";
}

}

@media print {

* {
	background: transparent !important;
	box-shadow: none !important;
	color: #000000 !important; /* Black prints faster: h5bp.com/s */
	text-shadow: none !important;
}

.show-for-print {
	display: block;
}

.hide-for-print {
	display: none;
}

table.show-for-print {
	display: table !important;
}

thead.show-for-print {
	display: table-header-group !important;
}

tbody.show-for-print {
	display: table-row-group !important;
}

tr.show-for-print {
	display: table-row !important;
}

td.show-for-print {
	display: table-cell !important;
}

th.show-for-print {
	display: table-cell !important;
}

a,
a:visited {
	text-decoration: underline;
}

a[href]:after {
	content: " (" attr(href) ")";
}

abbr[title]:after {
	content: " (" attr(title) ")";
}

.ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
	content: "";
}

pre,
blockquote {
	border: 1px solid #999999;
	page-break-inside: avoid;
}

thead {
	display: table-header-group; /* h5bp.com/t */
}

tr,
img {
	page-break-inside: avoid;
}

img {
	max-width: 100% !important;
}

p,
.admin-upload-roster ul,
h2,
h3 {
	orphans: 3;
	widows: 3;
}

h2,
h3 {
	page-break-after: avoid;
}

.hide-on-print {
	display: none !important;
}

.print-only {
	display: block !important;
}

.hide-for-print {
	display: none !important;
}

.show-for-print {
	display: inherit !important;
}

.show-for-print {
	display: block;
}

.hide-for-print {
	display: none;
}

table.show-for-print {
	display: table !important;
}

thead.show-for-print {
	display: table-header-group !important;
}

tbody.show-for-print {
	display: table-row-group !important;
}

tr.show-for-print {
	display: table-row !important;
}

td.show-for-print {
	display: table-cell !important;
}

th.show-for-print {
	display: table-cell !important;
}

.small-1 {
	float: left;
	width: 8.33333%;
}

.small-2 {
	float: left;
	width: 16.66667%;
}

.small-3 {
	float: left;
	width: 25%;
}

.small-4 {
	float: left;
	width: 33.33333%;
}

.small-5 {
	float: left;
	width: 41.66667%;
}

.small-6 {
	float: left;
	width: 50%;
}

.small-7 {
	float: left;
	width: 58.33333%;
}

.small-8 {
	float: left;
	width: 66.66667%;
}

.small-9 {
	float: left;
	width: 75%;
}

.small-10 {
	float: left;
	width: 83.33333%;
}

.small-11 {
	float: left;
	width: 91.66667%;
}

.small-12 {
	float: left;
	width: 100%;
}

.app-bar + .page-container {
	padding: 0;
	height: auto;
	overflow-y: auto;
}

.eprove-content-container {
	padding: 0;
}

p,
.admin-upload-roster ul {
	line-height: 1.5;
}

.row {
	max-width: none;
}

.row .row {
	margin: 0;
}

.column,
.columns {
	padding-left: 0;
	padding-right: 0;
}

.text-right {
	text-align: right !important;
}

h5 {
	color: #566B81 !important;
	font-size: 11pt;
	font-weight: 600;
	font-family: "Open Sans", sans-serif;
	margin-bottom: 0;
	border: 0;
}

p,
.admin-upload-roster ul {
	color: #000;
	font-family: 'Open Sans', sans-serif;
	font-size: 10pt;
	margin-top: 0;
}

.eprove-cart td p.small,
.eprove-cart td .admin-upload-roster ul.small,
.admin-upload-roster .eprove-cart td ul.small {
	font-size: 10pt;
}

table {
	width: 100%;
	page-break-inside: auto;
	margin-top: 0;
}

thead {
	display: table-header-group;
}

th {
	font-weight: 300;
	border-bottom: 1px solid #ddd;
}

tr {
	page-break-inside: avoid;
	page-break-after: auto;
}

table tr td {
	color: #555;
	font-weight: 300;
	padding-top: 15pt;
	padding-bottom: 15pt;
	padding-left: 0;
	padding-right: 0;
	border-bottom: 1px solid #ddd;
	page-break-inside: avoid;
	page-break-after: auto;
}

.eprove-cart td span {
	font-size: 13pt;
}

.eprove-cart__description > div {
	width: 100%;
}

.eprove-cart__discount,
.eprove-cart__purchase-total {
	border: 0;
	padding-top: 40px;
	padding-bottom: 0;
}

.eprove-cart input[type="text"] {
	border: 0;
	font-size: 13pt;
	font-weight: 300;
}

.eprove-cart__price-group {
	display: block;
	text-align: right;
	padding-right: 0.1in;
}

.eprove-cart__price-group span {
	float: none;
}

.eprove-cart__price-group .label:after {
	content: ' ';
}

}

@media not print {

.show-for-print {
	display: none !important;
}

}

