.template-distribution-configuration {
  .selected-institution-container {
    margin-bottom: 30px;
  }

  span.owner {
    font-size: 16px;
  }

  span.owner-info {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  .tooltip-trigger-icon {
    vertical-align: -5px;
  }

  .institution-selection-container {
    margin-bottom: 30px;

    .selected-institution-owner {
      background-color: #F3F3F3;
      border: 1px solid #808080;
      border-radius: 3px;
      padding: 0px 8px;
      position: relative;

      p.institution-name {
        font-size: 15px;
        font-weight: normal;
        margin-right: 15px;
        color: #4d4d4d;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span.close-icon {
        color: #4d4d4d;
        cursor: pointer;
        font-size: 16px;
        float:right;
        line-height: 40px; // Note there is 3 px of implicit padding in this line height
      }
    }
  }

  .institution-select__input {
    height: 38px;
    border-color: #ccc;

    .autofill-container {
      display: block;
      margin-top: 39px;
      left: 30px;
      border-radius: 2px;
      width: 88%;

      ul {
        max-height: 350px;

        li {
          padding: 8px 10px;

          .inst-name {
            font-size: 16px;
          }

          .inst-city {
            font-size: 12px;
          }
        }
      }
    }

    input[type="text"] {
      margin-top: 0;
      background: white;
      height: 36px;
      padding: 0 5px;
      box-shadow: none;
    }

    span.icon.icon-search {
      color: #CCCCCC;
      font-size: 24px;
      padding-top: 7px;
    }

    span.icon.icon-close {
      font-size: 20px;
      color: #ccc;
      padding-top: 9px;
      padding-right: 4px;
    }
  }

  ul.institution-select__group {
    display: none;
  }

  .dist-list-title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .dist-list-text {
    margin-bottom: 40px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;

    button {
      background-color: #148900;
      border-radius: 3px;
      width: 200px;
      height: 40px;
      font-size: 14px;
      font-weight: 700;

      &:disabled {
        background-color: #BBBBBB;
      }
    }
  }

  .advanced-inst-checkbox {
    margin-top: 20px;

    &>span {
      font-size: 16px;
      vertical-align: middle;

      &.title {
        font-weight: 700;
      }

      &.subtitle {
        font-weight: normal;
      }
    }


  }
}
