
.change-roster-modal {
  position: fixed;
  z-index: 3000;
  border-radius: 5px;
  bottom: 0;
  top: -10%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  width: 500px;
  height: 550px;
  max-height: 555px;
  overflow: hidden;
  -webkit-box-shadow: -3px 5px 80px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: -3px 5px 80px 0px rgba(0, 0, 0, 0.52);
  box-shadow: -3px 5px 80px 0px rgba(0, 0, 0, 0.52);
  font-family: 'Open Sans', sans-serif;

  &.has-file-selected {
    height: 650px;
  }

  &.confirm-modal {
    height: 300px;
  }

  &.has-error {
    height: 680px;
  }


  .upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.1);
    height: auto;
    width: auto;
  }

  .upload-spinner {
    font-size: 100px;
    height: 100px;
    width: 100px;
    line-height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
  .change-roster-modal-title {
    height: 60px;
    background-color: #148900;
    color: white;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    &.has-error {
      background-color: #d54230;
    }

    i {
      font-size: 40px;
      margin-right: 10px;
    }
  }

  #file {
    display: none;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }


  .modal-content {
    padding: 30px 40px;

    .message {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .attach-well {
    display: block;
    border: 1px solid #aaa;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    &.has-error {
      border: 1px solid #D54230;
    }
  }
  .file-size-limit{
    font-size: initial;
    color: grey;
  }
  .attachment-header{
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    color: black;
    width: 91%;
    text-align: inherit;
    padding-left: inherit;
    margin: inherit;
    font-weight: 500;
}

  .attach-header {
    cursor: pointer;
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
    background-color: #F8F8F8;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    font-family: 'Open Sans', sans-serif;
    > p {
      display: block;
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 4px;
      color: #565656;
      &.small {
        font-size: 14px;
        line-height: 14px;
        color: #AAAAAA;
      }
    }
    button.disabled:hover,  button.disabled:focus, 
    button[disabled]:hover, button[disabled]:focus, 
    .button.disabled:hover, .button.disabled:focus, 
    .button[disabled]:hover, .button[disabled]:focus{
        background-color: #f8f8f8; 
        color: black;
        cursor: not-allowed;
}
  }

  .attach-info {
    p {
      width: 100%;
      font-size: 14px;
      line-height: 14px;
      color: #AAAAAA;
      font-weight: bold;
      padding: 0 5px 5px 5px;
      background-color: #F8F8F8;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  .attach-button {
    float: right;
    height: 36px;
    width: 36px;
    padding: 0;
    position: relative;
    top: 5px;
    background-color: #FFF;
    border: 1px solid #979797;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin:0;

    span.icon-cloud-upload {
      color: #6FC96F;
      font-size: 22px;
      line-height: 22px;
      justify-content: center;
      vertical-align: middle;
    }

    &:hover {
      background-color: #FFF;
      border: 1px solid #979797;

      span.icon-cloud-upload {
        color: #6FC96F;
      }
    }
  }

  .close-message {
    padding: 20px 40px 10px 40px;
    width: 100%;

    color: #565656;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .upload-success-message{
    padding: 5px 40px;
    font-weight: 400;
    color: #3f3f3f;
    margin-top: 20px;
  }
  .upload-error-message{
    padding: 5px 45px;
    font-weight: 600;
    color: #3f3f3f;
  }
  textarea.attach-comments {
    min-height: 50px;
    resize: none;
    overflow-y: scroll;
    width: 100%;
    background: none;
    line-height: 20px;
  }

  .button-row {
    margin-top: 10px;
    > button {
      width: 117px;
      height: 45px;
      float: right;
      color: #fff;
      margin-left: 5px;
      font-family: 'Open Sans', sans-serif;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;

      &.cancel {
        background-color: #BBB;
      }
      &.submit {
        background-color: #148900;
        &:disabled {
          background-color: #565656;
          opacity: 1;
        }
      }

      &.close {
        margin-right: 20px;
      }

    }
  }

  .error-container {
    color: #D63E37;
    padding-top: 5px;

    > p {
      display: inline-block;
    }
    > i {
      display: inline-block;
    }
  }
}