// TAYLOR OVERRIDES FOR THE ECHO APP CSS
// AND STYLES FOR THE NEW PAGES

@import "./templates/publish-content";
@import "./templates/sharing-checkboxes";
@import "./templates/sharing-content";
@import "./templates/publish-checkboxes";
@import "./templates/create-content";
@import "./templates/browse-content";
@import "./templates/manage-content";
@import "./templates/preview-content";
@import "./templates/select-language";
@import "./templates/survey-queue";
@import "./templates/select-institution";
@import "./templates/tooltips";
@import "./templates/template-admin-selection-modal";
@import "./templates/template-distribution-configuration";
@import "./templates/template-configuration";
@import "./templates/branded-checkbox";
@import "./templates/datetime-picker";
@import "./templates/moment-picker-custom";
@import "./templates/assign-participants";
@import "./reporting/view-report";
@import "templates/add-edit-user-pii-toggle";
@import "./templates/manage_users";
@import "./templates/dashboard-content";