.rtl-section-label {
  right: -20px !important;
}

.rtl-section-name {
  right: 20px;
}

.rtl-sidebar-label {
  padding-right: 1.5rem !important;

  &.current {
    border-right: 5px solid #6fc96f !important;
    border-left: 0 !important;
  }
}

.rtl-sidebar-subitem {
  padding-right: 3.5rem !important;

  &.current {
    border-right: 5px solid #6fc96f !important;
    border-left: 0 !important;
  }
}

.min-max-option-dropdown {
  display: inline-block;
  margin-left: 25px;

  select {
    width: 50px;
  }

  label {
    display: inline;
  }
}

.inline-checkbox {
  display: inline-block;
  margin-left: 15px;

  &:first-child {
    margin-left: 0px;
  }
}

input.template-answer-value {
  width: 50px;
  margin-left: 15px;
  text-align: center;
  display: inline-block;
  background: #fff;
  border: 0;
  border-bottom: 1px dashed #777;
  box-shadow: none;
  padding: 0;
  height: 1.875rem;
  line-height: 1.875rem;
  margin-bottom: 0;
  font-size: 1.25rem;

  &:focus {
    box-shadow: none;
    background: inherit;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

select {
  -moz-appearance: none;
}

.publish-info {

  span.text, div.text {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
  }

  span.emphasized {
    color: black;
    font-weight: bold;
  }

  a.emphasized {
    color: #6fc96f;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .text-line {
    margin-bottom: 15px;
  }

}

.survey-header {
  .survey-header__details {
    .button.green-btn {
      background-color: #148900;
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      border: none;
    }
  }
}

.answer-val-tooltip {
  position: absolute;
  left: 265px;
  z-index: 88;
  top: 52px;
}

.answer-val-tooltip-dropdown {
  position: absolute;
  left: 214px;
  z-index: 88;
  top: 52px;
}

// overridng echo.css for the answer tags in
// template-question-answer-options.html
.option-tag {
  margin-top: -10px;

  .survey__tag-container {
    line-height: 1.5 !important;
  }

  .icon {
    border: none !important;
    background-color: #f6f6f6 !important;
    position: relative !important;
    top: 2px !important;
    right: 1px !important;
  }

  .tag-text {
    position: relative !important;
    left: 2px !important;
  }

  .survey__tag-container {
    .survey__tag-item {
      display: inline-block !important;
      color: #666 !important;
      background-color: #f6f6f6 !important;
      font-size: 0.75rem !important;
      font-weight: 400 !important;
      padding: 0.20rem !important;
      margin-right: 0.625rem !important;
      margin-bottom: 0.625rem !important;
      border-radius: 0.125rem !important;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16) !important;
      width: auto !important;
      height: auto !important;
      border: none !important;
      vertical-align: baseline !important;
    }
  }

}

.survey__choice input:checked + label span.icon-close:first-child:before,
.survey__mc input:checked + label span.icon-close:first-child:before {
  content: 'k';
  background-color: transparent;
}

.survey__choice input:checked + label span.survey__tag-item:first-child:before,
.survey__mc input:checked + label span.survey__tag-item:first-child:before {
  content: normal;
}
