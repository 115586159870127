/* CHECKBOXES */
.surveys-checkbox {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;

  label {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;

    &:after {
      opacity: 0;
    }
  }

  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      content: '\F00C';
      position: absolute;
      top: 2px;
      left: 1px;
      opacity: 1;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 16px;
      text-rendering: auto;
    }
  }

  &.checkbox-branded {
    label {
      background: white;
      border: 1px solid #ccc;
    }
    input[type=checkbox] {
      &:checked + label:after {
        color: white;
      }
      &:checked + label {
        background: #148900;
        border: 1px solid #148900;
      }
      &:disabled + label {
        cursor: not-allowed;
      }
    }
  }
}
