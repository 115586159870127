a.button.btn-black {
  border-color: #aaa;
  color: #777;

  &:hover {
    background: #ebebeb;
  }
}

input::-ms-clear {
  display: none !important;
}