.template-administration-selection-modal.orange-modal {
  h2 {
    background-color: #F4AA34;
  }
}

.template-administration-selection-modal {
  padding: 0 0 50px 0;
  width: 495px;

  h2 {
    margin: 0;
    background-color: #148900;
    color: white;
    padding: 20px 0 8px;

    i {
      font-size: 55px;
    }

    span {
      color: white;
      font-style: normal;
      font-size: 24px;
      font-weight: 700;
      vertical-align: 15px;
    }
  }

  p {
    padding: 38px 48px 0 48px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    .top-text {
      margin-bottom: 22px;
    }

    .weighted-text {
      font-weight: 700;
    }
  }

  button {
    float: right;
    border-radius: 3px;
    width: 115px;
    height: 43px;
    color: white;

    &.cancel {
      background-color: #148900;
      margin-right: 50px;

      &:hover {
        background-color: #148900;        
      }
    }

    &.confirm {
      background-color: #D0D0D0 !important;
      box-shadow: none !important;
    }
  }
}


/**
  This class is used to style the download report popup
 */
.report-download-modal {
  padding: 0 0 50px 0;
  width: 495px;

  .center-text {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  h2 {
    margin: 0;
    background-color: #148900;
    color: white;
    padding: 20px 0 8px;

    i {
      font-size: 55px;
    }

    span {
      color: white;
      font-style: normal;
      font-size: 24px;
      font-weight: 700;
      vertical-align: 15px;
    }
  }

  p {
    padding: 38px 48px 0 48px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    .top-text {
      margin-bottom: 22px;
    }

    .weighted-text {
      font-weight: 700;
    }
  }

  button {
    border-radius: 3px;
    min-width: 115px;
    height: 43px;
    color: white;

    &.cancel {
      background-color: #D0D0D0 !important;
      margin-right: 50px;

      &:hover {
        background-color: #148900;
      }
    }

    &.confirm {
      background-color: #148900 !important;
      box-shadow: none !important;
    }
  }
}


/**
  This class is used to style the download report popup
 */
.report-request-success-modal {
  padding: 0 0 12px 0;
  width: 495px;
  border: 1px solid #000;

  .new-line {
    display:block;
    clear:both;
  }

  .center-text {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .green-check {
    color: #148900;
  }

  h2 {
    margin: 0;
    background-color: #EEE;
    color: #000;
    padding: 10px 0 10px 18px;
    text-align: left;
    color: #000;
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    i {
      font-size: 55px;
    }
  }

  p {
    padding: 2% 8%;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    text-align: center;

    .top-text {
      margin-bottom: 22px;
    }

    .weighted-text {
      font-weight: 700;
    }
  }

  button {
    border-radius: 3px;
    min-width: 115px;
    height: 43px;
    color: white;
    margin-top: 15px;

    &.cancel {
      background-color: #D0D0D0 !important;
      margin-right: 50px;

      &:hover {
        background-color: #148900;
      }
    }

    &.confirm {
      background-color: #FFF !important;
      color: #000 !important;
      border: 1px solid #000;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      box-shadow: none !important;
      font-family: "Open Sans";
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
}

/**
  This class is used to style the publish template modal
 */
.publish-template-modal {
  padding: 0 0 50px 0;
  width: 495px;
  -webkit-box-shadow: 0px 0px 14px 14px rgba(0,0,0,.1);
  -moz-box-shadow: 0px 0px 14px 14px rgba(0,0,0,.1);
  box-shadow: 0px 0px 14px 14px rgba(0,0,0,.1);

  .center-text {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  h2 {
    margin: 0 0 20px 0;
    background-color: #F5A944;
    color: white;
    padding: 20px 0 8px;
    font-size: 26px;

    i {
      font-size: 38px;
    }

    span {
      color: white;
      font-style: normal;
      font-size: 24px;
      font-weight: 700;
      vertical-align: 6px;
    }
  }

  p {
    padding: 16px 5% 0 5%;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    span {
      font-weight: bold;
    }

    .top-text {
      margin-bottom: 22px;
    }

    .weighted-text {
      font-weight: 700;
    }
  }

  button {
    border-radius: 3px;
    min-width: 115px;
    height: 43px;
    color: white;
    float: right;
    font-weight: bold;

    &.cancel {
      background-color: #148900 !important;
      box-shadow: none !important;
      margin-right: 10%;
    }
    &.confirm {
      background-color: #D0D0D0 !important;
      margin-right: 5px;
      &:hover {
        background-color: #148900;
      }
    }
  }
}
