.manage-content {
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 15px;

  .ellipsis-inline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
    display: inline-block;
  }

  .content-name {
    max-width: 180px;
    display: inline-block; //fallback for non webkit browsers
    display: -webkit-inline-box;
    white-space: initial;
    max-height: 35px;
    vertical-align: text-top;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .content-last-edited {
    max-width: 135px;
  }

  .exit-off-canvas {
    z-index: 5000;
    position: fixed;
  }

  .new-survey__list {
    display: none;
  }

  .browse-content-filter {
    display: none;
  }

  .left {
    font-size: 1.875rem;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
  }
  .no-results-text {
    text-align: center;
    color: #666;
    font-size: 0.9375rem;
    padding: 0.9375rem 0.625rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  .sort-arrow-icon {
    position: relative;
    top: 3px;
  }
  .search-filter {
    border-radius: 5px;
    height: 33px;
    line-height: 100%;
    .icon {
      color: #ccc;
      font-size: 1.5rem;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    input {
      //max-width: 85%;
      color: #666;
      background: transparent;
      font-size: 1.0625rem;
      font-weight: 400;
      height: 29px;
      line-height: 1.0625rem;
      display: inline-block;
      vertical-align: middle;
      //max-width: 66%;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      width: 89%;
    }
    .icon-close {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }
    .f-dropdown {
      max-width: 97%;
      left: 6px !important;
    }
    &.open {
      border: 1px solid #ddd;
      input, .icon-close {
        visibility: visible;
        opacity: 1;
        position: relative;
      }
    }
  }
  .search-filter-container {
    width: 100%;
  }
  .mc-filter-dropdown {
    width: 19%;
  }

  .mc-edit-trigger {
    width: 2%;
    display: inline-block;
    .vertEllipsis {
      color: #ccc;
      height: 35px;
      line-height: 36px;
      text-align: right;
    }
    #drop-mc-edit-menu {
      z-index: 9001;
      height: 60px;
      top: 0;
      margin-top: -27px;
      margin-left: 20px;
      width: 160px;
      border: none;
      color: #aaa;
      text-align: left;
      padding: 0;
      background-color: #f7f7f7;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
    .icon-cloud-upload{
      padding-top: 2px;
    }
  }
  .icon {
    &[data-icon="o"] {
      font-size: 1.875rem;
      display: inline-block;
      vertical-align: middle;
    }
    display: inline-block;
    vertical-align: middle;
  }
  input {
    border: 0;
    outline: 0;
    height: 31px;
    line-height: 100%;
    margin: 0;
    display: inline-block;
  }
  .target-table-row {
    .actions-col.small-1 {
      .f-dropdown {
        min-width: 215px;
        background-color: #f7f7f7;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 0;
        a {
          font-size: 0.9375rem;;
        }
      }
    }
  }
  .medium-5.columns, .medium-8.columns {
    float: right;
  }
  .filter__select-mcc select {
    background-color: white;
    background-image: url("./images/select-down-arrow.svg");
    background-size: 15px 8px;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 2.0625rem;
    line-height: 2.0625rem;
    padding: 0 10px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 240px 13px;
    cursor: pointer;
  }
  .tab {
    //width: 896px;
    white-space: nowrap;
    position: relative;
    height: inherit;
    overflow: hidden;
    overflow-y: hidden;
    > .tablinks {
      z-index: 1000;
    }
  }
  .tablinks + .tablinks {
    transform: skew(-41deg);
    border-top-left-radius: 0;
    border-top-right-radius: 33px;
    .tablinksInner {
      transform: skew(60deg);
    }
  }
  .tab-container {
    height: 38px;
    border-radius: 5px;
    //width: 960px;
    position: relative;
    overflow-y: hidden;
    .fa {
      margin-top: 5px;
      color: #6fc96f;
      cursor: pointer;
    }
  }
  .tablinks {
    background-color: #FFFFFF;
    margin-left: 4px;
    margin-top: 2px;
    border: 1px solid #DEDEDE;
    color: #AFAFAF;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 212px;
    outline: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 10px;
    &.active-tab {
      background-color: #6fc96f;
      color: #FFFFFF;
      border: 1px solid #DEDEDE;
      z-index: 1001 !important;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      border-bottom: none;
      top: -2px;
    }
  }
  .tablinksInner {
    background-color: #FFFFFF;
    color: #AFAFAF;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 191px;
    transform: skew(40deg);
    border: 1px solid #DEDEDE;
    border-left: none;
    border-bottom: none;
    border-top: none;
    border-top-left-radius: 10px;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    border-top-left-radius: 100px;
    margin-left: 30px;
    &.active-tab {
      background-color: #6fc96f;
      color: #FFFFFF;
      border: 1px solid #DEDEDE;
      border-left: none;
      border-bottom: none;
      border-top: none;
      z-index: 25;
      margin-left: 30px;
      border-top-left-radius: 10px;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .tab-text {
    font-size: 13px;
    line-height: 37px;
    transform: skew(-40deg);
    border-top-left-radius: 10px;
    padding-right: 20px;
    white-space: nowrap;
  }
  .active-tab .tab-text {
    overflow: auto;
    white-space: nowrap;
    padding-right: 17px;
  }
  .tablinks .tablinksInner:not(.active-tab) {
    margin-top: 1px;
    border-bottom: 1px solid #e5e5e5;
  }
  .prefix {
    color: #AFAFAF;
    font-family: "Open Sans";
    font-size: 18px;
    line-height: 24px;
    border: none;
  }
  .search-filter__wrap {
    width: 62%;
  }
  .filter-text {
    width: 12%;
  }
  .table-header {
    height: 48px;
    width: 100%;
    background-color: #F3F3F3;
    display: table;
    color: #6fc96f;
    .type-col {
      font-weight: bold;
      display: table-cell;
      height: 100%;
      padding: 12px;
      padding-left: 20px;
      color: #6CCA6B;
      font-size: 14px;
      line-height: 16px;
      padding-top: 15px;
      width: 16%;
    }
    .contact-name-col {
      font-weight: bold;
      display: table-cell;
      height: 100%;
      vertical-align: top;
      padding: 12px;
      color: #6CCA6B;
      font-size: 14px;
      line-height: 16px;
      padding-top: 15px;
      width: 12%;
    }
    .description-col {
      font-weight: bold;
      display: table-cell;
      height: 100%;
      color: #6CCA6B;
      font-size: 14px;
      line-height: 16px;
      width: 16%;
    }
    .status-col {
      font-weight: bold;
      line-height: 20px;
      height: 100%;
      display: table-cell;
      padding: -31px;
      color: #6CCA6B;
      font-size: 14px;
      line-height: 16px;
    }
    .actions-col {
      height: 100%;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      height: 100%;
      display: table-cell;
      text-align: center;
      padding: 12px;
      padding-right: 20px;
      color: #6CCA6B;
      font-size: 14px;
      line-height: 16px;
      padding-top: 15px;
      //width: 19.6%;
    }
  }
  .target-table {
    z-index: 2000;
    position: relative;
    top: -1px;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
  }
  .target-table .target-table-row:nth-child(2n+1) {
    background-color: #F8F8F8;
  }
  .target-table-row {
    height: 68px;
    width: 100%;
    background-color: #FFFFFF;
    display: table;
    border-bottom: 2px solid #f3f3f3;
    .type-col {
      color: #3D3D3D;
      font-size: 14px;
      line-height: 16px;
      display: table-cell;
      height: 100%;
      padding-left: 20px;
      position: relative;
      top: 10px;
      width: 28%;
    }
    .contact-name-col {
      color: #3D3D3D;
      font-size: 14px;
      line-height: 16px;
      display: table-cell;
      height: 100%;
      position: relative;
      top: 10px;
      width: 18%;
    }
    .description-col {
      color: #3D3D3D;
      display: table-cell;
      height: 100%;
      font-size: 14px;
      line-height: 16px;
      width: 26%;
      position: relative;
      top: 10px;
    }
    .status-col {
      display: inline-block;
      color: #3D3D3D;
      font-size: 14px;
      line-height: 16px;
      height: 100%;
      padding: 12px;
      margin-bottom: 11px;
      position: relative;
      top: 10px;
      width: 25%;
    }
    .actions-col.small-1 {
      display: inline-block;
      text-align: right;
      position: relative;
      top: 20px;
      float: right;
      right: 58px;
    }
    .actions-col.small-1 a {
      color: #999999;
      font-size: 22px;
      font-weight: bold;
      line-height: 16px;
      height: 100%;
      text-align: center;
    }
    .description-col + .actions-col.small-1 a {
      color: #999999;
    }
    .vertEllipsis {
      cursor: pointer;
      font-size: 14px;
    }
  }
  .manage-content__options_dashboard {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .manage-content__options {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .manage-content_certified-contents__options {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .manage-content {
    &.search-filter .icon:before {
      line-height: inherit;
    }
    &.icon:before {
      line-height: 24px;
    }
  }
  .table-data-content-with-icon.icon.mod-table-icon {
    vertical-align: middle;
    margin-top: 0 !important;
  }
  .mcc-left-arrow {
    position: relative;
    float: left;
    font-size: 30px;
    cursor: pointer;
    width: 32px;
    text-align: center;
    border: 1px solid #ddd;
    background-color: white;
    z-index: 20;
    border-top-left-radius: 5px;
  }
  .mcc-right-arrow {
    float: right;
    position: relative;
    top: -38px;
    font-size: 30px;
    cursor: pointer;
    width: 32px;
    text-align: center;
    border: 1px solid #ddd;
    background-color: white;
    z-index: 20;
    border-top-right-radius: 5px;
  }
  .tablinksInner {
    .tab-text {
      &.tab-text-too-long:hover {
        overflow: visible;
      }
      .tab-text-too-long {
        white-space: nowrap;
        overflow: hidden;
        width: 155px;
        text-overflow: ellipsis;
        position: relative;
      }
    }
    &.active-tab .tab-text .tab-text-too-long:hover {
      overflow: visible;
      color: white;
      .extended-ellipsis-text {
        overflow: visible;
        background-color: #4c4c4c;
        color: white;
      }
    }
  }
  .actionsList {
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #f7f7f7;
    border: solid 0 #cccccc;
    font-size: 0.9375rem;
    z-index: 99;
    margin-top: 2px;
    max-width: 200px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .hideText {
    visibility: hidden;
    color: white;
  }
  .tab-container .disabledArrow {
    color: #F3F3F3;
    cursor: not-allowed;
  }
  .under-five-tabs {
    overflow: initial;
    overflow-y: initial;
  }
  .icon-cert-container {
    display: inline-block;
    width: 20px;
  }
  .hideShortenedEllipsis, .hideExtendedEllipsis {
    display: none;
  }
  .icon-close {
    top: 4px;
  }
  .browse-content-filter {
    z-index: 99999;
    position: absolute;
  }
  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  .action-menu-align-left {
    text-align: left;
  }

}

@media (max-width: 1530px) {
  .manage-content .filter__select-mcc select {
    background-position: 220px 13px;
  }
}

@media (max-width: 1430px) {
  .manage-content .filter__select-mcc select {
    background-position: 200px 13px;
  }
}

@media (max-width: 1238px) {
  .manage-content .search-filter input {
    max-width: 70%;
  }
}

@media (max-width: 1519px) {
  .manage-content .search-filter__wrap {
    width: 67%;
  }
  .manage-content .filter-text {
    width: 12%;
  }

}

.admin-dates .date-p {
  font-size: 15px;
  margin-bottom: 0px;
}

.admin-dates .date-p .date-label {
  font-weight: bold;
  /*
    Overrides for table.echo-surveys__list span, table.echo-reports__list span from echo.css
  */
  color: inherit;
  display: inline-block;
  font-style: normal;
  font-size: 15px;
  margin-top: 0px;
}

.admin-dates .date-p .date-value {
  display: block;
  min-width: 100px;
}

/* Uncomment to widen survey list to accommodate for wider data
//.echo-surveys .row {
//  max-width: 70.0rem;
//}
*/

.search-row {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none !important;
  .search-filter-container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    .row {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: none;
      .search-filter__wrap {
        padding: 0;
      }
    }
  }
}