.change-roster-modal-override-error-height {
    height: 665px !important;
}

.change-roster-modal-override-height {
    height: 630px !important;
}

.change-roster-modal-override {
    position: fixed;
    z-index: 3000;
    border-radius: 5px;
    bottom: 0;
    top: -10%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    width: 580px !important;
    overflow: hidden;
    -webkit-box-shadow: -3px 5px 80px 0px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: -3px 5px 80px 0px rgba(0, 0, 0, 0.52);
    box-shadow: -3px 5px 80px 0px rgba(0, 0, 0, 0.52);
    font-family: 'Open Sans', sans-serif; }
.change-roster-modal-override.has-file-selected {
    height: 650px; }
.change-roster-modal-override.confirm-modal {
    height: 380px !important;
    width: 480px !important;
}
.change-roster-modal-override.has-error {
    height: 650px; }
.change-roster-modal-override .upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    height: auto;
    width: auto; }
.change-roster-modal-override .upload-spinner {
    font-size: 100px;
    height: 100px;
    width: 100px;
    line-height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px); }
.change-roster-modal-override .change-roster-modal-override-title {
    height: 60px;
    background-color: #148900;
    color: white;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center; }
.change-roster-modal-override .change-roster-modal-override-title.has-error {
    background-color: #d54230; }
.change-roster-modal-override .change-roster-modal-override-title i {
    font-size: 40px;
    margin-right: 10px; }
.change-roster-modal-override #file {
    display: none;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0; }
.change-roster-modal-override .modal-content {
    padding: 30px 40px; }
.change-roster-modal-override .modal-content .message {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px; }
.change-roster-modal-override .attach-well {
    display: block;
    border: 1px solid #aaa;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px; }
.change-roster-modal-override .attach-well.has-error {
    border: 1px solid #D54230; }
.change-roster-modal-override .file-size-limit {
    font-size: initial;
    color: grey; }
.change-roster-modal-override .attachment-header {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    color: black;
    width: 91%;
    text-align: inherit;
    padding-left: inherit;
    margin: inherit;
    font-weight: 500; }
.change-roster-modal-override .attach-header {
    cursor: pointer;
    display: block;
    padding: 5px;
    margin: 0;
    background-color: #F8F8F8;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    font-family: 'Open Sans', sans-serif; }
.change-roster-modal-override .attach-header > p {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 4px;
    color: #565656; }
.change-roster-modal-override .attach-header > p.small {
    font-size: 14px;
    line-height: 14px;
    color: #AAAAAA; }
.change-roster-modal-override .attach-header button.disabled:hover, .change-roster-modal-override .attach-header button.disabled:focus,
.change-roster-modal-override .attach-header button[disabled]:hover, .change-roster-modal-override .attach-header button[disabled]:focus,
.change-roster-modal-override .attach-header .button.disabled:hover, .change-roster-modal-override .attach-header .button.disabled:focus,
.change-roster-modal-override .attach-header .button[disabled]:hover, .change-roster-modal-override .attach-header .button[disabled]:focus {
    background-color: #f8f8f8;
    color: black;
    cursor: not-allowed; }
.change-roster-modal-override .attach-info p {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    color: #AAAAAA;
    font-weight: bold;
    padding: 0 5px 5px 5px;
    background-color: #F8F8F8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0; }
.change-roster-modal-override .attach-button {
    float: right;
    height: 36px;
    width: 36px;
    padding: 0;
    background-color: #FFF;
    border: 1px solid #979797;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0; }
.change-roster-modal-override .attach-button span.icon-cloud-upload {
    color: #6FC96F;
    font-size: 22px;
    line-height: 22px;
    justify-content: center;
    vertical-align: middle; }
.change-roster-modal-override .attach-button:hover {
    background-color: #FFF;
    border: 1px solid #979797; }
.change-roster-modal-override .attach-button:hover span.icon-cloud-upload {
    color: #6FC96F; }
.change-roster-modal-override .close-message {
    padding: 20px 40px 10px 40px;
    width: 100%;
    text-align: center;
    color: #565656;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    text-align: justify; }
.change-roster-modal-override .upload-success-message {
    padding: 5px 40px;
    font-weight: 400;
    color: #3f3f3f;
    margin-top: 20px; }
.change-roster-modal-override .upload-error-message {
    padding: 5px 45px;
    font-weight: 600;
    color: #3f3f3f; }
.change-roster-modal-override textarea.attach-comments {
    min-height: 100px;
    resize: none;
    overflow-y: scroll;
    width: 100%;
    background: none;
    line-height: 20px; }
.change-roster-modal-override .button-row {
    margin-top: 10px; }
.change-roster-modal-override .button-row > button {
    width: 117px;
    height: 45px;
    float: right;
    color: #fff;
    margin-left: 5px;
    font-family: 'Open Sans', sans-serif;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
.change-roster-modal-override .button-row > button.cancel {
    background-color: #BBB; }
.change-roster-modal-override .button-row > button.submit {
    background-color: #148900; }
.change-roster-modal-override .button-row > button.submit:disabled {
    background-color: #565656;
    opacity: 1; }
.change-roster-modal-override .button-row > button.close {
    margin-right: 40px; }
.change-roster-modal-override .error-container {
    color: #D63E37;
    padding-top: 5px; }
.change-roster-modal-override .error-container > p {
    display: inline-block; }
.change-roster-modal-override .error-container > i {
    display: inline-block; }

.title-icon-size {
    font-size: 24px !important;
}

.spinner-dashboard {
    float: top;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2999;
    color: #1a1a1a;
    font-size: 24px;
}

.dropzone-content {
    position: relative;
    top: 12px;
}

.eleoticon-file-type:before {
    font-family: "eleoticons" !important;
    content: "J";
}

.dropzone {
    background-color: #F8F8F8;
    height: 70px;
    border: 1px solid #d7d7d7;
    text-align: center;
    margin: auto;
    font-family: 'Open Sans', 'Helvetica Neue';
}

.droppedfilezone {
    background-color: #F8F8F8;
    height: 50px;
    border: 1px solid #d7d7d7;
    margin: auto;
    font-family: 'Open Sans', 'Helvetica Neue';
}

.opensans-bold20 {
    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 20px;
    font-weight: bold;
    color: #1a1a1a;
}

.dropped-file-div {
    padding-left: 10px;
    display: table;
    height: 50px;
    width: 100%;
}

.dropped-file-span {
    display: table-cell;
    vertical-align: middle;

    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;

    word-break: break-all;
    text-align-last: left;
}

.upload-icon-size {
    font-size: 30px;
    position: relative;
    top: 7px;
    color: #1a1a1a;
}

.selected-file-image {
    float: left;
    position: relative;
    left: 15px;
    top: 15px;
}

.file-name {
    font-family: 'Open Sans', 'Helvetica Neue';
}

.pointer {
    cursor: pointer;
}

.cancel-modal {
    background-color: #BBB;
    border-radius: 4px;
}

.submit-file {
    background-color: #148900;
    border-radius: 4px;
}

.submit-file:disabled {
    background-color: #565656;
    opacity: 1;
    border-radius: 4px;
}

.submit-file:disabled:hover {
    background-color: #565656;
    opacity: 1;
    border-radius: 4px;
}

.attach-button-override {
    border-radius: 4px;
    float: right;
    height: 40px !important;
    background-color: #148900 !important;
    position: relative;
    top: 1px;
    left: -27px;
    vertical-align: middle;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-weight: bold;
    font-size: 16px;
}

.close-file-button {
    height: 40px !important;
    background-color: transparent !important;
    position: relative;
    top: 1px;
    vertical-align: middle;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-weight: bold;
    font-size: 24px;
    color: #565656 !important;
}

.close-file-button:hover {
    background-color: transparent !important;
    color: #565656 !important;
}

.close-file-button-span {
    float: right;
    left: -10px;
}

.file-size-text {
    font-family: 'Open Sans', 'Helvetica Neue';
    font-weight: 400;
    font-size: 18px;
    color: #1a1a1a;
    position: relative;
    top: -4px;
}

.contact-client-care {
    text-decoration: underline;
    color: #148900;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
}

.contact-client-pos {
    float: left;
    position: relative;
    top: 18px;
    left: -10px;
    width: 200px;
}

.adjust-cancel-submit-pos {
    position: relative;
    left: 30px;
}

img.download-icon {
    padding: 4px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #F9F9F9;
    position: relative;
    left: 10px;
}

.light-gray-circle {
    background-color: #D9D9D9;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    position: relative;
    top: 0px;
}

.headerfont {
    font-family: 'Open Sans', 'Helvetica Neue';
}

.circle-number-pos {
    position: relative;
    top: 3px;
    color: #1a1a1a;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.instruction-header {
    vertical-align: middle;
    font-weight: bold;
    font-size: 16px;
    font-weight: bold;
    color: #1a1a1a;
    position: relative;
    left: 7px;
}

.move-up-10 {
    position: relative;
    top: -10px;
}

.move-up-15 {
    position: relative;
    top: -15px;
}

.move-up-20 {
    position: relative;
    top: -20px;
}

.move-up-25 {
    position: relative;
    top: -25px;
}

.move-up-30 {
    position: relative;
    top: -30px;
}

.move-up-35 {
    position: relative;
    top: -35px;
}

.move-up-40 {
    position: relative;
    top: -40px;
}

.move-up-60 {
    position: relative;
    top: -60px;
}

.instruction-header-underline {
    vertical-align: middle;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    color: #1a1a1a;
    position: relative;
    left: 7px;
}

.shifted-instruction {
    margin-left: 38px;
    color: #1a1a1a;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 16px;
    line-height: 21.79px;
}

.italics-instruction {
    color: #1a1a1a;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-style: italic;
    font-size: 16px;
    line-height: 21.79px;
}

.error-instruction {
    color: #D54230;
    font-family: 'Open Sans', 'Helvetica Neue';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    left: -10px;
}

.error-exc-icon {
    position: relative;
    top: 10px;
    width: 24px;
    height: 24px;
}

.border-box {
    border: 2px solid white;
    height: 36px;
    position: relative !important;
    vertical-align: middle;
    top: -2px !important;
}

.to-myjourney-adj {
    position: relative;
    top: -2px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
}

.survey-taking-footer {
    margin: 2em 0 2em 0 !important;
    font-size: 80%;
    line-height: 170%;
}

.disabled-button-mouse-over:hover{
    cursor: not-allowed;
}

/*General Text*/
.text-align-center { text-align: center; }

/*eProve Buttons*/
.button--eprove {
    background-color: #148900;
}

.button--eprove:hover,
.button--eprove:focus {
    background-color: #599F58;
}

.bullet-list li {
    list-style: disc !important;
}

.color-red {
    color: red;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 400;
    border: 0;
    font-size: 14px;
    line-height: 22px;
}

.color-black {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #1a1a1a;
    border: 0;
    font-size: 14px;
    line-height: 22px;
}

.certified-content {
    /*background: #f7f7f7;*/
}

.survey-header__lock_expanded {
    width: 100%;
    background: #f3f3f3;
    font-size: 1rem;
    font-weight: 400;
    padding: 0 0.3125rem;
    line-height: 2.1875rem;
    box-shadow: inset 0px 1px 0px 0px #ECECEC;
    border-radius: 0.3125rem;
    margin: 10px 0 !important; }
/* line 313, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock_expanded div {
    vertical-align: middle; }
/* line 315, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock_expanded div .icon {
    vertical-align: middle; }
/* line 319, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock_expanded input {
    display: table-cell;
    font-weight: 400;
    font-size: 1rem;
    background: transparent;
    outline: 0; }
/* line 326, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock_expanded a {
    width: 60px;
    vertical-align: middle;
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: none;
    color: #999; }
/* line 333, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock_expanded a .icon {
    color: #999;
    vertical-align: -5px;
    text-decoration: none;
    font-size: 1.125rem; }
/* line 339, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock_expanded a span {
    text-decoration: underline;
    font-weight: 400;
    color: #999; }

#survey-edit__lang_select {
    position: fixed;
    top: 65px;
    right: 20px;
    z-index: 9999;
    width: 160px;
    background: #fff;
    text-align: right; }
/* line 1106, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.open #survey-edit__lang_select {
    display: block; }
/* line 1109, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-edit__lang_select.rtl {
    right: auto;
    left: 292px;
    text-align: left; }
/* line 1114, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-edit__lang_select select {
    background-color: #fff;
    border-right: 10px solid #fff;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: #777777;
    height: 1.125rem;
    line-height: 1.125rem;
    box-shadow: none;
    margin-bottom: 0; }
/* line 1127, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-edit__lang_select > a {
    color: #777;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem; }
/* line 1132, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-edit__lang_select > a:after {
    content: "\0067";
    font-family: eleoticons;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
    font-size: 0.875rem;
    margin-left: 0.625rem; }
/* line 1143, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-edit__lang_select.rtl > a:after {
    content: ""; }
/* line 1146, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-edit__lang_select.rtl > a:before {
    content: "\0067";
    font-family: eleoticons;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
    font-size: 0.875rem;
    margin-right: 0.625rem; }

.survey-edit__sidebar ul.edit_section {
    list-style: none;
    list-style-position: inside;
}

.survey-edit__sidebar ul.edit_section li {
    padding-left: 0;
    margin: 5px 0;
    margin-left: 1.5rem;
    list-style-position: outside;
}

.survey-edit__sidebar ul li ul.edit_section_item {
    list-style: none;
}

.survey-edit .institution-select__input {
    margin-top: 0;
}

.echo-surveys .search-filter .f-dropdown {
    max-width:97%;
    left:6px !important;
}

.echo-surveys .search-filter input {
    max-width:85%;
}

.gray-bg-color {
    background-color: #FFFFFF !important;
}

.rmTopLeftAlign {
    margin-top: 3px !important;
    position: relative;
    left: 10px;
}

.solid-white-1px-border {
    border: #ffffff;
    border-style:
    solid;border-width: 1px;
}

.sortable-drag-and-drop {

}

.latest-activity__metrics .tabs {
    border-bottom: 1px solid #ddd;
    font-weight: 600;
}

.latest-activity__metrics .tabs dd.active a, .tabs .tab-title.active a {
    background: white;
    color: #6fc96f;
    border-bottom: 2px solid #6fc96f;
}

.latest-activity .empty-activity {
    color: #666666
}

.echo-surveys__list .open .mg-line1-color {
    stroke: #6fc96f;
}

.dashboardtooltip{
    text-align: left;
}

.dashboardtooltip .tooltip-content{
    display:none;
}

.echo-surveys__list .echo-surveys__empty_list{
    text-align: center;
}
.echo-surveys__list .echo-surveys__empty_list_link{
    color: #333;
    text-decoration: underline;
}
.echo-surveys__list .echo-surveys__custom_content_span{
    margin-left:0
}

.echo-surveys__list .tool_tip{
    color: white;
    font-weight: normal;
    font-style: normal;
}
#echo-surveys__tooltip_content_span{
    color: white;
    font-weight: normal;
    font-style: normal;
}

#echo-surveys__tooltip_content_span ul{

}

#echo-activity__tooltip_content_span{
    color: white;
    font-weight: normal;
    font-style: normal;
}

.echo-surveys__options_dashboard span.icon {
    color: #000;
    font-size: 1.4375rem;
    margin-right: 10px; }

.dashboard-demographic-graph .demo-bar-graph {
    position: relative;
    margin-left: 300px;
    margin-right: 40px;
    padding: 0;
    margin-bottom: 10px; }

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar {
    position: relative;
    background-color: #6fc96f;
    height: 26px;
    padding: 0;
    line-height: 26px;
    width: 0;
 }

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar:after {
    content: attr(data-count) " " attr(data-width);
    color: #777;
    font-size: 0.9375rem;
    font-weight: bold;
    height: 26px;
    line-height: 26px;
    position: absolute;
    top: 0; }

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar[data-offset='1']:after {
    right: -40px;
}

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar[data-offset='2']:after {
    right: -55px;
}

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar[data-offset='3']:after {
    right: -65px;
}

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar[data-offset='4']:after {
    right: -70px;
}

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar[data-offset='5']:after {
    right: -80px;
}

.dashboard-demographic-graph div {
    font-size: 0.8375rem;
    text-align: left;
   }
.dashboard-demographic-graph .total-label {
    font-size: 0.8375rem;
    text-align: left;
}

.dashboard-demographic-graph .demo-bar-graph .demo-bar-graph__bar.short:after {
    right: -70px; }

.dashboard-demographic-graph .demo-bar-graph span {
    padding-left: 0.625rem;
    color: #fff;
    line-height: 26px;
    font-size: 0.9375rem;
    font-weight: 400; }

.dashboard-demographic-graph .demo-bar-graph .label {
    display: block;
    position: absolute;
    width: 250px;
    left: -280px;
    color: #777;
    background-color: transparent;
    text-align: left;
    padding: 0;
    top: 0;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem; }

.dashboard-demographic-graph a{
    color: #777;
    text-decoration: underline;
}

.surveys_link, .surveys_link:hover{
    color: #333;
    text-decoration: underline;
}

#browse_reports_tooltip_link{
    color: #aaa;
    text-decoration: underline;
}

.strong-normal{
    font-weight: normal;
}

.multiple-select__group .multi_link{
    opacity:1;
    margin-left: 0;
    font-size: 0.85rem;
}

#panel3, #panel4{
    position: relative;
    background: #fff;
    right: 0;
    top: -10px;
    height: 100%;
    margin-left: calc(-100% - 15px);
    padding: 1.25rem 0.3125rem;
}


#panel3 .rtl, #panel4 .rtl {
    margin-left: 0;
    margin-right: calc(-100% + 20px);
}

#panel3 h5, #panel4 h5 {
    color: #000;
    font-weight: 300;
}

#panel3 table, #panel4 table {
    width: 100%;
}

#panel3 table td, #panel4 table td {
    color: #666;
    font-size: 0.75rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0;
}

#panel3 table td a, #panel4 table td a{
    color: #666;
    font-size: 0.75rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0;
}

#panel3 .rtl table td, #panel4 .rtl table td {
    text-align: right; }

#panel3 table tr td:nth-of-type(2), #panel4 table tr td:nth-of-type(2) {
    font-weight: 600;
}

.report-skip-button.clear {
    background: transparent;
    border: 1px solid #fff;
    box-shadow: none;
    color: #979797;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 0.375rem 0;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
}

.report-skip-button[disabled]{
    background-color: transparent;
    border: 1px solid #fff;
    color: #979797;
    box-shadow: none;
    cursor: default;
    opacity: 0.4;
}

:focus{
    outline: none;
}

.reports .reports-tab-pagination {
    color: #666;
    font-weight: normal;
    text-decoration: underline;
}

#report-header{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    line-height: 1;
}

#row-divider{
    height: 1px;
    background: #ddd;
    margin-top: 50px;
    margin-bottom: 50px;
}

.graph-container .total-label {
    font-size: 0.8375rem;
    text-align: left;
}

#reports_no_response_section{
    color: #333;
    padding: 0.625rem 0;
    font-size: 0.875rem;
    font-weight: 400;
}

#reports_no_response_highest_tab{
    color: #333;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#reports_no_response_lowest_tab{
    color: #333;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.item-graph{
    margin-bottom: 3.125rem;
}

.item-graph:last-child{
   margin-bottom: 0;
}

.item-freeform{
    color: #333;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: bold;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* eProve Cart Overrides*/
.eprove-cart input[type="number"] {
    display: inline-block;
    background: #fcfcfc;
    border: 1px solid #bbb;
    border-radius: 2px;
    width: 50px;
    text-align: center;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    vertical-align: -12px;
    height: 31px;
    padding: 0;
}

.eprove-cart input[type="number"].ng-invalid,
.eprove-cart input[type="text"].ng-invalid {
    border: 2px solid pink;
}

.eprove-cart span.error{
    margin-top: 1px;
    margin-bottom: 1px;
    background: none;
    padding: 0rem;
    color: #F04124;
}

.echo-surveys .signup-form input.signup__input--text.signup__form--text,
.echo-surveys .signup-form .filter__select.signup__select {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
}

.echo-surveys .signup-form input.signup__input--text.signup__form--text.no-top-border{
    margin-top: 0rem;
}

.echo-surveys .signup-form .button.button--eprove {
    margin-top: 1.25rem;
}

/* Signup select institution overrides */
.filter__select.signup__select.gray--no--value select.gray--text  {
    color: gray;
}
.filter__select.signup__select.gray--no--value select option {
    color: black;
}

/*Contact Us */
.reveal-modal.surveys-modal {
    display: block;
    opacity: 1;
    visibility: visible;
    top: 200px;
}

.eprove-modal .filter__select--dropdown {

    position: relative;
}

.eprove-modal .filter__select--dropdown span {
    display: inline-block;
    background-color: #FCFCFC;
    background-image: url("./images/select-down-arrow.svg");
    background-size: 15px 8px;
    background-position: right;
    background-repeat: no-repeat;
    border: 0;
    border-right: 10px solid #FCFCFC;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 2.5625rem;
    line-height: 2.5625rem;
    padding-left: 0.625rem;
    padding-right: 1.25rem;
    font-weight: 400;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}

.eprove-modal .filter__select--dropdown span:hover {
    background-color: #f6f6f6;
    border-right: 10px solid #f6f6f6;
}

.eprove-modal .filter__select--dropdown .dropdown {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #fff;
    border: solid 1px #cccccc;
    font-size: 0.875rem;
    z-index: 99;
    max-width: none;
    width: 100%;
    color: #666;
    font-size: 1rem;
    font-weight: 400;
    box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.5);
    background: #f6f6f6;
    margin-top: 0.625rem;
    max-height: 150px;
    overflow: auto;
}

.eprove-modal .filter__select--dropdown .dropdown > *:first-child {
    margin-top: 0;
}

.eprove-modal .filter__select--dropdown .dropdown > *:last-child {
    margin-bottom: 0;
}

.eprove-modal .filter__select--dropdown .dropdown:before,
.eprove-modal .filter__select--dropdown .dropdown:after {
    border: 0;
}

.eprove-modal .filter__select--dropdown .dropdown label {
    display: block;
    color: #666;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.3125rem 0.625rem;
}

.eprove-modal .filter__select--dropdown .dropdown input[type="checkbox"] {
    margin-right: 0.3125rem;
    margin-bottom: 0;
}


    /* Invite Admin - Add admin overrides*/
.reveal-modal.surveys-modal .assign-school-admin__entry {
    border-top: 0;
    border-bottom: 0;
    padding: 2.5rem 0px 0rem;
}

/** assign system admins **/

.assign-school-admin__entry input[type="email"]{
    background: #fafafa;
    border:1px solid #ccc;
    border-radius:4px;
    font-size:1.125rem;
    font-weight: 400;
    padding-top: 0;
    padding-bottom: 0;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
}

.assign-school-admin__footer a {
    cursor:default;
}

.admin__delete {
    cursor:pointer;
}

.offcanvas__form input[type="email"] {
    font-size: 1.0625rem;
    font-family: 'Open Sans',sans-serif;
    font-weight: 400;
}

.add_institution {
    max-width: 32.5rem;
}

.add_institution-container {
    padding-top: 3.625rem;
    font-weight: 400;
    font-size: 1rem;
    color:#777;

}

.add_institution-container input {
    font-weight: 400;
}
.signup__subtitle_add_institution {
    margin-top: 25px !important;
}

.add_parent_institution .row {
    margin-bottom: 1rem !important;
}

.no_leftpadding {
    padding-left: 0rem;
}

.no_rightpadding {
    padding-right: 0rem;
}

.add_institution_parent_selection{
    margin : 0rem !important;
    border-bottom: none !important;
    padding-bottom: 0rem !important;
}


.survey-add-q__add-more {
    display: none;
    position: relative;
    vertical-align: middle;
    margin:0 15px 0 0;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: #ccc;
}

.echo-admin__options nested li {
    list-style: none;
}

.survey-header__select-lang input {
    margin-top:2px;
}

.survey-header__select-lang div {
    padding:2px;
    margin-left:-0.75em;
}

.echo-admin__options li {
    list-style: none;
    margin-right:0px !important;
}

.echo-surveys__list tr td a {
/*    padding-top:1.5em;
    padding-bottom:1.2em;*/
}

.institution-selector-dropdown {
    color:#666;
}

.institution-selector-dropdown:hover {
    text-decoration: none;
    color:lightgrey;
    cursor: pointer;
}

.institution-selector-dropdown.no-select:hover {
    text-decoration: none;
    color:#666;
    cursor: default;
}

.entireGroupLinks {
    color:#666;
    font-size: 0.5em;
}

.institution-select__group .toggle:hover, .list__list-toggle:hover {
    cursor:pointer;
}

.institution-select__wrap input[type="checkbox"]:checked + label:after {
    color: #6fc96f !important;
}

.institution-select__wrap .icon:hover {
    color:#666;
}

.institution-select__input .autofill-container {
    display: block;
    margin-top: 30px;
    left: 35px;
}

/* LeftSide Menu*/

.institution-address {
    color:#666;
    font-size: 80%;
}

.entireGroupLinks {
    color:#aaa;
    text-decoration: underline;
}

.echo-admin__list ul li {
    display: inline;
}

.echo-admin__list-license {
    margin: 0.25rem;
}

.echo-admin__list-toggle {
    font-size: 1.25rem;
    color:#aaa;
    margin:0.25rem;
    font-weight: bold;
}

.echo-admin__list-toggle:hover {
    color:#666;
    cursor:pointer;
}

.echo-admin__options {
    /*box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.5);*/
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
}

.echo-admin__options .table-row-options-item {
    padding: 0 0.9375rem 0.625rem 0.9375rem;
}

/*.echo-admin__options li:hover {*/
    /*background: #999;*/
/*}*/

.echo-admin__options .nested {
    box-shadow: none;
}

.admin__tag-item {
    box-shadow: -1 1px 1px rgba(0, 0, 0, 0.16) !important;
}

.schools-mgmt strong {
    color: #666 !important;
}

.subitem strong {
    color: #999;
}

.subitem strong, .subitem p, .subitem .echo-admin__tag-container {
    padding: 0 0 0 4rem;
}

.has-dropdown .table-row-options-item::after {
    float:right;
    position: relative;
    right:0px;
    padding:0.75rem 0 0 0;
}

.onboarding-es-search {
    top:77px !important;
    left:5px !important;
}

.onboarding-search-close {
    padding-right: 0.3rem;
}

.report-modal__list .report-export__label {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 0;
    vertical-align: baseline;
}
.report-modal__list .report-export__label_disabled {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 0;
    vertical-align: baseline;
    color: #999 !important;
}

#base-html-style {
    font-size: 0.9rem;
    font-weight: normal;
    font-style: inherit;
    color: #333;
    line-height: 20px;
    vertical-align: baseline;
    margin-left: none;
    cursor: default;
}

#base-html-style:hover {
    color: rgba(51, 51, 51, 1);
}

#base-html-style.text:hover {
    color: rgba(51, 51, 51, 1);
}

#base-html-style.link:hover {
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
}

div #base-html-style {
    padding: 0rem 0.9375rem;
    color: #333333;
}

#new-survey:nth-child(1){
    margin-top:5px;
}
#new-survey.new-survey__list {
    max-width: 450px;
}

.latest-activity__overview div .filter__select select{
    padding: 0 22px 0 10px !important;
}

.echo-surveys__options_dashboard li {
    line-height: 1.5 !important;
}

.span-header {
    color: #333 !important;
    display: block;
    font-style: normal !important;
    font-size: 15px;
}

.link:hover { cursor: pointer; }

.echo-surveys .custom-filter span {
    font-size: 16px;
    font-weight: 400;
}

.echo-surveys .custom-filter p {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 300;
}

.echo-surveys .custom-filter {
    height:auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.survey-inline-error-q {
    color: #f36a54;
}

#survey, #survey-page-container {
    padding-top: 0px !important;
}

#survey-page-container div div .survey-edit__content {
    padding: 0px !important;
}

#select-lang__wrapper {
    margin-top: -45px;
}

#preview-report-header-container{
    height: 185px;
}

/* media query for apple iphone4 */
@media (max-width: 320px) and (max-height: 480px){
    #select-lang__wrapper {
        margin-top: -5px;
    }
}

/* media query for survey title */
@media(max-width: 1300px){
    .title-area {
        width: 50% !important;
    }
    .name h1 span {
        font-size:1em !important;
        height: 50px;
        margin:5px;
    }
    .closed_text {
        font-size: 2em !important;
    }
    .closed_icon {
        width: 10vw !important;
    }
}
@media (max-width: 600px) {
    .si_title {
        line-height: 2em !important;
        /*font-size: 1.4em !important;*/
    }
    .opinion-scale-text {
        font-size: 0.65em;
    }
    .closed_text {
        font-size: 1.5em !important;
    }
    .closed_icon {
        width: 10vw !important;
    }
}
@media (max-width: 750px) {
    .title-area {
        width: 80% !important;
    }
    .top-bar.app-bar .title-area img {
        width: 50vw !important;
    }
    .top-bar-section {
        width: 100vw !important;
    }
    .top-bar.app-bar .top-bar-section.center ul.center li {
        text-align: right; font-size: 1.7em !important;
        width: 50%; float: right; padding-right: 1em;
    }
    .si_title {
        line-height: 2em !important;
        /*font-size: 1.9em !important;*/
    }
    .si_message {
        padding-top: 1em;
    }
    .institution-select__input input{
        font-size: 0.9em !important;
    }
}

@media(max-width: 1050px){
    .title-area {
        width: 80% !important;
    }
    .top-bar-section {
        width: 100vw !important;
    }
    .top-bar.app-bar .top-bar-section.center ul.center li {
        text-align: right;
        width: 50%; float: right;
        padding: 0.2em 1em 0.2em 1em;
    }
    .si_title {
        padding-right: 0.4em !important;
        line-height: 1.2em !important;
    }
    .si_message {
        padding-top: 1em;
    }
    .institution-select__input input{
        font-size: 0.9em !important;
    }
    .survey-edit__content { padding-left: 50px !important; }
}

@media (max-height: 800px) {
    .signup__autofill ul {
        max-height: 58vh; overflow: auto;
    }
}

@media (max-height: 630px) {
    .signup__autofill ul {
        max-height: 50vh; overflow: auto;
    }
}

@media (max-height: 530px) {
    .signup__autofill ul {
        max-height: 45vh; overflow: auto;
    }
}

.sidebar-title { text-indent: 0.75em; }
.sidebar-title:hover {
    cursor: pointer;
}

.small-caviat-text {
    padding-left: 0.5em;
    font-weight: 100;
    color: #999999;
}

.pagination-button {
    /*padding: 1em 1.5em;*/
    /*margin:0 1em; font-size: 1.75em !important;*/
    color: white; background: #148900 !important;
    /*box-shadow: none;*/
    width: 5em;
}

.pagination-button:hover {
    background-image: linear-gradient(to bottom,  #44b644, #3FA73E) !important;
    border-bottom-color: #297029;
}

.responseGoalIntVal {
    color: white !important;
}

.survey-pagination-footer {
    width: 100%;
    text-align: right;
}

.survey-section {
    margin-top: 2em !important; margin-bottom: 2em !important;
    padding: 0em !important;
}

.surveyHeader { margin-top: 2em; }

.name { padding: 0.1em; }

.reportheader__length {
    height: 160px;
}

.reportsidebar__height{
    /*padding-top: 0px;*/
}

.floatLeft {
    float: left !important;
}

.alignLeft {
    float: left !important;
    text-align: left !important;
}

.floatRight {
    float: right !important;
}

.alignRight {
    text-align: right;
}

.defaultText {
    color: rgba(0,0,0,0.5);
}

.echo-surveys__options_dashboard,
.echo-surveys__options,
.echo-reports__options,
.echo-surveys_certified-contents__options,
.f-dropdown {
    /*box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) !important;*/
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
}

.echo-surveys .icon {
    margin-top: 5px !important;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.generic-spinner {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-animation-name: spin;
    -webkit-animation-duration: 1500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1500ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1500ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
}

.white {
    color: white !important;
}

table.echo-surveys__list span, table.echo-reports__list span {
    margin-top:3px !important;
}

table.echo-reports__list .icon-chevron-down {
    padding-top: 3px;
}

.dashboard_table thead tr th span:hover { cursor: pointer; }

.echo-surveys__list thead tr th span:hover { cursor: pointer; }

.respondents_chevron { margin-right: -7px; }

.am-item span {
    color: #333 !important;
    font-style: normal !important;
    margin-top: 3px !important;
    font-weight: 400;
}
.row-item span {
    color: #333 ;
    font-style: normal !important;
    margin-top: 3px !important;
    font-weight: 400;
}
.isReleased{
    pointer-events: none;
    opacity: 0.2;
}
.am-item span.icon { color: #aaa !important; }

/*.paddingFree { padding-top: 10px !important; padding-bottom: 5px !important; }*/

.disabledText { color: lightgrey !important; }

.dashboard_sparkLines svg { padding: 2px; }

#eProve_logo:hover { cursor: default; }

.echo-surveys__list thead tr th div.chevronDiv { text-align: right; color: rgb(160, 160, 160); }

.dashboard-demographic-graph .demo-bar-graph .response-count {
    display: block;
    position: absolute;
    width: 40px;
    left: -45px;
    color: #777;
    background-color: transparent;
    text-align: right;
    padding: 0;
    top: 0;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem; }

.wrapword {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* css-3 */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
}

.custom-search-filter .icon:before{
    line-height:24px !important;
}

.logoLink:hover { cursor: default; }

.survey-sidebar__actions { padding: 0em !important; }

.institution-select__group {
    max-height: 60vh;
    outline: 1px solid #ccc;
    border-radius: 0px !important; border: 1px solid #f2f2f2 !important;
}

.institution-select__group .toggle { padding-left: 0.1em !important; }

.institution-select__group .tooltip {
    font-size: 15px;
    border-radius: 5px;
    opacity: 0.85;
}


.institution-select__wrap.single-select {
    line-height: 2.0;
}
.single-select .toggle {
    height: 2.0rem;
    line-height: 1.8rem;
    padding-top: 0.2rem;
}

.single-select a, .single-select .icon {
    color: #666;
}

.single-select a:hover,
.single-select .icon:hover {
    color: #999;
    cursor: pointer;
}
.single-select .icon:hover {
    border: 1px solid lightgrey;
    border-radius: 4px;
}

.single-select .institution {
    position: relative;
    color: #666;
    font-size: 0.875rem;
    font-family: "Open Sans",sans-serif;
    font-weight: 600;
    margin: 0px 0px 0px 10px;
}


.si_dd-row {
    width: 93% !important;
    margin-left: 0.2em !important;
}
.tab-bar { background: #6fc96f !important; }

.mg-line1-color, .mg-area1-color { stroke: #333 !important; }

.echo-admin__list--users thead tr th .icon {
    color: rgb(160, 160, 160);
}

#closed_screen table {
    padding: 10vw;
    border: none !important;
    height: 80vh;
}
#closed_screen table tr {
    border: none !important;
}
.closed_icon {
    width: 6vw;
}
.closed_text {
    vertical-align: middle;
    width: 53%;
    font-size: 2em; font-weight: 400; font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.closed_content { text-align: center !important; }

.survey-section__end { margin-bottom: 50vh !important; }

.signup__autofill ul {
    max-height: 70vh; overflow: auto;
}

#reports-percent-disclaimer{
    color: #777;
    background-color: transparent;
    text-align: left;
    padding-bottom: 0.9rem;
    top: 0;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem;
}

.reports__content .mg-active-datapoint {
    font-size: 0.925rem; }

input[readonly], textarea[readonly] {
    pointer-events: none;
}

.f-dropdown .has-dropdown > a:hover { cursor: default; }

.new-survey__list-title { font-size:0.93em !important; font-weight: 200; }
.new-survey__list-title > span { font-size: 1rem !important; }

/**realign survey-taking**/

.survey__subheading, .survey-subsection:not(.editing-survey-subsection) { margin-left: 0px !important; }

.droppable-i, li {
    list-style:none !important;
}

.droppable { font-weight: 600; }
.droppable > a:not(.closed) { color: #6fc96f !important; }

.sortable-subsection, .ui-sortable-handle, .ui-sortable { overflow: auto; }

#edit-report__save-flash {
    display: none; margin-bottom: 0px; border: none; width: 117px; text-align: center;
}

.bar-graph { overflow: visible !important; }

.survey__options .saveButton { color: #6fc96f; padding-top: 2px; }

.eprove-signup div div p { color: #777; font-weight: 500; }
.eprove-signup div div p a { text-decoration: none !important; }
.eprove-signup div div p:nth-child(3) { margin-bottom: 10px; }
.fifty { width: 50%; }

.currentlyEditing_element { border: 1px dotted #6fc96f !important; }

.okButton { float: right; text-align: right; margin-bottom: 100px; }

.okButton button { border-radius: 5px; }

.centeredMessage {
    text-align: center;
    margin: 30vh auto;
}

.centeredMessage h1 { color: #6fc96f !important; }

.survey__submit-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in 4s;
}

.survey__submit-screen.show {
    opacity: 0.9;
    z-index: 99;
    transition: all 0.5s ease-in 4s;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "eleoticons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
    content: "f";
}

@keyframes reveal {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

.submit-screen__outer {
    animation: reveal;
}

.reveal {
    animation-name: reveal;
    animation-duration: 0.25s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-direction: alternate;
    animate-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

/* whitespace cleanup */
.survey-subsection {
    padding-top: 10px;
    padding-bottom: 20px;
}
.survey__q {
    margin-top: 10px;
    margin-bottom: 10px;
}
.survey__choice, .survey__mc {
    margin-bottom: 0;
}

/* distribution customizations */
.survey-header .survey-header__details span.status {
    display: inline-block;
    float: right;
    margin-left: 1em;
    color: #6fc96f;
    font-weight: bold;
}
.survey-header .content .share_admin {
    margin-bottom: 10px;
}
.survey-header .content .token_admin {
    margin-top: 20px;
}
.survey-header .content .token_label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #333;
}
.survey-header .content .token_label input[type="checkbox"] {
    margin-right: 0.625rem;
}
.survey-header .content .token_count {
    display: inline-block;
    margin: 0.625rem 0 0;
    line-height: 35px;
    font-size: 0.875rem;
    font-weight: 400;
    font-style: italic;
    vertical-align: baseline;
}

.tokens-manage-header h3 {
    margin-bottom: 0;
}
.tokens-manage-header .tokens-manage-count {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: italic;
}
.tokens-manage-actions-addTokens {
    position: relative;
}
.tokens-manage-actions-addTokens > button {
    margin-bottom: 10px;
}
.tokens-manage-actions-addTokens-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0.3125rem;
    z-index: 99;
    width: 450px;
    padding: 0.5em 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
}
.tokens-manage-actions-addTokens-menu.visible {
    display: block;
}
.tokens-manage-actions-addTokens-menu:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 0;
    border-color: transparent transparent white transparent;
    border-bottom-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
.tokens-manage-actions-addTokens-menu .head,
.tokens-manage-actions-addTokens-menu .body,
.tokens-manage-actions-addTokens-menu .foot {
    padding: 0.625rem 0.9375rem;
}
.tokens-manage-actions-addTokens-menu .foot {
    text-align: right;
}
.tokens-manage-actions-addTokens-menu-input {
    height: 35px;
    border: 2px solid #ddd;
    border-radius: 5px;
}
.tokens-manage-actions-addTokens-menu-input.error {
    border-color: #F04124;
}
.tokens-manage-actions-addTokens-menu-remaining {
    font-size: .875em;
    line-height: 35px;
}
.tokens-manage-actions-addTokens-menu-remaining.error {
    color: #F04124;
}
.echo-surveys .tokens-manage-search .search-filter {
    height: 42px;
}
.echo-surveys .tokens-manage-search .search-filter .icon {
    width: 40px;
    height: 40px;
    margin-top: 0 !important;
    line-height: 40px;
}
.echo-surveys .tokens-manage-search .search-filter input {
    height: 40px;
}
.tokens-manage-search-info {
    min-height: 1px;
    line-height: 42px;
    vertical-align: middle;
}
.tokens-manage-search-info .button {
    display: inline-block;
    width: 33px;
    height: 33px;
    padding: 0;
    margin: 0 6px 0 0;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    vertical-align: middle;
}
.tokens-manage-table .tokens-manage-table-date {
    font-size: .875em;
}
.tokens-manage-table .tokens-manage-table-date span {
    display: block;
    margin-top: 3px;
    font-style: italic;
    font-size: .875em;
    color: #aaa;
}
.tokens-manage-table .tokens-manage-table-action > div {
    float: right;
}
.tokens-manage-table .tokens-manage-table-action > div > a {
    color: #aaa;
}

/* matrix customizations */


@media (max-width: 50.063em) {
    .survey-subsection {
        padding-top: 0;
        padding-bottom: 1.5rem;
    }
    .survey-subsection .survey-subsection {
        padding-bottom: 0;
    }
    .survey-subsection__text.matrix p {
        padding-bottom: .4em;
        margin-bottom: 0;
        font-weight: 300;
        color: #333333;
        border-bottom: 1px solid #bbb;
    }
    .survey__q, .survey__subheading {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.3;
    }
    .survey__subheading {
        padding-bottom: 0;
    }
    .survey__rating {
        margin-top: 10px;
    }
}

@media (min-width: 50.063em) {
    .survey-subsection.matrix {
        padding: .7rem 1rem;
        margin-top: 30px;
        margin-bottom: 10px;
        background-color: #f5f5f5;
    }
    .survey-subsection.matrix p,
    .survey__q.matrix {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
        line-height: 30px;
        color: #333333;
    }

    .survey-subsection.matrix,
    .survey-subsection.matrix-child {
        padding: .7rem 1rem;
        margin-top: 60px;
        margin-bottom: 50px;
        background-color: #f5f5f5;
    }
    .survey-subsection.matrix-child.even {
        background-color: #ffffff;
    }
    .survey-subsection.matrix + .survey-subsection.matrix-child {
        margin-top: -10px;
    }
    .survey-subsection.matrix-child + .survey-subsection.matrix-child {
        margin-top: -50px;
    }
    .survey-subsection.matrix-child:before,
    .survey-subsection.matrix-child:after {
        display: table;
        content: " ";
    }
    .survey-subsection.matrix-child:after {
        clear: both;
    }
    .survey__q.matrix {
        float: left;
        width: 30%;
    }
    .survey__q.matrix .survey__tag-container {
        display: none;
    }
    .survey__answer.matrix {
        margin-left: 33%;
    }
    .survey__answer.matrix.matrix-message {
        margin-left: 0;
    }
    .survey__answer.matrix.matrix-message .survey-subsection.matrix {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
    }
    .survey__rating.matrix {
        margin-top: 0;
        background-position: 50% 52%;
    }
    .survey__rating.matrix .survey__rating-choice,
    .survey__rating.matrix .survey__rating-choice label {
        height: 30px;
    }
    .survey-subsection.matrix-child + .survey-subsection.matrix-child .survey__answer.matrix .opinion-scale-text {
        display: none;
    }
    .survey-subsection.matrix-child .survey__answer.matrix .opinion-scale-text {
        bottom: 44px;
    }
    .survey__rating.matrix .survey__rating-choice input:checked + label {
        font-weight: 300;
    }

    .greyout {
        color: lightgrey !important;
    }
    .survey-name{
        max-width: 446px;
    }
}


.institution-select__group li{
    border-bottom: none;
}

.separator-line {
    height: 1px;
    background: lightgrey;
    margin-left: 30px;
}

.institution-selector-wrap {
    padding: 20px 20px 20px 20px !important;
    background-color: #f8f8f8 !important;
    margin-top: 20px;
}

.institution-select__input {
    margin-top: 0;
    padding: 0;
}

.institution-select__input {
    input::-ms-clear {
        display: none;
    }
}

.institution-selector-container h3 {
    font-size: 24px;
}

.institution-selector-container {
    width: 100%;
    height: 100%;
    max-width: none;
    margin: 0;
}

.results-list {
    border: 1px solid;
    margin-top: 5px;
    overflow-y: auto;
    background-color: white;
}

.ac-height-one {
    height: 130px;
}

.ac-height-two {
    height: 225px;
}

.ac-height-default {
    height: 230px;
}

.ac-items-list {
    color: #565656;
    font-size: 18px !important;
    padding: 10px;
}
.ac-items-list div.secondary {
    font-size: 14px !important;
}

.ac-items-list div {
    font-family: ‘Open Sans’, sans-serif;
    line-height: 1.5;
    font-weight: 400;
}

.results-count {
    border-top: 1px solid;
    height: 35px;
    padding-left: 10px;
}

.results-count span {
    line-height: 35px;
    color: #666;
    font-size: inherit;
}

.highlight {
    font-weight: bold;
    font-size: inherit !important;
    color: #565656 !important;
}
.prettydropdown.disabled, .prettydropdown > ul > li.disabled {
    opacity: 1 !important;
}
.sharing-menu-container{
    background: #f3f3f3;
    padding: 20px;
}
.sharing-menu-container .button-container{
    display: flex;
    
}
.sharing-menu-container  button[disabled]{
    background-color: grey;
    cursor: not-allowed;
}

.sharing-selection-container{
    border: 2px solid #ddd;
    border-radius: 0.375rem;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #F8F8F8;
}
.download-icon-sharing{
    margin: 0 1.25rem !important;
}
.owner-info{
    padding: 10px 0px; 
    font-weight: 600;
}
.inst-sharing-label{
    padding: 15px 0px;
}
.padding-left-30{
    padding-left: 30px !important;
}
.sharing-header-label{
    background-color: #F8F8F8;
    padding: 18px;
    border: 2px solid #ddd;
    border-radius: 0.375rem;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 19px;
    position: absolute;
    border-bottom: none;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}
.parent-sharing-buttons{
    top:55px;
    position: relative;
}
.parent-sharing-buttons .cancel{
    background-color: #D0D0D0 !important;
}
.roster-button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.roster-button-container .icon-download{
    vertical-align: middle;
}
.roster-button-container button{
    border-radius: 3px;
    width: 100%;
    background-color: #148800;
}
.message hr{
    margin: inherit;
}
.message .download-text{
    text-align: justify;
    font-size: 16px;
    width: 430px;
}
.message .upload-text{
    text-align: justify;
    font-size: 16px;
}

.copyright-and-links {
    background-color: #E9E9E9 !important;
    padding: 14px;
}

.color-black-underline-link {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 500;
    color: #1a1a1a;
    border: 0;
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
}

.survey-taking-submit-msg {
    z-index: 99;
    width: 300px;
    height: 50px;
    position: absolute;
    margin: auto;
    top: 220px;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight:600;
}

#survey-thank-you-page {
    height: 100vh;
}

#survey-thank-you-page .page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
}
#survey-thank-you-page .message-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#survey-thank-you-page .thank-you-message {
    text-align: center;
    color: #1a1a1a;
}

#survey-thank-you-page .app-footer {
    /*width: 100%;*/
    height: 80px;
    line-height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 25px 0 25px;
    /*margin-top: 18px;*/
    border-top: 2px solid rgba(0, 0, 0, 0.09);
    margin-left: 10px;
    margin-right: 10px;

}

#survey-thank-you-page .footer-item {
    flex: 1;
}

#survey-thank-you-page .company-logo {
    display: inline-flex;
}

#survey-thank-you-page .version{
    text-align: right;
    justify-content: center;
    font-size: 12px;
    color: #4a4a4a;
    font-weight: 300;
}

#survey-thank-you-page a {
    text-decoration: none;
    font-size: 13px;
    color: #4a4a4a;
}

#survey-thank-you-page .rebranding-logo {
    height: 45px;
    /*width: 18.75em;*/
}

#survey-thank-you-page .version-copyright-container {
    line-height: normal;
}

.survey-taking-footer a {
    text-decoration: none;
}
