#publish-page,
#published-inst-page {
  padding-top: 2.5rem;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;

  .spinner-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.02);
    position: relative;
    z-index: 100;
  }

  .generic-spinner {
    color: #6fc96f;
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .publish-container {
    height: 100%;
    width: 100%;
    max-width: 50rem;
    min-width: 30rem;
  }

  .publish-container {
    .select-customers {
      height: calc(100% - 300px);
    }
  }

  .publish-container {
    &.confirm-page {
      .select-customers {
        height: calc(100% - 200px);
        min-width: 35rem;
      }
    }
  }

  .child-publish {
    color: black;
  }

  .select-customers {
    position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }

  .green {
    color: #6CCA6B;
    font-weight: 600;
  }

  .grey {
    color: #333;
  }

  .lightgrey {
    color: #bfbfbf;
  }

  .inactive {
    cursor: not-allowed;
  }

  button.publish {
    margin-top: 10px;
   	height: 29px;
    line-height: 29px;
    width: 139px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: normal;
    padding: 0;
  }

  button.publish.confirm-institutions {
    margin-top: 10px;
    height: 29px;
    line-height: 29px;
    width: 139px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: bold;
    padding: 0;
  }

  button.cancel-publish {
    margin-top: 10px;
   	height: 29px;
    line-height: 29px;
    width: 139px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: normal;
    padding: 0;
    border: 1px solid rgb(153,153,153);
    color: rgb(153,153,153);
    background: white;
  }

  button.cancel-publish.confirm-institutions{
    margin-top: 10px;
    height: 29px;
    line-height: 29px;
    width: 139px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: bold;
    padding: 0;
    margin-right: 10px;
    border: none;
    color: #fff;
    background: #ccc;
  }

  button.left-float {
    float: left;
  }

  button.right-float {
    float: right;
  }

  .hierarchy-popup {
    position: absolute;
    font-size: 12px;
    background-color: #273850;
    padding: 8px 11px;
    border-radius: 5px;
    left: 25px;
    color: white;
    z-index: 1;

    &-above {
      top: -35px;

      .triangle {
        top: 20px;
        border-top: 20px solid #273850;
      }
    }

    &-below {
      bottom: -40px;

      .triangle {
        top: -15px;
        border-bottom: 20px solid #273850;
      }
    }

    .triangle {
      position: absolute;
      z-index: 0;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .parent {
      color: white;
    }
  }

  .child-padding {
    padding: 8px 2px 2px 16px;
  }

  .parent-name {
    font-size: 17px;
    position: relative;
    padding: 10px;
    font-weight: 400;
  }

  .selectable-parent-name {
    font-size: 17px;
    font-weight: 400;
    position: relative;
    padding: 12px 10px;

    &.selected {
      background-color: #F8F8F8;
    }
  }

  .owner-institution-name {
    font-weight: normal;
  }

  .hover-parent {
    &:hover {
      border: 1px solid #6CCA6B;

      i, .name-text {
        color: #273850;
      }
    }
  }

  .border-container {
    border-bottom: 1px solid #ccc;
  }

  .confirm-parent-info {
    background: #f3f3f3;
    border-radius: 4px;
    margin-bottom: 4px;
  }

  .selection-container {
    position: relative;
    width: 100%;
    height: 100%;

    .parent-container {
      border: 2px solid #ddd;
      border-radius: 0.375rem;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px 20px 0px 20px;
      overflow: scroll;
      height: inherit;
      z-index: 1;

      div.parent:last-child .parent-name {
        border-bottom: none;
      }

      div.parent:first-child .parent-name {
        padding-top: 4px;
      }
    }
  }

  .delete-icon {
    float: right;
  }

  .no-cursor {
    cursor: not-allowed !important;
  }

  .filter-toggle {
    float: right;
  }

  .legend {
    margin-bottom: 10px;
  }

  .legend-text {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .publish-select-input {
    padding-right: 30px;
  }

  .expand {
    margin-right: 2.5px;
  }

  .expand-child {
    margin-right: -4px;
    margin-left: 6px;
  }

  select {
    border-radius: 4px;
    width: 150%;
  }

  .dropdown-container {
    max-width: 25rem;
  }

  .extra-margin {
    margin-left: 24px;
  }

  .child-name {
    font-weight: 400;
    display: inline-block;
    margin-bottom: 4px;
    font-size: 16px;
    padding: 6px 6px 6px 3px;
    border-radius: 4px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  .selection-modal {
    position: fixed;
    z-index: 2;
    border-radius: 5px;
    bottom: 0;
    top: -25%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    width: 500px;
    height: 355px;
    -webkit-box-shadow: -3px 5px 80px 0px rgba(0,0,0,0.52);
    -moz-box-shadow: -3px 5px 80px 0px rgba(0,0,0,0.52);
    box-shadow: -3px 5px 80px 0px rgba(0,0,0,0.52);
  }

  .selection-modal-title {
    height: 86px;
    background-color: #148900;
    color: white;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 40px;
      margin-right: 10px;
    }
  }

  .modal-content {
    padding: 30px 40px;

    .message {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .radio-wrapper-selected {
    margin-bottom: 20px;
  }

  .radio-text {
    display: inline-block;
    margin-top: -3px;
    width: 90%;
  }

  .radio-label {
    vertical-align: middle;
    display: inline;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .radio-label-selected {
    font-size: 16px;
    font-weight: bold;
    color: #6fc96f;
  }

  .radio {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 6px;
    width: 20px;
    height: 20px;
    margin-bottom: 14px;
    margin-top: 0px;

    label.radio-label-selector {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      border: 1px solid #979797;
      border-radius: 100%;

      &:after {
        opacity: 0;
      }
    }

    input[type=radio] {
      visibility: hidden;
      margin: 0;
      &:checked + label.radio-label-selector:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        opacity: 1;
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: #57CB6D;
      }
      &:checked + label.radio-label-selector {
        background: white;
      }
    }
  }

  .button-row {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 19px;

    button {
      float: right;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;

      border-radius: 3px;
      width: 115px;
      height: 43px;

      &.cancel {
        margin-right: 14px;
        background-color: #AFAFAF;
      }
    }
  }

  i {
    cursor: pointer;
  }

  @media(max-width: 750px) {
    .select-customers {
      flex-direction: column;
      max-height: none;
    }

    .publish-container {
      padding: 14px;
    }
  }

}
