$inactive-grey: #ccc;
$darker-grey: #333333;

%inactive {
  background: white;
  border: 1px solid $inactive-grey;
}

#publish-page,
#published-inst-page {
  .checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 0.25rem;
    width: 17px;
    height: 17px;
    cursor: pointer;

    &.inactive {
      @extend %inactive;
      cursor: not-allowed;
    }
  }

  .checkbox-grandchild {
    vertical-align: -1px;
    margin-right: 0px;
    margin-left: 15px;
    width: 14px;
    height: 14px;
  }

  .check-all {
    background: #57CB6D;

    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      content: '\F00C';
      color: white;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  .checked {
    background: white;
    border: 1px solid $darker-grey;

    &:after {
      position: absolute;
      top: 1px;
      left: 1px;
      content: '\F00C';
      color: #57CB6D;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  .checked-owner {
    background: #B3B3B3;
    border: 1px solid #999;

    &:after {
      position: absolute;
      top: 1px;
      left: 1px;
      content: '\F00C';
      color: #565656;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  .checkbox-grandchild {
    &:after {
      font-size: 12px;
    }
  }

  .check-partial {
    background: white;
    border: 1px solid $inactive-grey;

    &:after {
      position: absolute;
      top: 0;
      content: '';
      width: 0;
      height: 0;
      border-top: 15px solid #57CB6D;
      border-right: 15px solid transparent;
    }
  }

  .unchecked {
    background: white;
    border: 1px solid $darker-grey;
  }
}
