.pii-toggle {
  width: 50px;
  height: 30px;
  background: lightgrey;
  border: 1px solid grey;
  border-radius: 25px;
  cursor:pointer;
}

.pii-toggle.pii-toggle-on {
  background: #44b644;
  border: 1px solid #148900;
}

.pii-toggle-ball{
  position: relative;
  left: 5px;
  top: 4px;
  transition: all 0.15s linear;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  background: whitesmoke;
  border: 1px solid grey;
  cursor:pointer;
}

.pii-toggle-ball.pii-toggle-on{
  left: 25px;
  transition: all 0.15s linear;
}

