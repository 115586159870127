/* This fixes a flickering issue due the way ng-show, ng-hide, and ng-if work */
.ng-hide.ng-hide-animate{
  display: none !important;
}

.survey-assign-participants-section {
  background-color: #f3f3f3;
  border: #d1d1d1 1px solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 20px;

  .pull-right {
    display: block;
    float: right;
  }

  .filter-application-container {
    button {
      margin-right: 20px;
    }
  }

  .value-selector{
    display: inline-block;
  }

  .filter-results-container {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;

    .filter-results {
      display: inline-block;
      height: auto;
      display: flex;
      p {
        color: #4A4A4A;
        display: inline-block;
        flex: 1 0 auto;
        flex-direction: row;
        font-size: 18px;
        font-family: "Open Sans";
        font-weight: normal;
        height: 45px;
        padding-left: 10px;
        line-height: 45px;
        border: 1px #cccccc solid;
        border-right: none;
        background-color: #FFF;
        -webkit-border-radius: 3px 0px 0px 3px;
        -moz-border-radius: 3px 0px 0px 3px;
        border-radius: 3px 0px 0px 3px;
      }

      button {
        color: #6FC96F;
        border: 1px #cccccc solid;
        padding: 0;
        font-size: 28px;
        border-left: none;
        background-color: #FFF;
        -webkit-border-radius: 0 3px 3px 0;
        -moz-border-radius: 0 3px 3px 0;
        border-radius: 0 3px 3px 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      /** Refresh button **/
      button + button{
        margin-left: 30px;
        border: 1px #cccccc solid;
        background-color: #FFF;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }

    }

    /** Download button **/
    button {
      display: inline-block;
      height: 45px;
      width: 45px;
      float: right;
    }

  }


  & select {
    display: inline-flex;
    flex: 1 0 100%;
    flex-direction: row;
    background-color: #FFF;
    background-image: url("./images/select-down-arrow.svg");
    background-size: 15px 8px;
    background-position: right;
    background-repeat: no-repeat;
    border: 0;
    border-right: 10px solid #FFF;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 38px;
    padding-left: 0.625rem;
    padding-right: 1.25rem;
    width: 100%;
    font-weight: 400;
    cursor: pointer;
    font-size: 1rem;
    color: #333;

    &.multiselect {
      height: auto;
    }


  }

  & select.inactive {
    /* for Firefox */
    -moz-appearance: none !important;
    /* for Chrome */
    -webkit-appearance: none !important;
    background-image: none !important;
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
    text-align: center !important;
    width: auto !important;
    .rule-filter-container & {
      min-width: 10px;
    }
    cursor: default;
    font-weight: bold !important;
  }
  
  & select.inactive::-ms-expand{
    display: none !important;
  }

  & h6.inactive-answer {
    display: inline-block;
    line-height: 38px;
    height: 38px;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    width: auto;
    margin: 10px 0;
    //margin-bottom: 10px;
    cursor: default;
    font-size: 1rem;
    color: #333;
    vertical-align: middle;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    &.value {
      background-color: #FFF;
      border: 1px solid #979797;
      font-weight: bold;
      &.error {
        border-color: #D54230;
      }
    }

    &.operator {
      background-color: transparent;
      border: none;
      padding: 0;
      padding-left: 4px;
      padding-right: 4px;
      font-family: "Open Sans";
      //font-size: 20px;
      font-weight: normal;
      margin: 0;
      float: left;
      margin-right: 8px;
    }

    &.removable {
      padding-right: 0;
    }
  }

  & .rule-filter-container {
    display: inline-block !important;
    width: auto;
  }

  & .rule-filter-container:last-of-type {
    min-width: 300px;
  }
  & .rule-operator-container {
    display: inline-block !important;
    width: auto;
    min-width: 80px;
    margin: 10px 5px !important;
  }

  & .rule-value-container {
    display: inline-block !important;
    width: auto;
    min-width: 300px;
    margin: 10px 5px 10px 0 !important;
  }



  & .rule-value-edit-container {
    display: inline-block !important;
    width: auto;
    min-width: 100px;
  }

  >.category-selection-container {
    margin-right: 37px;
    height: 100%;
    line-height: 96px;
    width: 200px;

    & > p {
      position: relative;
      margin: 28px 0;
      display: block;
      border: 1px solid #AAA;
      background: #FFFFFF;
      height: 40px;
      vertical-align: middle;
      line-height: 38px;
      padding: 0 10px;
      border-radius: 3px;
      color: #333;
      text-align: left;
    }
  }

  &.category {
    height: 96px;
  }

  &.participant-count {
    height: 60px;
    border-width: 2px;
  }

  &.light {
    background-color: #fff;
    border: 1px solid #979797;
  }

  .icon-download{
    text-align: center;
    vertical-align: middle;
    font-weight: 800;
  }

  .refresh-button {
    height: 45px;
    width: 45px;
    margin: 0;
    padding: 0;
  }

  .icon-reset {
    text-align: center;
    vertical-align: middle;
    font-weight: 800;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &:before {
      line-height: 43px;
    }
  }
  .value-error-container {
    display: block;
    color: #D54230;
    font-size: 15px;
    font-weight: normal;
    font-family: "Open Sans";

    button.clear {
      height: 38px;
      font-size: 15px;
      display: inline-block;
      //clear: both;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
  }

  .icon-add {
    height: inherit;
    width: auto;
    line-height: 38px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    justify-items: center;
    padding-top: 2px;

    &:before {
      font-size: 20px;
      line-height: inherit;
    }
  }

  .icon-close {
    line-height: 38px;
    color: #333;
    &:hover {
      color: #FFF;
    }
    &:before {
      font-size: 24px;
      line-height: inherit;
      font-weight: bold !important;
    }
  }

  .inactive-answer {
    margin-bottom: 10px;
    .icon-close {
      line-height:auto;
      font-size: 18px;
      color: #666;

      &:hover {
        color: #333;
      }
      &:before {
        font-size: 18px;
        line-height:inherit;
        font-weight: normal !important;
      }
    }
  }

  .inactive-answer.error {
    color: #D54230;
  }



}

.assign-participants-navigation {
  margin-top: 80px;

  >button {
    display: block;
    width: 90px;
    height: 40px;
    float:right;
    margin-left: 10px;
  }
}
.category-selection-message {
  padding-left: 20px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 96px;
  text-align: left;
}

.page-subheader {
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.sweet-alert[data-custom-class="calculateParticipantsAlert"] {
  h2 {
    background-color: #148900;
    color: #FFFFFF;
    margin-left: -17px;
    margin-top: -17px;
    margin-right: -17px;
    padding-top: 23px;
    padding-bottom: 23px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Open Sans";
  }
  .sa-icon {
    display: none !important;
  }
  > button {
    font-weight: bold;
    height: 43px;
    width: 115px;
    &.confirm {
      float:right;
    }
    &.cancel {
      position: absolute;
      right: 150px;
    }
  }
  > p {
    padding-top: 8px;
    color: #333333;
  }
}
.sweet-alert[data-custom-class="confirmAssignAlert"] {
  h2 {
    background-color: #148900;
    color: #FFFFFF;
    margin-left: -17px;
    margin-top: -17px;
    margin-right: -17px;
    padding-top: 23px;
    padding-bottom: 23px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Open Sans"
  }
  .sa-icon {
    display: none !important;
  }
  > button {
    font-weight: bold;
    height: 43px;
    width: 115px;
    &.confirm {
      float:right;
    }
    &.cancel {
      position: absolute;
      right: 150px;
    }
  }
  > p {
    padding-top: 8px;
    color: #333333;
    > strong {
      color: #148900;
    }
  }
}

.sweet-alert[data-custom-class="confirmAssignAlert"] {
  h2 {
    background-color: #148900;
    color: #FFFFFF;
    margin-left: -17px;
    margin-top: -17px;
    margin-right: -17px;
    padding-top: 23px;
    padding-bottom: 23px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Open Sans"
  }
  .sa-icon {
    display: none !important;
  }
  > button {
    font-weight: bold;
    height: 43px;
    width: 115px;
    &.confirm {
      float:right;
    }
    &.cancel {
      position: absolute;
      right: 150px;
    }
  }
  > p {
    padding-top: 8px;
    color: #333333;
    > strong {
      color: #148900;
    }
  }
}
.sweet-alert[data-custom-class="confirmDeselectAllAlert"] {

  .selection-modal-title-icon {
    font-size: 40px;
    margin-right: 5px;
  }

  .selection-modal-title-text {
    //height: 86px;
    //background-color: #148900;
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    //display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0;
    margin: 0 0 0 0;
    display: inline-block;
    line-height: 37px;
    vertical-align: text-bottom;
  }

  h2 {
    background-color: #148900;
    color: #FFFFFF;
    margin-left: -17px;
    margin-top: -17px;
    margin-right: -17px;
    padding-top: 23px;
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Open Sans"
  }
  .sa-icon {
    display: none !important;
  }
  > button {
    float: right;
    margin:30px 0 0 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
    width: 115px;
    height: 43px;


    &.confirm {
      float:right;
    }
    &.cancel {
      position: absolute;
      right: 148px;


    }
  }
  > p {
    text-align: left;
    //padding-top: 8px;
    padding: 8px 15px 0 15px;

    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    > strong {
      color: #148900;
    }
  }
}

select.inactive {
  @at-root .rule-filter-container#{&} {
    min-width: 10px !important;
  }
}


.delete-value {
  background: transparent;
  float:right;
  height: 38px;
  width: 38px;
  margin: 0;
  padding: 0;
  i {
    font-size: 1rem;
    color: #AAA;
    font-weight: 300;
  }
  &:hover {
    background: transparent;
  }
}

.number-participants-message {
  padding-left: 20px;
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 60px;
  text-align: left;
}

pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.top-bar.assign-participants {
  background-color: #148900 !important;
}

.button--eprove:not([disabled]) {
  background-color: #148900 !important;
}


.spinner-wrapper {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;

  .generic-spinner {
    color: #FFFFFF;
    font-size: 120px !important;
    height: 120px;
    width: 120px;
    position: absolute;
    display:block;
    top: 50%;
    left: 50%;
    margin-top: 150px;
    margin-left: -60px;
    opacity: 50%;
  }
}

.download-icon {
  height: 45px;
  width: 45px;
  border: 1px solid #979797;
  font-size: 30px !important;
  line-height: 45px !important;
  vertical-align: center !important;
  text-align: center;
  margin: 6px;
  border-radius: 3px;
  background-color: #F8F8F8;
  color: #6FC96F;
  padding: 0;
}

.download-filtered-icon {
  height: 45px;
  width: 45px;
  font-size: 30px !important;
  line-height: 45px !important;
  vertical-align: center !important;
  text-align: center;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #6FC96F;
  padding: 0;
}

.header-message {
  margin-bottom: 40px;
}


.prettydropdown {
  vertical-align:middle;
  height: 38px;
  &> ul {
    margin: 0 !important;
    position: relative;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    min-width: 116px;
    height: 100%;
    & > li {
      height: 38px !important;
      min-width: 116px;
      line-height: 38px !important; // This overrides the prettydropdown css which has a much larger line height
    }

    &.active {
      height: auto;
      &> li:hover:not(.label){
        background-color: #ccc !important;
      }
    }
  }

  &> select {
    margin: 0;
  }
}
.rule-container {
  &.active {
    .rule-filter-container {
      margin: 10px 5px 10px 0 !important;
      color: #000 !important;
    }
  }
  &.inactive {
    .rule-filter-container {
      margin: 10px 0 !important;
      color: #000 !important;
    }
  }

  select[disabled='']::-ms-value {
    color: #333 !important;
  }

}

.rule-container.inactive {
  .prettydropdown {
    ul {
      width: auto;

      li.selected {
        width: auto !important;
        font-weight: bold;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
      li:not(.selected){
        display: none;
      }

    }
  }
}


.prettydropdown > ul.active > li:not(.label):hover, .prettydropdown > ul.active > li.hover:not(.label), .prettydropdown > ul.active > li:first-child:hover:after {
  background-color: #ccc !important;
}

.prettydropdown:not(.disabled) > ul.active {
  border: 1px solid #a9a9a9 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.prettydropdown > ul > li > span.checked {
  height: 18px;
  width: 18px;
  line-height: 22px;
  background-color: #6FC96F;
  font-size: 17px;
  text-align: center;
  color: #FFF;
  margin: 9px 10px 9px 0 !important; // Overrides default margin set by prettydropdown
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left !important;
  padding: 0;
}
.prettydropdown > ul > li > span.unchecked {
  height: 18px;
  width: 18px;
  line-height: 22px;
  background-color: #FFF;
  border: 1px solid #333;
  font-size: 17px;
  text-align: center;
  color: #FFF;
  margin: 9px 10px 9px 0 !important; // Overrides default margin set by prettydropdown
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left !important;
  padding: 0;
}





.rule-filter-container {
  .prettydropdown.disabled {
    width: 170px !important;
    ul {
      width: 170px !important;

      li {
        width: 170px !important;
      }
    }
  }
  .prettydropdown > ul > li > span.checked {
    display: none;
  }
  .prettydropdown > ul > li > span.unchecked {
    display: none;
  }
}

.rule-operator-container {
  .prettydropdown {
    &> ul {
      width: 116px !important;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      &> li {
        width: 116px !important;
      }
    }
  }
  .prettydropdown > ul > li > span.checked {
    display: none;
  }
  .prettydropdown > ul > li > span.unchecked {
    display: none;
  }
}

.rule-value-container {
  .prettydropdown.single-select > ul > li > span.checked {
    display: none;
  }
}

.rule-container.inactive {
  .rule-filter-container {
    .prettydropdown {
      &> ul {
        &> li {
          text-align: center !important;
          line-height: 38px !important;
          color: #333;
          font-weight: bold;
          padding: 0;
          width: 100% !important;

        }
      }
    }
  }
  .rule-operator-container {
    .prettydropdown {
      &> ul {
        cursor: not-allowed;
        width: 100px !important;
        &> li {
          width: 100px !important;
          text-align: center !important;
          line-height: 38px !important;
          color: #333;
          font-weight: bold;
        }
      }
    }
  }
}

.rule-operator-container {
  line-height: 35px !important;
  vertical-align: top;
}
.rule-filter-container {
  line-height: 35px !important;
  vertical-align: top;
}
.rule-value-container {
  line-height: 35px !important;
  vertical-align: middle;
  input {
    line-height: 50px !important;
    vertical-align: top;
    display: inline-block;
    position: relative;
  }
}
input.value-selector[type="text"] {
  margin: 0;
  padding: 0;
  height: 38px;
}

.filter-rules {
  >.section-header {
    display:block;
    width: inherit;
    //height: 96px;
    background-color: #E5E5E5;

    >h3 {
      color: #333333;
      font-family: "Open Sans";
      font-size: 24px;
      font-weight: 600;
      line-height: 84px;
      text-align: left;
      padding: 0;
      margin:0;
      padding-left: 20px;
    }

    >h6 {
      color: #333333;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      text-align: left;
      padding: 0 20px 20px 20px;
      margin:0;
    }
  }

  > .filter-rules-container {
    background-color: #f3f3f3;
    width: 100%;
    display: block;
    position: relative;

    .filter-row{
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
      min-height: 100px;
      position: relative;
      display: block;

      &.placeholder {
        border-bottom: 1px dashed #B1B1B1;
      }

      &.placeholder:last-of-type {
        border: none;
      }

      .filter-index {
        position: absolute;
        top: 50%; left: 0%;
        transform: translate(0%,-50%);


        background-color: #E4EDF1;
        border: 1px solid #979797;
        height: 40px;
        width: 40px;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        border-radius: 24px;

        span {
          background-color: transparent;
          display: block;
          line-height: 38px;
          vertical-align: center;
          height: 100%;
          width: inherit;
          text-align: center;
          padding: 0;
          margin: 0;
          margin-left: -2px;

          color: #333333;
          font-family: "Open Sans";
          font-size: 21px;
          font-weight: 700;
        }
      }

      .filter-criteria {
        margin-left: 60px;
      }
    }
  }
}

.select2 > .select2-choice.ui-select-match {
  /* Because of the inclusion of Bootstrap */
  height: 29px;
}

.selectize-control > .selectize-dropdown {
  top: 36px;
}


.btn-xs {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.query-builder {
  .rules-group-container {

    .rules-group-body {
      .rules-list {
        & .rule-container {
          border-bottom: 1px dashed #B1B1B1 !important;
          padding: 16px 5px;
          padding-right: 48px;
          line-height: 35px;
        }

        & .rule-container:last-of-type {
          border: none !important;
        }
      }
    }
  }
}


.query-builder .drag-handle, .query-builder .error-container, .query-builder .rule-container .rule-filter-container, .query-builder .rule-container .rule-operator-container, .query-builder .rule-container .rule-value-container {
  vertical-align: top !important;
}
.query-builder .rule-container {
  select {
    border: 1px solid #979797;
    background-color: #FFF;
    background-image: url("./images/select-down-arrow.svg");
    background-size: 15px 8px;
    background-position: right 8px center;
    background-repeat: no-repeat;
  }

  .rule-filter-container {
    select {
      width: auto;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      padding-right: 2rem;
    }
  }
  .rule-operator-container {
    select {
      width: auto;
      padding-right: 2rem;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
    }
  }
  .rule-value-container {
    border: none;
    min-width: 200px;
    select, input {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
    
    ul.active {
      li[data-value='PLACEHOLDER'] {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}


.query-builder .rule-container:last-of-type {
  .rule-filter-container {
    select {
      min-width: 10px;
    }
  }
}

.rules-list > .rule-container > .rule-header {
  position: absolute;
  right: 0 !important;
  top: 0;
  bottom: 0;
  height: 100% !important;
}
.rules-list > .rule-container > .rule-header > .rule-actions > button {
  position: absolute;
  top: 42px;
  left: 50%;
  height: 40px;
  width: 40px;
  padding: 0px;
  border: 1px solid #979797;
  -webkit-transform: translate(-100%, -50%);
  -moz-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  -o-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  &> i {
    width: 40px;
    font-size: 1.5rem;
    font-weight: lighter;
  }
}


.query-builder.editing {
    .rule-container {
      .rule-header {
        .rule-actions {
          .delete-rule {
            /* ORIGINAL COLOR */
            //background-color: #D0D0D0;
            //background-color: #6FC96F;
            background-color: #E4EDF1;
            span {
              color: #979797;
              &:before {
                color: #979797;
              }
            }
            &:hover {
              /* RED HOVER COLOR */
              //background-color: #FC645F;
              //background-color: #148900;
              background-color: #eee;

              span {
                //color: #FFF;
                color: #333;
                &:before {
                  //color: #FFF;
                  color: #333;
                }
              }
            }
          }
          .add-rule {
            display: none;
          }
        }
      }
    }
  .rule-container:last-of-type {
    .rule-header {
      .rule-actions {
        .delete-rule {
          display: none;
        }
        .add-rule {
          display: inline-block;
          background-color: #6FC96F;
        }
      }
    }
  }
    .rule-container.has-error {
      .error-container {
        display: none !important;
      }
      .rule-header {
        .rule-actions {
          button {
            pointer-events: none;
          }
          .add-rule {
            background-color: #D0D0D0;
          }
        }
      }
    }
}

.query-builder.applied {
  pointer-events: none;
  .rule-container {
    .rule-header {
      .rule-actions {
        .delete-rule {
          display: none;
        }
        .add-rule {
          display: none;
        }
      }
    }
  }
}

.query-builder .has-error {
  background-color: #FFF !important;
  border-color: transparent !important;
  border: none !important;
}

.query-builder .rules-group-container {
  border: none !important;
  background: transparent !important;
}

.query-builder .rule-container {
  border: none !important;
  background: transparent !important;
}

.rule-actions {
  height: 100% !important;
  position: relative;
}

.json-representation {
  padding: 10px !important;
}

/* Deugging rule container sizes and offsets */

//.rule-filter-container {
//  background-color: #0b93d5;
//  select {
//    background-color: #84b358;
//  }
//  ul {
//    background-color: #84b358;
//  }
//  .prettydropdown {
//    background-color: #84b358;
//  }
//}
//
//.rule-operator-container {
//  background-color: #0b93d5;
//  select {
//    background-color: #84b358;
//  }
//  ul {
//    background-color: #84b358;
//  }
//  .prettydropdown {
//    background-color: #84b358;
//  }
//}
//
//.rule-value-container {
//  background-color: #0b93d5;
//  .value-selector {
//    background-color: #84b358;
//  }
//}


input.value-selector[type=text] {
  line-height: 1.0625rem !important;
}