/* line 324, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-version {
    font-family: "/5.2.3/"; }

/* line 328, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-mq-small {
    font-family: "/only screen/";
    width: 0em; }

/* line 333, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-mq-medium {
    font-family: "/only screen and (min-width:50.063em)/";
    width: 50.063em; }

/* line 338, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-mq-large {
    font-family: "/only screen and (min-width:64.063em)/";
    width: 64.063em; }

/* line 343, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-mq-xlarge {
    font-family: "/only screen and (min-width:90.063em)/";
    width: 90.063em; }

/* line 348, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-mq-xxlarge {
    font-family: "/only screen and (min-width:120.063em)/";
    width: 120.063em; }

/* line 353, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
meta.foundation-data-attribute-namespace {
    font-family: false; }

/* line 360, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
html, body {
    height: 100%; }

/* line 365, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

/* line 370, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
html,
body {
    font-size: 100%; }

/* line 373, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
body {
    background: white;
    color: #999999;
    padding: 0;
    margin: 0;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1;
    position: relative;
    cursor: default; }

/* line 386, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
a:hover {
    cursor: pointer; }

/* line 389, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
img {
    max-width: 100%;
    height: auto; }

/* line 391, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
img {
    -ms-interpolation-mode: bicubic; }

/* line 397, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
    max-width: none !important; }

/* line 402, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.left {
    float: left !important; }

/* line 403, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.right {
    float: right !important; }

/* line 176, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.clearfix:before, .clearfix:after {
    content: " ";
    display: table; }
/* line 177, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.clearfix:after {
    clear: both; }

/* line 405, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.hide {
    display: none; }

/* line 411, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

/* line 414, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
img {
    display: inline-block;
    vertical-align: middle; }

/* line 424, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
textarea {
    height: auto;
    min-height: 50px; }

/* line 427, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
select {
    width: 100%; }

/* Foundation Dropdowns */
/* line 219, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown, .new-survey__list, #lang_choices, .survey__tag-list {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #f7f7f7;
    border: solid 0 #cccccc;
    font-size: 0.9375rem;
    z-index: 99;
    margin-top: 2px;
    max-width: 200px; }
/* line 69, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown > *:first-child, .new-survey__list > *:first-child, #lang_choices > *:first-child, .survey__tag-list > *:first-child {
    margin-top: 0; }
/* line 70, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown > *:last-child, .new-survey__list > *:last-child, #lang_choices > *:last-child, .survey__tag-list > *:last-child {
    margin-bottom: 0; }
/* line 95, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown:before, .new-survey__list:before, #lang_choices:before, .survey__tag-list:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 0;
    border-color: transparent transparent white transparent;
    border-bottom-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99; }
/* line 102, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown:after, .new-survey__list:after, #lang_choices:after, .survey__tag-list:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 1;
    border-color: transparent transparent #cccccc transparent;
    border-bottom-style: solid;
    position: absolute;
    top: -2;
    left: -1;
    z-index: 98; }
/* line 110, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.right:before, .right.new-survey__list:before, .right#lang_choices:before, .right.survey__tag-list:before {
    left: auto;
    right: 0; }
/* line 114, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.right:after, .right.new-survey__list:after, .right#lang_choices:after, .right.survey__tag-list:after {
    left: auto;
    right: -1; }
/* line 222, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-right, .drop-right.new-survey__list, .drop-right#lang_choices, .drop-right.survey__tag-list {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #f7f7f7;
    border: solid 0 #cccccc;
    font-size: 0.9375rem;
    z-index: 99;
    margin-top: 0;
    margin-left: 2px;
    max-width: 200px; }
/* line 69, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-right > *:first-child, .drop-right.new-survey__list > *:first-child, .drop-right#lang_choices > *:first-child, .drop-right.survey__tag-list > *:first-child {
    margin-top: 0; }
/* line 70, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-right > *:last-child, .drop-right.new-survey__list > *:last-child, .drop-right#lang_choices > *:last-child, .drop-right.survey__tag-list > *:last-child {
    margin-bottom: 0; }
/* line 124, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-right:before, .drop-right.new-survey__list:before, .drop-right#lang_choices:before, .drop-right.survey__tag-list:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 0;
    border-color: transparent white transparent transparent;
    border-right-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99; }
/* line 131, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-right:after, .drop-right.new-survey__list:after, .drop-right#lang_choices:after, .drop-right.survey__tag-list:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 1;
    border-color: transparent #cccccc transparent transparent;
    border-right-style: solid;
    position: absolute;
    top: -1;
    left: -2;
    z-index: 98; }
/* line 226, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-left, .drop-left.new-survey__list, .drop-left#lang_choices, .drop-left.survey__tag-list {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #f7f7f7;
    border: solid 0 #cccccc;
    font-size: 0.9375rem;
    z-index: 99;
    margin-top: 0;
    margin-left: -2px;
    max-width: 200px; }
/* line 69, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-left > *:first-child, .drop-left.new-survey__list > *:first-child, .drop-left#lang_choices > *:first-child, .drop-left.survey__tag-list > *:first-child {
    margin-top: 0; }
/* line 70, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-left > *:last-child, .drop-left.new-survey__list > *:last-child, .drop-left#lang_choices > *:last-child, .drop-left.survey__tag-list > *:last-child {
    margin-bottom: 0; }
/* line 145, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-left:before, .drop-left.new-survey__list:before, .drop-left#lang_choices:before, .drop-left.survey__tag-list:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 0;
    border-color: transparent transparent transparent white;
    border-left-style: solid;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    z-index: 99; }
/* line 153, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-left:after, .drop-left.new-survey__list:after, .drop-left#lang_choices:after, .drop-left.survey__tag-list:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 1;
    border-color: transparent transparent transparent #cccccc;
    border-left-style: solid;
    position: absolute;
    top: -1;
    right: -2;
    left: auto;
    z-index: 98; }
/* line 230, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-top, .drop-top.new-survey__list, .drop-top#lang_choices, .drop-top.survey__tag-list {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: 100%;
    max-height: none;
    height: auto;
    background: #f7f7f7;
    border: solid 0 #cccccc;
    font-size: 0.9375rem;
    z-index: 99;
    margin-top: -2px;
    margin-left: 0;
    max-width: 200px; }
/* line 69, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-top > *:first-child, .drop-top.new-survey__list > *:first-child, .drop-top#lang_choices > *:first-child, .drop-top.survey__tag-list > *:first-child {
    margin-top: 0; }
/* line 70, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-top > *:last-child, .drop-top.new-survey__list > *:last-child, .drop-top#lang_choices > *:last-child, .drop-top.survey__tag-list > *:last-child {
    margin-bottom: 0; }
/* line 168, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-top:before, .drop-top.new-survey__list:before, .drop-top#lang_choices:before, .drop-top.survey__tag-list:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 0;
    border-color: white transparent transparent transparent;
    border-top-style: solid;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    z-index: 99; }
/* line 177, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.drop-top:after, .drop-top.new-survey__list:after, .drop-top#lang_choices:after, .drop-top.survey__tag-list:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset 1;
    border-color: #cccccc transparent transparent transparent;
    border-top-style: solid;
    position: absolute;
    top: auto;
    bottom: -2;
    left: -1;
    right: auto;
    z-index: 98; }
/* line 235, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown li, .new-survey__list li, #lang_choices li, .survey__tag-list li {
    font-size: 0.9375rem;
    cursor: pointer;
    line-height: 1.125rem;
    margin: 0; }
/* line 206, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown li:hover, .new-survey__list li:hover, #lang_choices li:hover, .survey__tag-list li:hover, .f-dropdown li:focus, .new-survey__list li:focus, #lang_choices li:focus, .survey__tag-list li:focus {
    background: #eeeeee; }
/* line 208, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown li a, .f-dropdown li a p, .new-survey__list li a, #lang_choices li a, .survey__tag-list li a {
    display: block;
    padding: 0.625rem 0.9375rem;
    color: #333333; }
/* line 238, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.content, .content.new-survey__list, .content#lang_choices, .content.survey__tag-list {
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    padding: 1.25rem;
    width: 100%;
    height: auto;
    max-height: none;
    background: #f7f7f7;
    border: solid 0 #cccccc;
    font-size: 0.9375rem;
    z-index: 99;
    max-width: 200px; }
/* line 69, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.content > *:first-child, .content.new-survey__list > *:first-child, .content#lang_choices > *:first-child, .content.survey__tag-list > *:first-child {
    margin-top: 0; }
/* line 70, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.content > *:last-child, .content.new-survey__list > *:last-child, .content#lang_choices > *:last-child, .content.survey__tag-list > *:last-child {
    margin-bottom: 0; }
/* line 241, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.tiny, .tiny.new-survey__list, .tiny#lang_choices, .tiny.survey__tag-list {
    max-width: 200px; }
/* line 242, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.small, .small.new-survey__list, .small#lang_choices, .small.survey__tag-list {
    max-width: 300px; }
/* line 243, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.medium, .medium.new-survey__list, .medium#lang_choices, .medium.survey__tag-list {
    max-width: 500px; }
/* line 244, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_dropdown.scss */
.f-dropdown.large, .large.new-survey__list, .large#lang_choices, .large.survey__tag-list {
    max-width: 800px; }

/**
 * Echo global styles
 */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.fullwidth {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: initial; }

/* line 10, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo .page-container {
    padding-top: 50px; }
/* line 12, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo .page-container.no-scroll {
    overflow-y: hidden; }
/* line 17, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo .alert-box + .page-container {
    height: calc(100% - 106px);
    padding-bottom: 100px; }
/* line 26, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo h1, .echo h2, .echo h3, .echo h4, .echo h5, .echo h6 {
    color: #6fc96f;
    border: 0; }
/* line 30, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo h1 span, .echo h2 span, .echo h3 span, .echo h4 span, .echo h5 span, .echo h6 span {
    font-size: 0.875rem;
    font-weight: 300;
    font-style: italic;
    color: #999999;
    line-height: inherit;
    vertical-align: baseline;
    margin-left: 10px; }
/* line 39, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo h1 span .icon, .echo h2 span .icon, .echo h3 span .icon, .echo h4 span .icon, .echo h5 span .icon, .echo h6 span .icon {
    font-size: 1.0625rem;
    display: inline-block;
    vertical-align: -3px;
    color: #ccc;
    margin-right: 3px; }
/* line 50, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.echo .scroller-nav span {
    vertical-align: middle;
    font-size: 2rem; }

/* line 57, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.divider {
    height: 1px;
    background: #ddd;
    margin-top: 50px;
    margin-bottom: 50px; }

/* line 65, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.filter__select select {
    background-color: #f3f3f3;
    background-image: url("./images/select-down-arrow.svg");
    background-size: 15px 8px;
    background-position: right;
    background-repeat: no-repeat;
    border: 0;
    border-right: 10px solid #f3f3f3;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 2.0625rem;
    line-height: 2.0625rem;
    padding: 0 10px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none; }
/* line 81, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.filter__select select::-ms-expand {
    display: none; }
/* line 84, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.filter__select select:hover {
    background-color: #f6f6f6;
    border-right: 10px solid #f6f6f6; }
/* line 90, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.filter__select.rtl select {
    background-position: 5px; }

/* line 100, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert .sa-icon.sa-warning {
    border-color: #999 !important; }
/* line 102, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert .sa-icon.sa-warning .sa-body {
    background-color: #999 !important; }
/* line 105, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert .sa-icon.sa-warning .sa-dot {
    background-color: #999 !important; }
/* line 110, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert.long-content {
    width: 750px;
    margin-left: -375px; }
/* line 113, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert.long-content p {
    text-align: left;
    margin-bottom: 0.625rem;
    font-weight: 400;
    font-size: 0.875rem; }
/* line 119, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert.long-content ul {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
/* line 123, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert.long-content ul li {
    padding: 5px 0; }
/* line 127, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert.long-content label {
    color: inherit;
    font-weight: 400;
    font-size: 0.875rem; }
/* line 133, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.sweet-alert.long-content input[type="checkbox"] {
    margin-bottom: 0;
    margin-right: 5px; }

/* line 140, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.button.alert {
    background-color: #bd7777;
    border-color: #bd7777; }

/* line 145, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
span.error {
    margin-top: 5px; }

@-webkit-keyframes pulseWarning {
    /* line 163, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    0% {
        border-color: #999; }

    /* line 164, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    100% {
        border-color: #999; } }

@keyframes pulseWarning {
    /* line 163, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    0% {
        border-color: #999; }

    /* line 164, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    100% {
        border-color: #999; } }

/* line 166, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.pulseWarning {
    -webkit-animation: pulseWarning 0.75s infinite alternate;
    animation: pulseWarning 0.75s infinite alternate; }

@-webkit-keyframes pulseWarningIns {
    /* line 171, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    0% {
        background-color: #999; }

    /* line 172, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    100% {
        background-color: #999; } }

@keyframes pulseWarningIns {
    /* line 171, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    0% {
        background-color: #999; }

    /* line 172, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
    100% {
        background-color: #999; } }

/* line 174, /Users/ds/Documents/echo-static/source/styles/echo/_base.scss */
.pulseWarningIns {
    -webkit-animation: pulseWarningIns 0.75s infinite alternate;
    animation: pulseWarningIns 0.75s infinite alternate; }

/**
 * Echo Main Nav
 * Includes off canvas sidebar and top nav bar
 */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
ul.off-canvas-list li a {
    font-size: 17px;
    font-weight: 600; }

/* line 6, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.list-wrap a {
    font-weight: 600; }
/* line 8, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.list-wrap a.logged-in__school {
    margin-top: 1.5625rem;
    font-weight: 400; }

/* line 14, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer {
    position: static;
    font-weight: 400;
    padding: 1.25rem; }
@media (min-height: 630px) {
    /* line 14, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
    .sidebar__footer {
        width: 100%;
        position: absolute;
        bottom: 0; } }
/* line 24, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer p {
    font-weight: 400;
    font-size: 0.8125rem; }
/* line 27, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer p:first-child {
    font-size: 0.9375rem;
    margin: 0; }
/* line 34, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer .inline-list li {
    font-size: 0.8125rem; }
/* line 39, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer img {
    display: block;
    margin: 0 auto 10px; }
/* line 44, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer a {
    display: inline-block; }
/* line 48, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.sidebar__footer .footer__link {
    border-bottom: 1px dotted #6fc96f; }

/* line 53, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.top-bar .menu-icon {
    vertical-align: middle; }

/* line 57, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.top-bar__search {
    bottom: auto;
    top: 0.5rem; }

/* line 62, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.top-bar .right {
    margin-right: 10px; }

/* line 66, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list {
    top: 42px;
    background: #fff;
    max-width: 372px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    color: #aaa;
    text-align: left;
    padding:0.5em 0; }
/* line 77, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list p {
    padding: 0.625rem 0.9375rem;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400; }
/* line 84, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list a {
    color: #999;
    font-size: 0.8875rem; }
.new-survey__list a:hover {
    cursor: pointer;
}
.new-survey__list-title { font-size: 1rem !important; }
.new-survey__list-title:hover {
    color: #333 !important;
    cursor: default !important;
    background: #fff;
}
/* line 90, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list ul {
    margin: 0;
    font-size: 0.9375rem;
    font-weight: 400; }
/* line 96, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list ul li:hover {
    cursor: pointer; }
/* line 100, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list ul a .list_item {
    color: #333;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    text-decoration: none;
   /* font-size: inherit; */
    font-size: 1.19em;
    font-weight: 300;
}
/* line 107, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list ul a .icon {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 1.125rem; }
/* line 115, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.new-survey__list hr {
    margin: 0.625rem 0.9375rem; }

/* line 120, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.button.outline {
    color: #f7f7f7;
    background: transparent;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    box-shadow: none;
    font-weight: 400; }
/* line 129, /Users/ds/Documents/echo-static/source/styles/echo/_main-nav.scss */
.button.outline:hover {
    background: rgba(255, 255, 255, 0.1); }

.button.outline-green {
    color: #6fc96f;
    background: transparent;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #6fc96f;
    box-shadow: none;
    font-weight: 600; }

.button.outline-green:hover {
    background: rgba(255, 255, 255, 0.1); }

/**
 * Echo Dashboard and Survey Library
 */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.alert-box a {
    color: inherit;
    text-decoration: underline; }
/* line 4, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.alert-box a.right {
    text-decoration: none; }

/* line 9, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.alert-box .close {
    text-decoration: none; }

/* line 13, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.tabs {
    border-bottom: 2px solid #ddd;
    font-weight: 600; }
/* line 16, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.tabs li {
    bottom: -2px; }
/* line 19, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.tabs li.active {
    border-bottom: 3px solid #6fc96f; }

/* line 24, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.tabs-content {
    margin-top: 40px; }

@media only screen and (min-width: 50.063em) {
    /* line 28, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
    .latest-activity__overview {
        padding-right: 2.5rem; }
    /* line 31, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
    .latest-activity__overview.medium-centered {
        padding-right: 0.3125rem; } }
/* line 36, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__overview > div {
    padding-bottom: 50px; }
/* line 40, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__overview span {
    font-size: 0.875rem;
    font-weight: 400;
    margin-right: 5px;
    color: #999; }
/* line 48, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__overview p {
    font-size: 0.875rem;
    color: #666;
    margin: 0.625rem 0; }
/* line 54, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__overview a {
    color: #999;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: underline;
    margin-top: 16px; }

/* line 64, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__status span {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: italic; }
/* line 67, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__status span.icon {
    font-size: 0.9375rem;
    vertical-align: middle;
    margin-right: 5px; }

/* line 76, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__metrics table {
    font-weight: 400; }
/* line 79, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__metrics table tr {
    border: 0; }
/* line 83, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__metrics table td {
    color: #333;
    padding: 0.625rem 0;
    font-size: 0.875rem; }
/* line 89, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.latest-activity__metrics table tr td:nth-of-type(2) {
    color: #666;
    font-size: 0.9375rem;
    font-weight: 600; }

/* line 98, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .prefix {
    font-size: 1rem;
    background: transparent;
    border: 0;
    color: #aaa;
    height: 33px;
    line-height: 33px; }
/* line 106, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .icon {
    display: inline-block;
    vertical-align: middle; }
/* line 110, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .icon[data-icon="o"] {
    font-size: 1.875rem;
    display: inline-block;
    vertical-align: middle; }
/* line 116, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter {
    border-radius: 5px;
    height: 33px;
    line-height: 100%; }
/* line 121, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter input,
.echo-surveys .search-filter .icon-close {
    position: absolute;
    visibility: hidden;
    opacity: 0; }
/* line 126, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter input {
    color: #666;
    background: transparent;
    font-size: 1.0625rem;
    font-weight: 400;
    height: 29px;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    max-width: 66%; }
/* line 137, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter .icon {
    color: #ccc;
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center; }
/* line 144, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter .icon:before {
    line-height: inherit; }
/* line 148, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter.open {
    border: 2px solid #ddd; }
/* line 151, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .search-filter.open input,
.echo-surveys .search-filter.open .icon-close {
    visibility: visible;
    opacity: 1;
    position: relative; }

.echo-surveys .search-filter.open input:focus {
    box-shadow: none;
}

/* line 159, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys input {
    border: 0;
    outline: 0;
    height: 31px;
    line-height: 100%;
    margin: 0;
    display: inline-block; }
/* line 168, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys .icon[data-icon="A"] {
    font-size: 1.2em;
    margin-right: 5px; }

/* line 174, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list, table.echo-reports__list {
    font-weight: 400; }
/* line 178, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.survey-library table.echo-surveys__list th:last-child, .survey-library table.echo-reports__list th:last-child,
.survey-library table.echo-surveys__list td:last-child,
.survey-library table.echo-reports__list td:last-child {
    text-align: right; }
/* line 183, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list th, table.echo-reports__list th {
    /*cursor: pointer;*/ }
/* line 187, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list tbody tr:hover, table.echo-reports__list tbody tr:hover {
    background: #fafafa;
    /*cursor: pointer;*/ }
/* line 191, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list span, table.echo-reports__list span {
    color: #aaa;
    display: block;
    font-style: italic;
    font-size: 15px;
    margin-top: 5px; }
/* line 198, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list span span, table.echo-reports__list span span {
    margin-top: 0;
    margin-left: 20px;
    color: #666; }
/* line 204, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list span span.icon, table.echo-reports__list span span.icon {
    float: left;
    margin-left: 0;
    margin-top: 2px; }
/* line 211, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list tr td:first-child, table.echo-reports__list tr td:first-child {
    color: #333; }
/* line 213, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list tr td:first-child span, table.echo-reports__list tr td:first-child span {
    color: #333;
    font-size: 0.875rem; }
/* line 216, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list tr td:first-child span.icon, table.echo-reports__list tr td:first-child span.icon {
    color: #6fc96f; }
/* line 223, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list tr th:last-child, table.echo-reports__list tr th:last-child,
table.echo-surveys__list tr td:last-child,
table.echo-reports__list tr td:last-child {
    width: 46px; }
/* line 225, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list tr th:last-child > a, table.echo-reports__list tr th:last-child > a,
table.echo-surveys__list tr td:last-child > a,
table.echo-reports__list tr td:last-child > a {
    display: block;
    width: 100%;
    min-height: 50px; }
/* line 232, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
table.echo-surveys__list td, table.echo-reports__list td {
    vertical-align: top; }

/* line 237, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys__options, .echo-reports__options {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    text-align: left; }
/* line 240, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.echo-surveys__options span.icon, .echo-reports__options span.icon {
    color: #000;
    font-size: 1.4375rem;
    margin-right: 10px; }

/* line 248, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.icon[data-icon="A"],
.icon.icon-certified-alt {
    color: #6fc96f; }

/* line 253, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.about-echo a {
    color: #333;
    font-weight: normal;
    font-size: 1.0625rem; }
/* line 258, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.about-echo .icon {
    color: #6fc96f;
    font-size: 1.5625rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }

/* line 267, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.autofill-container {
    display: none;
    background: #fff;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 1rem;
    color: #333333;
    line-height: 1.375rem;
    position: absolute;
    z-index: 10;
    margin-top: 5px;
    left: 0.3125rem;
    right: 0.3125rem; }
/* line 282, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.autofill-container ul {
    margin: 0;
    list-style: none; }
/* line 286, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.autofill-container ul li {
    border-top: 1px solid #ccc;
    padding: 0.625rem;
    font-size: 1rem;
    font-weight: 400;
    color: #333; }
/* line 292, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.autofill-container ul li:first-child {
    border-top: 0; }
/* line 295, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.autofill-container ul li:hover {
    background: #f7f7f7; }
/* line 299, /Users/ds/Documents/echo-static/source/styles/echo/_dashboard.scss */
.autofill-container ul li a {
    color: inherit; }

/**
 * Echo Survey
 * Includes sidebar, header, content, sections, questions, etc.
 */
/* Survey Sidebar */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 270px;
    height: calc(100% - 50px);
    color: #ccc;
    background: #fff;
    padding-bottom: 0.875rem;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
    -webkit-transform: translate3d(-270px, 0, 0);
    transform: translate3d(-270px, 0, 0);
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in; }
/* line 17, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.open .survey-edit__sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
/* line 22, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar .sidebar-title {
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
    color: #333333;
    line-height: 1.3125rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem; }
/* line 31, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar h6 {
    padding-left: 0.875rem;
    padding-right: 0.875rem; }
/* line 36, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar h6 span {
    font-size: 1rem;
    vertical-align: -0.125rem;
    margin-left: 0;
    margin-right: 5px; }
/* line 43, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar .current {
    border-right: 5px solid #6fc96f;
    font-weight: bold; }
/* line 48, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul {
    list-style: circle;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.9375rem;
    margin: 0;
    list-style-position: inside; }
/* line 55, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul li {
    padding-left: 0;
    margin: 5px 0;
    margin-left: 1.875rem;
    list-style-position: outside; }
/* line 60, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul li.hr {
    padding: 0.3125rem;
    margin-left: -3em;
    list-style: none; }
/* line 64, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul li ul {
    list-style: decimal;
    list-style-position: inside;
    font-weight: 300;
    padding: 0.3125rem 0; }
/* line 70, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul li ul li {
    padding-left: 0;
    margin-left: 0.625rem; }
/* line 74, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul li ul a {
    display: block;
    color: #000;
    padding: 0.3125rem 0; }
/* line 82, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul.sections {
    list-style: upper-alpha;
    list-style-position: inside; }
/* line 85, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul.sections li {
    padding-left: 0.875rem; }
/* line 87, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul.sections li ul {
    list-style: decimal;
    list-style-position: inside;
    font-weight: 300; }
/* line 91, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul.sections li ul li {
    padding-left: 0.3125rem; }
/* line 94, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul.sections li ul a {
    display: block;
    color: #000;
    padding: 0.3125rem 0; }
/* line 103, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar ul a {
    display: block;
    color: #333;
    padding: 0.3125rem 0; }
/* line 110, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar hr {
    margin-left: 0.875rem;
    margin-right: 0.875rem; 
    cursor: pointer;
}
/* line 115, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar .button {
    display: block;
    margin-left: 0.875rem;
    margin-right: 0.875rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.125rem; }
/* line 122, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar .button.secondary {
    color: #fff;
    background: #ccc; }
/* line 125, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar .button.secondary:hover {
    background: #a3a3a3; }

/* line 133, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar.rtl .current {
    border-right: 0;
    border-left: 5px solid #6fc96f;
    font-weight: bold; }
/* line 140, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar.rtl ul li {
    margin-left: 0;
    margin-right: 1.875rem; }
/* line 143, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar.rtl ul li.hr {
    padding: 0.3125rem; }
/* line 147, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar.rtl ul li ul li {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0.625rem; }
/* line 155, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar.rtl ul.sections li {
    padding-left: 0;
    padding-right: 0.875rem; }
/* line 159, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-edit__sidebar.rtl ul.sections li ul li {
    padding-left: 0;
    padding-right: 0.3125rem; }

/* line 169, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-sidebar__content {
    overflow: auto;
    padding-top: 0.875rem; }

/* line 174, /Users/ds/Documents/echo-static/source/styles/echo/_survey-sidebar.scss */
.survey-sidebar__actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 1em;
    background-color: white;
    }

/* Survey Header */
/* line 4, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .accordion-navigation > a {
    border-bottom: 1px solid #ddd;
    text-decoration: none; }
.survey-header .accordion-navigation > a.active {
    background: #F8F8F8;
}
/* line 7, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .accordion-navigation > a .icon {
    color: #ccc;
    font-size: 1.0625rem;
    margin-top: 0; }
/* line 13, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .accordion-navigation.active > a {
    background: #F8F8F8;
    border-bottom: 1px solid #ddd; }
/* line 16, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .accordion-navigation > .content.active,
.survey-header .accordion-navigation .active + .content{
    border-bottom: 1px solid #ddd; }
/* line 21, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .accordion-navigation:last-child > a,
.survey-header .accordion-navigation:last-child .active + .content {
    border-bottom: 0; }
/* line 24, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .accordion-navigation:last-child > a.active {
    border-bottom: 1px solid #ddd; }
/* line 30, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header p {
    color: #999;
    font-size: 0.75rem;
    font-weight: 400; }
/* line 37, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content span {
    color: #333;
    font-size: 0.875rem;
    font-weight: bold; }
/* line 43, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content p {
    color: #555;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    font-weight: 400;
    margin: 0.625rem 0; }
/* line 51, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content label {
    color: #333;
    font-size: 0.875rem; }
/* line 56, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content input[type="checkbox"] {
    margin: 0; }
/* line 60, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .survey__custom-link {
    display: table;
    table-layout: fixed; }
/* line 64, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .survey__custom-link span {
    display: table-cell;
    font-weight: 400;
    color: #000; }
/* line 71, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .survey__custom-link input,
.survey-header .content .survey__custom-link input:focus {
    display: table-cell;
    margin: 0;
    background: transparent;
    border: 0;
    border-bottom: 1px dashed #777;
    padding: 0;
    box-shadow: none;
    outline: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.1875rem;
    height: 1.1875rem;
    color: #000;
    min-width: 180px; }
/* line 89, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .button-group li {
    padding: 0;
    border-top: 0; }
/* line 93, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .button-group li .button {
    margin: 0;
    background-color: #fafafa; }
/* line 98, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .button-group li.active .button {
    background-color: #eee; }
/* line 103, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .content .tabs-content {
    margin: 10px 0; }
/* line 108, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .observation__time {
    height: auto;
    border: 0;
    margin: 0;
    width: 30%; }
/* line 176, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.survey-header .observation__time:before, .survey-header .observation__time:after {
    content: " ";
    display: table; }
/* line 177, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.survey-header .observation__time:after {
    clear: both; }
/* line 114, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .observation__time > div {
    width: 100%;
    padding: 0; }
/* line 120, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .button {
    font-size: 1.25rem;
    font-weight: 700;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif; }
/* line 124, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .button.secondary {
    background-color: #BBB;
    color: #fff; }
/* line 127, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .button.secondary:hover {
    background-color: #969696; }

/* line 134, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__details .survey-header__list {
    font-size: 0.875rem;
    font-weight: 400;
    color: #555;
    line-height: 1.4375rem; }
/* line 140, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__details .survey-header__list span {
    margin-left: 5px;
    font-weight: 400 !important;
    font-size: 0.75rem;
    font-style: italic;
    color: #999; }
/* line 148, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__details .survey-header__list select {
    font-size: 0.8125rem;
    line-height: 1.4375rem;
    height: 1.4375rem;
    padding: 0 1.3rem 0 0.625rem;
    margin: 0; }
/* line 156, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__details .survey-header__list li {
    padding: 0.3125rem 0;
    border: 0; }
/* line 161, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__details .survey-header__list .icon {
    font-size: 1.25rem;
    vertical-align: middle; }

/* line 167, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details {
    border: 2px solid #ddd;
    border-radius: 0.375rem;
    color: #333;
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
    padding: 0.9375rem; }
/* line 174, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details.collapsed {
    padding: 0; }
/* line 178, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details ul {
    list-style: none;
    margin: 0; }
/* line 183, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details li {
    padding: 0.625rem 0; }
/* line 176, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.survey-header .survey-header__details li:before, .survey-header .survey-header__details li:after {
    content: " ";
    display: table; }
/* line 177, /Users/ds/Documents/echo-static/source/bower_components/foundation/scss/foundation/components/_global.scss */
.survey-header .survey-header__details li:after {
    clear: both; }
/* line 188, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details span.heading {
    color: #6fc96f;
    font-weight: bold; }
/* line 193, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details span.icon-info {
    color: #ccc;
    font-size: 1rem; }
/* line 198, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details span.icon-certified-alt {
    font-size: 1.25rem;
    vertical-align: middle;
    margin-right: 5px; }
/* line 204, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details p {
    color: #555;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.0625rem;
    margin: 0.625rem 0; }
/* line 212, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details a {
    color: #555; }
/* line 216, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details input[type="text"] {
    background: #f3f3f3;
    border: 0;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    padding: 0 0.625rem;
    color: #333;
    margin: 10px 0 0;
    height: 2.1875rem;
    line-height: 2.1875rem;
    box-shadow: inset 0px 1px 0px 0px #ECECEC; }
/* line 231, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details .button {
    margin-bottom: 0;
    font-size: 0.875rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    height: 2.1875rem;
    line-height: 2.1875rem;
    padding: 0;
    margin: 0.625rem 0 0;
    color: #000;
    border: 1px solid #ccc;
    box-shadow: none;
    background: #eee; }

/* line 247, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__lang-list {
    -moz-column-count: 2;
    -moz-column-gap: 2.5em;
    -webkit-column-count: 2;
    -webkit-column-gap: 2.5em;
    column-count: 2;
    column-gap: 2.5em; }
/* line 254, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__lang-list li {
    display: block; }
/* line 257, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__lang-list li:before, .survey-header .survey-header__lang-list li:after {
    display: none; }
/* line 262, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__lang-list label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #333; }
/* line 268, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__lang-list input[type="checkbox"] {
    margin-right: 0.625rem; }

/* line 274, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__select-lang li {
    border-top: 1px dashed #333; }
/* line 276, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__select-lang li:first-child {
    border-top: 0; }
/* line 280, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__select-lang label {
    display: inline-block;
    width: 100px;
    font-size: 0.75rem;
    color: #333333;
    line-height: 1.0625rem;
    text-transform: uppercase;
    font-weight: 400; }
.survey-header .survey-header__select-lang div {
    display: inline-block;
    font-size: 0.75rem;
    color: #333333;
    line-height: 1.0625rem;
    text-transform: uppercase;
    font-weight: 400; }
.survey-header .survey-header__select-lang li {
    padding:1.25rem 1rem;
}
/* line 289, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__select-lang span {
    font-weight: 400;
    font-size: 1.125rem;
    color: #AAAAAA;
    padding:0 0 0 1rem; }
/* line 294, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__select-lang input[type="radio"]:checked ~ span {
    color: #333; }
/* line 297, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__select-lang input[type="radio"] {
    margin-bottom: 0; }

/* line 302, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock {
    width: 100%;
    background: #f3f3f3;
    font-size: 1rem;
    font-weight: 400;
    padding: 0 0.3125rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    box-shadow: inset 0px 1px 0px 0px #ECECEC;
    border-radius: 0.3125rem;
    margin: 10px 0 !important; }
/* line 313, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock div {
    vertical-align: middle; }
/* line 315, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock div .icon {
    vertical-align: middle; }
/* line 319, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock input {
    display: table-cell;
    font-weight: 400;
    font-size: 1rem;
    background: transparent;
    outline: 0; }
/* line 326, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock a {
    width: 60px;
    vertical-align: middle;
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: none;
    color: #999; }
/* line 333, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock a .icon {
    color: #999;
    vertical-align: -5px;
    text-decoration: none;
    font-size: 1.125rem; }
/* line 339, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__lock a span {
    text-decoration: underline;
    font-weight: 400;
    color: #999; }

/* line 347, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header.lang-select {
    height: 90%;
    overflow-y: hidden; }
/* line 350, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header.lang-select > .row {
    position: absolute;
    margin: auto;
    top: -50px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 320px; }

/* line 361, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.time__date-field {
    color: #555;
    background: #fff;
    border: 0;
    outline: 0;
    border-bottom: 1px dotted #999;
    max-width: 72%;
    margin-top: 10px; }
/* line 369, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.time__date-field.target-date {
    margin-top: 0; }

/* line 374, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-edit .ui-datepicker, .survey-header__details .ui-datepicker {
    position: absolute;
    right: 30px;
    max-width: 200px;
    background: #fff; }

/* line 382, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__upload .upload__dd {
    text-align: center;
    border: 2px dashed #ddd;
    padding: 1.25rem; }
/* line 386, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__upload .upload__dd:hover {
    border-color: #6fc96f; }
/* line 391, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__upload span.icon {
    display: inline-block;
    color: #6fc96f;
    font-size: 3.75rem;
    line-height: 3.75rem; }

/* line 398, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .ui-datepicker-trigger {
    font-family: "eleoticons";
    background: transparent;
    color: #666;
    font-size: 1.875rem;
    font-weight: normal;
    padding: 0;
    margin: 0;
    vertical-align: middle; }

/* line 409, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header .survey-header__details .survey-header__upload .button {
    color: #fff;
    font-weight: bold;
    background-color: #6fc96f;
    border-color: #6fc96f; }

/* line 416, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__auto-close {
    width: 70%; }
/* line 418, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__auto-close input[type="checkbox"] {
    display: inline; }
/* line 421, /Users/ds/Documents/echo-static/source/styles/echo/_survey-header.scss */
.survey-header__auto-close input[type="checkbox"] + label {
    display: inline;
    margin-right: 0; }

/* Survey content */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__content {
    margin-left: 280px;
    padding: 20px; }
/* line 6, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.row .survey-edit__content {
    margin-left: 0; }
/* line 10, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__content img {
    display: block;
    margin: 50px auto; }
/* line 14, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__content .button {
    display: block;
    margin-left: 0.875rem;
    margin-right: 0.875rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.125rem; }
/* line 21, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__content .button.secondary {
    color: #fff;
    background: #ccc; }
/* line 24, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__content .button.secondary:hover {
    background: #a3a3a3; }

/* line 32, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__p,
.survey-subheading__text p {
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: #333333;
    line-height: 1.5625rem; }

/* line 48, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-section {
    padding-top: 50px;
    padding-bottom: 50px; }

/* line 53, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-subsection {
    padding-top: 20px;
    padding-bottom: 50px;
    margin-left: 50px;
    border: 2px solid #fff; }
/* line 58, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified .survey-subsection {
    padding-left: 80px;
    border-left: 2px solid #eee;
    margin-left: 0; }
/* line 63, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-subsection.show-options {
    border: 2px solid #ddd;
    border-radius: 5px; }
/* line 67, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-subsection.rtl {
    margin-left: 0;
    margin-right: 50px; }
/* line 70, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified .survey-subsection.rtl {
    padding-left: 0;
    padding-right: 80px;
    border-left: 0;
    border-right: 2px solid #eee;
    margin: 0; }

/* line 79, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-subheading {
    padding-top: 20px;
    padding-bottom: 50px; }
/* line 82, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified .survey-subheading {
    padding-left: 30px;
    border-left: 2px solid #eee; }
/* line 87, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified .survey-subheading.rtl {
    border: 0;
    padding-right: 80px;
    border-right: 2px solid #eee; }
/* line 92, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-subheading.rtl {
    padding-right: 50px; }

/* line 98, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__q,
.survey__subheading {
    position: relative;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.3125rem;
    line-height: 1.5rem;
    color: #333333;
    margin: 20px 0; }
/* line 107, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__q, .show-options
.survey__subheading {
    padding-left: 20px;
    padding-right: 20px; }
/* line 113, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__q .survey__q-number,
.survey__q .survey__subheading-num,
.survey__subheading .survey__q-number,
.survey__subheading .survey__subheading-num {
    position: absolute;
    left: -50px;
    top: -4px;
    display: block;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 2px solid #979797;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.0625rem;
    color: #999999;
    line-height: 2rem;
    text-align: center; }
/* line 128, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__q .survey__q-number, .show-options
.survey__q .survey__subheading-num, .show-options
.survey__subheading .survey__q-number, .show-options
.survey__subheading .survey__subheading-num {
    background: #6fc96f;
    color: #fff;
    border: 2px solid #6fc96f; }
/* line 133, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__q .survey__q-number.rtl,
.survey__q .survey__subheading-num.rtl,
.survey__subheading .survey__q-number.rtl,
.survey__subheading .survey__subheading-num.rtl {
    left: auto;
    right: -50px; }

/* line 139, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__subheading {
    margin-left: 50px; }

/* line 143, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__answer {
    position: relative; }
/* line 146, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__answer {
    padding-left: 20px;
    padding-right: 20px; }

/* line 152, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice, .survey__mc {
    margin-bottom: 20px;
    position: relative; }
/* line 155, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice label, .survey__mc label {
    color: #333;
    font-size: 1.25rem;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4375rem; }
/* line 163, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice input:checked + label, .survey__mc input:checked + label {
    font-weight: 400; }
/* line 167, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice input[type="radio"], .survey__mc input[type="radio"] {
    display: none; }
/* line 171, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice input + label span:first-child, .survey__mc input + label span:first-child {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #979797;
    border-radius: 50%; }
/* line 181, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice input + label span:first-child.rtl, .survey__mc input + label span:first-child.rtl {
    margin-right: 0;
    margin-left: 15px; }
/* line 188, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice input:checked + label span:first-child:before, .survey__mc input:checked + label span:first-child:before {
    content: ' ';
    display: block;
    background: #979797;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
/* line 204, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice .autofill, .survey__mc .autofill {
    display: none;
    right: auto;
    left: 50px;
    width: 100%;
    max-width: 300px; }
/* line 211, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice .autofill:before, .survey__mc .autofill:before, .survey__choice .autofill:after, .survey__mc .autofill:after {
    left: 15%; }
/* line 214, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice .autofill ul, .survey__mc .autofill ul {
    list-style: none; }
/* line 217, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__choice .autofill li, .survey__mc .autofill li {
    font-size: 1.125rem; }

/* line 226, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__mc input[type="checkbox"] {
    display: none; }
/* line 229, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__mc input + label span:first-child {
    border-radius: 0; }
/* line 231, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__mc input + label span:first-child.rtl {
    margin-right: 0;
    margin-left: 15px; }
/* line 238, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__mc input:checked + label span:first-child:before {
    content: "\0066";
    font-family: eleoticons;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 130%;
    border-radius: 0; }

/* line 252, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__select select {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 97% center;
    border: 1px solid #979797;
    padding: 0.5rem;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    color: #333;
    line-height: normal;
    border-radius: 3px;
    height: 2.5625rem;
    width: 60%;
    min-width: 200px; }
/* line 270, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__select.rtl select {
    background-position: 3% center; }

/* line 276, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-page-break {
    display: table;
    white-space: nowrap; }
/* line 279, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-page-break:before, .survey-page-break:after {
    border-top: 1px solid #979797;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.75rem;
    width: 45%;
    vertical-align: middle; }
/* line 289, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-page-break span {
    display: table-cell;
    width: 10%;
    padding: 5px 20px;
    text-align: center;
    text-transform: lowercase;
    vertical-align: middle;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    color: #777; }
/* line 301, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-page-break span a {
    display: block;
    font-weight: 300;
    font-size: 0.875rem;
    text-decoration: underline;
    color: #777;
    margin: 5px 0; }

/* line 312, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating {
    display: table;
    table-layout: fixed;
    width: 100%;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2OcPn36fwAGWALGq5WbmgAAAABJRU5ErkJggg==");
    background-repeat: no-repeat;
    background-position: 50% 76%;
    margin-top: 20px;
    background-size: 80% 2px; }
/* line 322, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice {
    display: table-cell;
    position: relative;
    height: 64px;
    text-align: center; }
/* line 329, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice:first-child:before, .survey__rating .survey__rating-choice:last-child:before {
    content: " ";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
/* line 341, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice:last-child:before {
    left: auto;
    right: 0; }
/* line 347, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice input[type="radio"] {
    display: none; }
/* line 351, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice input + label span:first-child {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #979797;
    border-radius: 50%;
    line-height: 28px;
    color: #999;
    font-weight: 400; }
/* line 368, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice input:checked + label span:first-child:before {
    content: ' ';
    display: block;
    background: #979797;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
/* line 384, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label {
    display: block;
    position: relative;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    color: #333;
    font-size: 0.9375rem;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.0625rem;
    height: 64px; }
.survey__rating .survey__rating-choice-EDIT label {
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    color: #333;
    font-size: 0.9375rem;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.0625rem;
    height: 64px; }    
/* line 399, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label span,
.survey__rating .survey__rating-choice label textarea {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    margin: 0; }
/* line 405, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label span.survey__rating-emoji,
.survey__rating .survey__rating-choice label textarea.survey__rating-emoji {
    font-size: 2.25rem; }
/* line 408, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label span.na,
.survey__rating .survey__rating-choice label textarea.na {
    color: #999;
    font-size: 2rem; }
/* line 414, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label textarea {
    font-size: 0.9375rem;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.0625rem;
    text-align: center;
    padding: 0;
    height: auto;
    line-height: auto;
    color: #333;
    background: #fff;
    border: 0;
    box-shadow: none;
    width: 80%;
    height: 20px;
    min-height: 20px;
    resize: none;
    max-height: 50px;
    margin: auto;
    right: 0; }
/* line 434, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__rating .survey__rating-choice label textarea {
    border-bottom: 1px dashed #979797; }
/* line 437, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label textarea::-webkit-input-placeholder {
    color: #333; }
/* line 440, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label textarea:-moz-placeholder {
    color: #333; }
/* line 443, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label textarea::-moz-placeholder {
    color: #333; }
/* line 446, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice label textarea:-ms-input-placeholder {
    color: #333; }
/* line 452, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating .survey__rating-choice input:checked + label {
    font-weight: 400; }
/* line 460, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating.rtl .survey__rating-choice:first-child:before, .survey__rating.rtl .survey__rating-choice:last-child:before {
    content: " ";
    display: block;
    background: #fff;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0; }
/* line 472, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__rating.rtl .survey__rating-choice:last-child:before {
    left: 0;
    right: auto; }

/* line 481, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section {
    margin: 20px 0 40px;
    border-radius: 5px; }
/* line 485, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section ul {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 5px; }
/* line 491, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section li {
    width: 23%;
    border-left: 1px solid #eee; }
/* line 494, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section li:first-child {
    width: 8%;
    border-left: 0; }
/* line 500, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section span {
    vertical-align: middle;
    margin-right: 5px;
    color: #999;
    font-size: 1.25rem; }
/* line 507, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section .button.secondary {
    color: #777;
    font-size: 0.8125rem;
    font-weight: 400;
    background: #fff;
    box-shadow: none;
    margin: 0;
    height: 2.9375rem;
    line-height: 2.9375rem;
    padding: 0; }
/* line 517, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section .button.secondary:hover {
    background: #f4f4f4; }
/* line 522, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-section .button.disabled.secondary {
    background: #fff;
    color: #ccc;
    font-size: 0.8125rem;
    font-weight: 400;
    margin: 0; }

/* line 533, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified h3 span.icon {
    content: "\0041";
    font-family: "eleoticons";
    font-weight: 400;
    display: inline-block;
    vertical-align: -1px;
    font-size: inherit;
    margin-right: 20px;
    margin-left: 0; }
/* line 543, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified h3.rtl span.icon {
    margin-right: 0;
    margin-left: 20px; }
/* line 548, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified > span {
    float: right;
    color: #999;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.9375rem;
    margin-top: 10px; }
/* line 555, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.certified > span.rtl {
    float: left; }

/* line 563, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__subheading ~ .survey-subheading__text, .survey__subheading ~
.survey-subsection__text {
    margin-left: 50px; }
/* line 567, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-subheading__text p,
.survey-subsection__text p {
    line-height: 1.4;
    color: #333; }

/* line 574, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
textarea,
textarea:focus {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxuczpza2V0Y2g9Imh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaC9ucyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSI2NTYuOSA1Mi41IDggNjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgNjU2LjkgNTIuNSA4IDYwIj48dGl0bGU+R3JvdXA8L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPjxnIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiIHN0cm9rZT0iI2NjYyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtZGFzaGFycmF5PSIyLDYiIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0xMzI4LjEgMS41Ii8+PHBhdGggZD0iTTEuOSAxLjUiLz48cGF0aCBkPSJNMTMyNi4yIDU3LjUiLz48cGF0aCBkPSJNMS45IDU3LjUiLz48cGF0aCBkPSJNMS45IDExMS41aDEzMjQuMyIvPjwvZz48L3N2Zz4=");
    background-size: 4px 30px;
    background-color: transparent;
    background-attachment: scroll;
    background-position: 0 0;
    border: 0;
    min-height: 150px;
    box-shadow: none;
    font-size: 0.9375rem;
    line-height: 1.875rem;
    font-weight: 400;
    overflow: hidden; }

/* line 589, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-section__end {
    border-top: 1px solid #979797;
    color: #777;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 1.25rem 0;
    margin-bottom: 250px; }

/* line 599, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-sidebar__options {
    list-style: none;
    background: #efefef;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 0 0.625rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.6875rem; }
/* line 607, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-sidebar__options li {
    border-top: 1px solid #dfdfdf;
    padding: 0;
    margin: 0; }
/* line 611, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-sidebar__options li:first-child {
    border-top: 0; }
/* line 615, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#survey-sidebar__options a {
    padding: 0.3125rem 0; }

/* line 620, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__options {
    display: none;
    list-style: none;
    position: absolute;
    left: -66px;
    top: 40px; }
/* line 626, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__options {
    display: block; }
/* line 629, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__options li {
    margin-bottom: 10px;
    padding: 0;
    width: 28px;
    height: 28px; }
/* line 635, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__options a {
    display: block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border: 1px solid #888;
    border-radius: 5px;
    text-align: center;
    color: #999; }
/* line 645, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__options a:hover, .survey__options a.active {
    background: #999;
    color: #fff; }
/* line 650, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__options.rtl {
    left: auto;
    right: -48px;
    margin: 0; }

/* line 657, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__branching-select {
    display: none;
    margin-left: 50px; }
/* line 660, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options.branching-enabled .survey__branching-select {
    display: block; }
/* line 663, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__branching-select select {
    width: 110px;
    font-size: 0.8125rem;
    line-height: 1.4375rem;
    height: 1.4375rem;
    padding: 0 0.625rem;
    margin: 0;
    padding-right: 1.25rem; }
/* line 672, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__branching-select p {
    display: none;
    font-style: italic;
    font-weight: 400;
    margin-top: 5px; }

/* line 680, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__required {
    display: none;
    text-align: right; }
/* line 683, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__required {
    display: block; }
/* line 686, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__required label {
    color: #777;
    font-size: 0.75rem;
    font-weight: 400; }

/* line 694, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__q-text {
    width: 100%;
    border-bottom: 1px dashed #777; }
/* line 698, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q .survey__q-text .row {
    position: relative;
    top: -8px; }
/* line 702, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__q-text input[type="text"] {
    background: #fff;
    border: 1px solid #979797; }
/* line 708, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__q-text .button,
.survey__q-text .postfix,
.survey__q-text .prefix {
    font-weight: 400;
    font-size: 1.125rem;
    color: #555;
    outline: 0;
    background: #f5f5f5;
    border: 1px solid #979797;
    border-left: 0; }
/* line 716, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__q-text .button.rtl,
.survey__q-text .postfix.rtl,
.survey__q-text .prefix.rtl {
    border: 1px solid #979797;
    border-right: 0; }

/* line 725, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-edit__content > .survey-add-q.item, .survey-edit__content > .survey-add-q.section:last-child {
    display: none; }

/* line 730, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter {
    position: relative;
    list-style: none;
    margin: 0;
    display: block; }
/* line 735, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey-add-q__filter {
    display: none !important; }
/* line 738, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice {
    position: relative;
    display: block;
    background: #f1f1f1;
    border: 1px solid #999;
    font-size: 0.9375rem;
    color: #333;
    font-weight: 400;
    padding: 0.9375rem;
    padding-left: 4.0625rem;
    border-radius: 5px;
    margin: 0.625rem 0; }
/* line 750, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice:hover {
    background: #f1f1f1; }
/* line 753, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice.question {
    color: #777;
    background: #fafafa; }
/* line 757, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice.answers {
    line-height: 36px;
    font-size: 0.75rem;
    color: #777;
    background: #fafafa;
    padding: 0 1.25rem; }
/* line 765, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice span.toggle-choice {
    font-size: 1.375rem;
    display: block;
    float: left;
    border-right: 1px solid #999;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    top: 0;
    left: 0;
    width: 47px;
    height: 100%;
    line-height: 47px; }
/* line 779, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice span.icon-info {
    color: #777; }
/* line 782, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .filter-choice a {
    margin-left: 0.3125rem;
    color: #999; }
/* line 788, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter li {
    list-style: none;
    margin: 0; }
/* line 792, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter li ul {
    margin-left: 47px; }
/* line 796, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter .hide {
    display: none; }

/* line 801, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.f-dropdown.content.filter-choice__info, .content.filter-choice__info.new-survey__list, .content.filter-choice__info#lang_choices, .content.filter-choice__info.survey__tag-list {
    background-color: #333;
    color: #fff; }
/* line 804, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.f-dropdown.content.filter-choice__info:after, .content.filter-choice__info.new-survey__list:after, .content.filter-choice__info#lang_choices:after, .content.filter-choice__info.survey__tag-list:after {
    bottom: 100%;
    left: 88%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 51, 51, 0);
    border-bottom-color: #333333;
    border-width: 5px;
    margin-left: -5px; }

/* line 820, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter-hide {
    position: absolute;
    top: -35px;
    right: 0;
    text-align: right; }
/* line 825, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__filter-hide label {
    color: #777;
    font-size: 0.75rem;
    font-weight: 400; }

/* line 832, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
/* line 834, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey-add-q__select {
    display: block; }
/* line 837, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__select span {
    color: #333;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.875rem; }

/* line 845, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__type {
    min-height: 200px; }
/* line 848, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__type.survey-add-q__scale {
    min-height: 220px; }

/* line 853, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__select-type {
    display: block;
    width: 100%;
    color: #aaa;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 400;
    background: #fff;
    border: 0;
    border-bottom: 1px dashed #777;
    padding-bottom: 5px;
    background-image: url("./images/select-down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 9px 4px;
    height: 1.875rem;
    line-height: 1.875rem;
    padding: 0;
    outline: 0;
    margin-bottom: 1rem;
    -moz-appearance: none; }
/* line 872, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__select-type:hover {
    background-color: #fff; }
/* line 875, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__select-type.active {
    color: #555; }

/* line 881, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__pick-one input[type="text"], .survey-add-q__pick-mc input[type="text"] {
    display: inline-block;
    width: 200px;
    background: #fff;
    border: 0;
    border-bottom: 1px dashed #777;
    box-shadow: none;
    padding: 0;
    height: 1.875rem;
    line-height: 1.875rem;
    margin-bottom: 0;
    font-size: 1.25rem; }
/* line 894, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__pick-one span.icon-close, .survey-add-q__pick-mc span.icon-close {
    display: none;
    color: #ccc;
    vertical-align: -4px;
    margin-bottom: 0;
    margin-left: 0.625rem; }
/* line 900, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey-add-q__pick-one span.icon-close, .show-options .survey-add-q__pick-mc span.icon-close {
    display: inline; }

/* line 906, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.pick-one__filled {
    display: inline-block;
    min-width: 200px; }
/* line 910, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .pick-one__filled {
    border-bottom: 1px dashed #777; }

/* line 915, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__add-more {
    display: none;
    position: relative;
    vertical-align: middle;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: #ccc;
    margin-left: 0.5rem; }
/* line 929, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__mc .survey-add-q__add-more {
    border-radius: 0; }
/* line 932, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey-add-q__add-more {
    display: inline-block; }

/* line 937, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__save {
    padding-top: 50px;
    padding-bottom: 20px;
    overflow: hidden; }
/* line 941, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__save .button {
    margin: 0; }

/* line 951, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__dropdown textarea {
    background: #fff;
    border: 1px solid #979797;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.625rem;
    width: 335px;
    min-height: 128px;
    box-shadow: none; }

/* line 964, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__text-field textarea {
    min-height: 35px; }
/* line 966, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-add-q__text-field textarea.long {
    min-height: 95px; }

/* line 972, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.text-field__options, .scale__options {
    display: none;
    width: 100%;
    max-width: 300px;
    position: absolute;
    bottom: 2px; }
/* line 978, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .text-field__options, .show-options .scale__options {
    display: block; }
/* line 981, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.text-field__options label, .scale__options label {
    display: inline-block;
    color: #777;
    font-size: 0.75rem;
    font-weight: 400; }
/* line 987, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.text-field__options input[type="text"], .scale__options input[type="text"] {
    display: inline-block;
    width: 40px;
    border: 0;
    border-bottom: 1px dashed #979797;
    padding-top: 0;
    padding-bottom: 0;
    height: 20px;
    line-height: 20px;
    color: #777;
    font-size: 0.75rem;
    font-weight: 400;
    box-shadow: none;
    background: #fff; }

/* line 1004, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options {
    max-width: 310px;
    color: #777;
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 20px;
    position: absolute;
    bottom: 2px; }
/* line 1013, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options .button-group {
    width: 100%;
    max-width: 188px;
    margin-top: 5px;
    margin-bottom: 10px; }
/* line 1018, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options .button-group li {
    margin: 0;
    padding: 0;
    width: 33%;
    border: 1px solid #999; }
/* line 1023, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options .button-group li:first-child {
    border-right: 0; }
/* line 1026, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options .button-group li:last-child {
    border-left: 0; }
/* line 1030, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options .button-group .button {
    margin: 0;
    background: #fff;
    color: #777;
    font-size: 0.9375rem;
    border: 0; }
/* line 1037, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.scale__options .button-group .button:active, .scale__options .button-group .button.selected {
    background: #f5f5f5;
    color: #000; }

/* line 1045, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
input.na-option + label {
    margin-right: 0; }

/* line 1049, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-sticky-heading {
    margin: 0;
    background: #fff;
    border: 0;
    position: relative;
    z-index: 1;
    height: 50px;
    line-height: 50px; }
/* line 1057, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-sticky-heading.fixed {
    position: fixed;
    width: 200px;
    left: 272px;
    top: 50px;
    min-height: 5px;
    width: 100%;
    z-index: 999;
    padding-left: 48px; }
/* line 1066, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-sticky-heading.fixed.rtl {
    width: calc(100% - 272px);
    padding-right: 48px; }
/* line 1071, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-sticky-heading span.icon {
    content: "\0041";
    font-family: "eleoticons";
    font-weight: 400;
    display: inline-block;
    vertical-align: -1px;
    font-size: inherit;
    margin-right: 20px;
    margin-left: 0; }
/* line 1081, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-sticky-heading input {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    background: #fff;
    border: 0;
    box-shadow: none;
    padding-left: 0; }
/* line 1089, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey-sticky-heading input:focus {
    background: #fff;
    box-shadow: none; }

/* line 1097, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select {
    display: none;
    position: fixed;
    top: 70px;
    right: 20px;
    z-index: 9999;
    width: 160px;
    background: #fff;
    text-align: right; }
/* line 1106, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.open #lang_select {
    display: block; }
/* line 1109, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select.rtl {
    right: auto;
    left: 292px;
    text-align: left; }
/* line 1114, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select select {
    background-color: #fff;
    border-right: 10px solid #fff;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: #777777;
    height: 1.125rem;
    line-height: 1.125rem;
    box-shadow: none;
    margin-bottom: 0; }
/* line 1127, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select > a {
    color: #777;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem; }
/* line 1132, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select > a:after {
    content: "\0067";
    font-family: eleoticons;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
    font-size: 0.875rem;
    margin-left: 0.625rem; }
/* line 1143, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select.rtl > a:after {
    content: ""; }
/* line 1146, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_select.rtl > a:before {
    content: "\0067";
    font-family: eleoticons;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
    font-size: 0.875rem;
    margin-right: 0.625rem; }

/* line 1158, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_choices {
    background: #efefef;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 0 0.625rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.6875rem;
    margin-top: 10px;
    text-align: left;
    min-width: 200px; }
/* line 1169, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_choices li {
    border-top: 1px solid #dfdfdf;
    padding: 0; }
/* line 1172, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_choices li:first-child {
    border-top: 0; }
/* line 1176, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_choices a {
    padding: 0.625rem 0 !important; }
/* line 1178, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
#lang_choices a:after {
    content: "\0069";
    font-family: eleoticons;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
    font-size: 0.875rem;
    margin-left: 0.625rem;
    float: right; }

/* line 1191, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__tag-container {
    margin-top: 0.625rem;
}
/* line 1194, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.show-options .survey__tag-container {
    display: block; }

/* line 1199, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__add-tag {
    display: inline-block;
    font-size: 0.75rem;
    color: #666;
    font-weight: 400;
    text-transform: lowercase;
    text-decoration: underline;
    clear: left; }
/* line 1207, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__add-tag.rtl {
    margin-right: 10px; }

/* line 1212, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__tag-list {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px; }
/* line 1217, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__tag-list li a {
    font-size: 0.8125rem;
    font-weight: 400;
    padding: 0.3125rem 0.625rem; }
/* line 1223, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__tag-list.rtl {
    margin-left: -160px; }

/* line 1228, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__tag-item, .reports__tag-item {
    display: inline-block;
    color: #666;
    background-color: #f6f6f6;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0 0.3125rem;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    border-radius: 0.125rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16); }
/* line 1240, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.survey__tag-item .icon-close, .reports__tag-item .icon-close {
    color: #ccc;
    font-size: 0.625rem;
    margin-left: 0.3125rem; }
/* line 1246, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.rtl .survey__tag-item, .rtl .reports__tag-item {
    text-align: right; }
/* line 1249, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.rtl .survey__tag-item .icon-close, .rtl .reports__tag-item .icon-close {
    display: inline-flex; }

/* line 1247, /Users/ds/Documents/echo-static/source/styles/echo/_survey-content.scss */
.new-section__delete {
    display: block;
    font-weight: 300;
    font-size: 0.875rem;
    text-decoration: underline;
    color: #777;
    margin: 5px 0; }

/* Report Header */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.page-container.reports {
    padding: 0;
    background-color: #eee;
    height: calc(100% - 180px); }
/* line 5, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.page-container.reports.title-saved {
    height: calc(100% - 150px); }

/* line 10, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header {
    background-color: #fff;
    padding: 0.625rem 1.25rem;
    height: 130px;
    color: #999;
    font-size: 0.75rem;
    font-weight: 400;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 10;
    overflow: hidden; }
/* line 21, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header.title-saved {
    height: 100px; }
/* line 25, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header h5 {
    color: #666;
    margin: 0; }
/* line 30, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header .button {
    text-decoration: none; }
/* line 34, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header .button.secondary {
    margin-bottom: 0.625rem;
    font-size: 0.9375rem;
    color: #666;
    padding-top: 0.3125rem;
    padding-bottom: 0.375rem;
    border-radius: 2px; }

/* line 44, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__title {
    font-size: 0.9375rem;
    color: #333;
    font-weight: 600; }
/* line 49, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__title input[type="text"] {
    height: 2.0625rem;
    color: #666;
    background: #fff;
    border: 1px solid #ccc;
    font-size: 0.9375rem;
    box-shadow: inset 0 1px 0 #ececec;
    margin-top: 0.625rem;
    margin-bottom: 0.5rem; }
/* line 60, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__title .button {
    font-size: 1.125rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.25rem;
    margin-top: 1.5625rem; }
/* line 67, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__title p {
    color: #6fc96f;
    font-size: 1.4375rem;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-align: right;
    margin-top: 1.5625rem; }
/* line 74, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__title p span {
    font-size: 1.625rem;
    vertical-align: middle; }

/* line 83, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header-details span,
.reports__header-details strong {
    margin-right: 0.5rem; }
/* line 87, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header-details span.icon-certified-alt {
    font-size: 1rem;
    vertical-align: middle; }
/* line 92, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header-details a {
    color: #666;
    text-decoration: underline; }
/* line 96, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.rtl .reports__header-details a {
    display: inline-block;
    margin-right: 5px; }

/* line 98, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__header-buttons {
    display: none;
    position: absolute;
    width: 100% !important;
    top: 10px; }

/* line 105, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by {
    margin-top: 0; }
/* line 112, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.rtl .reports__group-by[dir="ltr"] {
    text-align: right; }
/* line 115, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by a {
    display: inline-block;
    padding: 0.3125rem;
    color: #999;
    text-decoration: underline; }
/* line 113, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by .filter__select {
    display: none;
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
/* line 117, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by .filter__select.show {
    display: inline-block; }
/* line 120, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by .filter__select select {
    font-size: 0.75rem;
    height: auto;
    line-height: 1;
    padding: 0.3125rem;
    color: #666; }
/* line 128, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by .group-by__add {
    color: #666;
    display: none; }
/* line 131, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by .group-by__add.show {
    display: inline-block; }
/* line 135, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by span.text {
    display: inline-block; }
/* line 137, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__group-by span.text.show {
    display: inline-block; }

/* line 143, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.reports__tag-item {
    padding: 0.3125rem;
    margin-left: 0.625rem; }

/* line 149, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.survey-header__list {
    list-style: none;
    font-size: 0.875rem;
    font-weight: 400;
    color: #555;
    line-height: 1.4375rem; }
/* line 156, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.survey-header__list span {
    margin-left: 5px;
    font-weight: 400 !important;
    font-size: 0.75rem;
    font-style: italic;
    color: #999; }
/* line 164, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.survey-header__list select {
    font-size: 0.8125rem;
    line-height: 1.4375rem;
    height: 1.4375rem;
    padding: 0 0.625rem;
    margin: 0; }
/* line 172, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.survey-header__list li {
    padding: 0.3125rem 0;
    border: 0; }
/* line 177, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.survey-header__list .icon {
    font-size: 1.25rem;
    vertical-align: middle; }

/* line 185, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal h4 {
    color: #333;
    font-size: 1.1875rem;
    font-weight: 400;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif; }
/* line 190, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal h4 span {
    color: #555;
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 400; }
/* line 197, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal p.lead {
    color: #777;
    font-size: 0.875rem; }
/* line 202, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal ul {
    list-style: none;
    margin: 0; }
/* line 207, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal label {
    font-size: 0.9375rem;
    font-weight: 400;
    color: #333; }
/* line 213, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal input[type="text"] {
    height: 2.0625rem;
    text-align: left !important; }
/* line 218, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal span {
    color: #999;
    font-size: 0.9375rem;
    font-weight: 400;
    font-style: italic; }
/* line 225, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-share-modal .button {
    padding: 0.375rem 0;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0; }

/* line 235, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-modal__list li {
    overflow: hidden;
    margin-bottom: 1.25rem; }
/* line 240, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-modal__list input {
    float: left;
    margin-top: 0.1875rem;
    margin-left: 0.3125rem; }
/* line 246, /Users/ds/Documents/echo-static/source/styles/echo/_report-header.scss */
.report-modal__list span {
    display: block;
    margin-left: 1.75rem; }

/* Report Sidebar */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports__sidebar {
    position: fixed;
    color: #333;
    background: #eee;
    height: calc(100% - 300px);
    font-size: 0.75rem;
    font-weight: 400;
    width: 210px;
    padding: 0.625rem;
    line-height: 1.8;
    left: 0;
    /*top: 300px;*/
    overflow: auto;
    margin-top: -10px;}

.reports__sidebar .actions {
    /*position: fixed;*/
    padding-top: 0.625rem;
    color: #333;
    background: #eee;
    /*top: 210px;*/
    left: 0;
    width: 180px;
    height: 80px;
    line-height: 1.8;
}
/* line 14, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.title-saved .reports__sidebar {
    height: calc(100% - 150px);
    }
/* line 19, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports__sidebar label {
    font-size: 0.75rem;
    font-weight: 400;
    color: #333; }
/* line 25, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports__sidebar input[type="checkbox"] {
    margin-bottom: 0;
    margin-right: 0.3125rem; }
/* line 29, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports__sidebar.rtl input[type="checkbox"] {
    margin-right: 0;
    margin-left: 0.3125rem; }
/* line 34, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports__sidebar .button.secondary,
.reports__sidebar__action .button.secondary {
    margin-bottom: 0.625rem;
    font-size: 0.9375rem;
    color: #666;
    padding-top: 0.3125rem;
    padding-bottom: 0.375rem;
    border-radius: 2px; }

/* line 40, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports-sidebar__filter {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
/* line 44, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports-sidebar__filter ul {
    list-style: none;
    margin: 0; }
/* line 49, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports-sidebar__filter li {
    position: relative;
    margin-left: 0.3125rem;
    margin-bottom: 0.3125rem;
    margin-top: 0.3125rem;
    padding-left: 0.75rem; }
/* line 59, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.rtl .reports-sidebar__filter li {
    padding-left: 0;
    padding-right: 0.75rem; }
/* line 65, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports-sidebar__filter input {
    position: absolute;
    left: -0.3125rem;
    top: 0.125rem; }
/* line 69, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.rtl .reports-sidebar__filter input {
    left: 0;
    right: -0.3125rem; }
/* line 76, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports-sidebar__filter input:checked + span,
.reports-sidebar__filter input:checked + label {
    font-weight: bold; }
/* line 68, /Users/ds/Documents/echo-static/source/styles/echo/_report-sidebar.scss */
.reports-sidebar__filter a {
    color: #999;
    font-weight: 400;
    text-decoration: underline; }

/* Report Content */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__content {
    background: #fff;
    margin-left: 210px;
    padding: 30px;
    min-height: 100%;
    color: #777;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow: hidden; }
/* line 10, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__content h6 {
    font-size: 1.125rem;
    font-weight: 300;
    color: #000; }
/* line 15, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__content h6 span {
    display: inline-flex; }

/* line 16, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.tabs-content > .content.reports-wide {
    position: relative;
    background: #fff;
    right: 0;
    top: -10px;
    height: 100%;
    margin-left: calc(-100% - 15px);
    padding: 1.25rem 0.3125rem; }
/* line 29, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.rtl .tabs-content > .content.reports-wide {
    margin-left: 0;
    margin-right: calc(-100% + 20px); }
/* line 34, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.tabs-content > .content.reports-wide h5 {
    color: #000;
    font-weight: 300; }
/* line 29, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.tabs-content > .content.reports-wide table {
    width: 100%; }
/* line 31, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.tabs-content > .content.reports-wide table td {
    color: #666;
    font-size: 0.75rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0; }
/* line 45, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.rtl .tabs-content > .content.reports-wide table td {
    text-align: right; }
/* line 50, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.tabs-content > .content.reports-wide table tr td:nth-of-type(2) {
    font-weight: 600; }

/* line 46, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__list span.icon-certified-alt {
    margin-top: 0;
    margin-right: 0.3125rem;
    font-size: 1.125rem;
    vertical-align: middle; }
/* line 54, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__list tr td:nth-of-type(3),
.echo-reports__list tr td:nth-of-type(4),
.echo-reports__list tr td:last-child {
    font-style: italic; }
/* line 57, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__list.rtl {
    text-align: right; }
/* line 61, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__list.rtl tr th,
.echo-reports__list.rtl tr td {
    text-align: right; }
/* line 77, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__list td[dir="rtl"] {
    direction: rtl;
    text-align: right; }
/* line 81, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__list td[dir="rtl"] .icon {
    float: right;
    margin-right: 0;
    margin-left: 5px; }

/* line 67, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__options {
    font-style: normal; }
/* line 70, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__options span.icon {
    margin-top: 0; }
/* line 74, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__options.rtl li {
    overflow: hidden; }
/* line 77, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.echo-reports__options.rtl span.icon {
    float: right;
    margin-right: 0; }

/* line 85, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal h4, .report-share-modal h4 {
    margin: 0; }
/* line 89, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal p.lead, .report-share-modal p.lead {
    font-size: 0.9375rem;
    font-weight: 400; }
/* line 94, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal strong, .report-share-modal strong {
    display: inline-block;
    color: #333;
    font-size: 0.9375rem;
    margin-bottom: 0.3125rem; }
/* line 101, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal input[type="text"], .report-share-modal input[type="text"] {
    margin-bottom: 0;
    color: #999;
    font-size: 1rem;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 #ececec;
    position: relative;
    z-index: 1;
    text-align: center; }
/* line 115, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal .right, .report-share-modal .right {
    color: #999;
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 0.25rem; }
/* line 121, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal .right a, .report-share-modal .right a {
    color: #666; }
/* line 126, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal .button, .report-share-modal .button {
    top: 0;
    line-height: normal; }

/* line 132, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.autofill-input {
    position: relative; }
/* line 134, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.autofill-input:before {
    position: absolute;
    display: block;
    content: '\006F';
    font-size: 1.875rem;
    font-family: "eleoticons";
    z-index: 10;
    top: 5px;
    left: 5px; }
/* line 144, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.autofill-input input {
    padding-left: 2.1875rem; }

/* line 149, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal__options {
    margin-top: 1.25rem;
    padding: 0.625rem 0; }
/* line 152, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-modal__options .button {
    padding: 0.375rem 0;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0; }

/* line 161, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select, .multiple-select__group {
    max-height: 230px;
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 rgba(236, 236, 236, 0.9);
    margin: 0;
    padding: 0; }
/* line 171, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select input[type="checkbox"], .multiple-select__group input[type="checkbox"],
.multiple-select input[type="radio"],
.multiple-select__group input[type="radio"] {
    display: none; }
/* line 176, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select input[type="checkbox"] + label, .multiple-select__group input[type="checkbox"] + label,
.multiple-select input[type="radio"] + label,
.multiple-select__group input[type="radio"] + label {
    display: block;
    color: #333;
    font-size: 0.9375rem;
    font-weight: 400;
    margin: 0;
    padding: 0.3125rem; }
/* line 186, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select input[type="checkbox"]:checked + label, .multiple-select__group input[type="checkbox"]:checked + label,
.multiple-select input[type="radio"]:checked + label,
.multiple-select__group input[type="radio"]:checked + label {
    background-color: #f6f6f6; }
/* line 190, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select span.icon-certified-alt, .multiple-select__group span.icon-certified-alt {
    vertical-align: middle;
    margin-right: 0.3125rem; }

/* line 196, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group {
    margin-top: -3px;
    border-radius: 0 0 3px 3px; }
/* line 200, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group input[type="checkbox"] {
    position: absolute;
    display: block;
    top: 10px;
    left: 8px; }
/* line 207, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group input[type="checkbox"] + label {
    display: block;
    color: #333;
    font-size: 0.9375rem;
    font-weight: 400;
    margin: 0;
    padding: 0.3125rem;
    padding-left: 1.875rem; }
/* line 217, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group a {
    opacity: 0;
    color: #999;
    font-size: 0.75rem;
    text-decoration: underline;
    margin-left: 0.625rem; }
/* line 224, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group li:hover a {
    opacity: 1; }
/* line 228, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group label span {
    opacity: 0;
    color: #999;
    font-size: 0.75rem;
    margin-left: 0.625rem; }
/* line 234, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group .open label:hover span {
    opacity: 1; }
/* line 238, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group li {
    position: relative; }
/* line 242, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul {
    display: none;
    background-color: #fff;
    list-style-type: none;
    margin: 0; }
/* line 247, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul input[type="checkbox"] {
    left: 38px; }
/* line 250, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul input[type="checkbox"] + label {
    padding-left: 3.75rem; }
/* line 256, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul ul input[type="checkbox"] {
    left: 68px; }
/* line 259, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul ul input[type="checkbox"] + label {
    padding-left: 5.625rem; }
/* line 265, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul ul ul input[type="checkbox"] {
    left: 98px; }
/* line 268, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group ul ul ul input[type="checkbox"] + label {
    padding-left: 7.5rem; }
/* line 273, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.multiple-select__group li.open ul {
    display: block; }

/* line 279, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.group__toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer; }

/* line 290, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.button.clear {
    background: transparent;
    border: 1px solid #fff;
    box-shadow: none;
    color: #979797;
    font-size: 1.125rem;
    font-weight: 400; }

/* line 299, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-bar {
    position: fixed;
    width: 100%;
    height: 82px;
    bottom: 0;
    left: 0;
    background-color: rgba(246, 246, 246, 0.92);
    z-index: 99;
    padding: 1.5625rem 0; }
/* line 309, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.new-report-bar .button {
    padding: 0.375rem 0;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif; }

/* line 317, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.calendar__heading {
    margin-bottom: 1.25rem; }
/* line 319, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.calendar__heading strong {
    font-size: 0.875rem; }

/* line 324, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.calendar {
    margin-bottom: 1.25rem; }

/* Start Custom jQuery UI Datepicker CSS */
/* line 330, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.ui-datepicker {
    width: 100%; }

/* line 334, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.ui-datepicker .ui-datepicker-title {
    font-weight: bold; }

/* line 339, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.ui-datepicker td,
.ui-datepicker th {
    border: 1px solid #e5e5e5;
    font-weight: 400;
    padding: 0; }
/* line 343, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.ui-datepicker td.ui-datepicker-other-month,
.ui-datepicker th.ui-datepicker-other-month {
    background-color: #e5e5e5; }
/* line 346, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.ui-datepicker td a.ui-state-active,
.ui-datepicker th a.ui-state-active {
    background-color: #def3de;
    font-weight: bold; }

/* line 353, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    font-family: "eleoticons"; }

/* End Custom jQuery UI Datepicker CSS */
/* line 359, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__filter-bar.alert-box.secondary {
    color: #949494;
    font-size: 0.875rem;
    font-weight: 400; }
/* line 363, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__filter-bar.alert-box.secondary span {
    margin-right: 0.3125rem; }
/* line 365, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__filter-bar.alert-box.secondary span:first-child {
    font-weight: 600; }
/* line 369, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__filter-bar.alert-box.secondary .icon {
    font-size: 1rem;
    vertical-align: -4px; }
/* line 373, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.reports__filter-bar.alert-box.secondary a {
    color: #666;
    font-size: 0.75rem;
    margin-left: 0.3125rem; }

/* line 380, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.graph-container {
    margin-bottom: 3.125rem; }
/* line 382, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.graph-container .graph-container {
    display: none;
    margin-left: 1.875rem;
    margin-bottom: 0; }

/* line 390, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.report-graph .bar-graph {
    position: relative;
    margin-left: 300px;
    padding: 0;
    margin-bottom: 10px; }
/* line 395, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.report-graph .bar-graph .bar-graph__bar {
    position: relative;
    background-color: #6fc96f;
    height: 26px;
    padding: 0;
    line-height: 26px;
    width: 0; }
/* line 402, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.report-graph .bar-graph .bar-graph__bar:after {
    content: attr(data-width);
    color: #777;
    font-size: 0.9375rem;
    font-weight: bold;
    height: 26px;
    line-height: 26px;
    position: absolute;
    right: -40px;
    top: 0; }
/* line 434, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.rtl .report-graph .bar-graph .bar-graph__bar:after {
    right: auto;
    left: -40px; }
/* line 439, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.report-graph .bar-graph .bar-graph__bar.short:after {
    right: -70px; }
/* line 441, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.rtl .report-graph .bar-graph .bar-graph__bar.short:after {
    right: auto;
    left: -70px; }
/* line 447, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.report-graph .bar-graph span {
    padding-left: 0.625rem;
    color: #fff;
    line-height: 26px;
    font-size: 0.9375rem;
    font-weight: 400; }
/* line 453, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.rtl .report-graph .bar-graph span {
    padding-left: 0;
    padding-right: 0.625rem; }
/* line 458, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.report-graph .bar-graph .label {
    display: block;
    position: absolute;
    width: 280px;
    left: -280px;
    color: #777;
    background-color: transparent;
    text-align: left;
    padding: 0;
    top: 0;
    white-space: pre-line;
    font-family: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1rem; }
/* line 472, /Users/ds/Documents/echo-static/source/styles/echo/_report-content.scss */
.rtl .report-graph .bar-graph .label, .report-graph .bar-graph .label.rtl {
    text-align: right; }

/* Admin */
/* line 1, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter {
    font-weight: 400;
    padding-bottom: 20px; }
/* line 4, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter > div:first-child {
    border: 1px solid #ccc;
    border-right: 0;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    text-align: center; }
/* line 12, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter > div:first-child + div {
    border: 1px solid #ccc;
    border-left: 0;
    overflow: hidden;
    position: relative;
    height: 30px; }
/* line 19, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter > div:first-child + div > div {
    position: absolute;
    padding: 0 0 10px;
    overflow-x: scroll;
    white-space: nowrap; }
/* line 26, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter input {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    background: #fff !important;
    border: 0;
    outline: 0;
    box-shadow: none !important; }

/* line 41, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__tag {
    display: inline-block;
    background: #F6F6F6;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    font-size: 12px;
    color: #666666;
    line-height: 22px;
    height: 22px;
    padding: 0 5px;
    vertical-align: 3px; }
/* line 52, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__tag i {
    margin-left: 5px;
    vertical-align: -2px; }

/* line 58, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter {
    font-weight: 400; }
/* line 60, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter > a {
    display: block;
    padding-left: 5px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #666;
    text-decoration: underline; }
/* line 69, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter ul.f-dropdown, .manage-users-filter__filter ul.new-survey__list, .manage-users-filter__filter ul#lang_choices, .manage-users-filter__filter ul.survey__tag-list {
    min-width: 105px;
    background: #F6F6F6;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.5), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
    border-radius: 3px; }
/* line 74, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter ul.f-dropdown li, .manage-users-filter__filter ul.new-survey__list li, .manage-users-filter__filter ul#lang_choices li, .manage-users-filter__filter ul.survey__tag-list li {
    position: relative; }
/* line 76, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter ul.f-dropdown li a, .manage-users-filter__filter ul.new-survey__list li a, .manage-users-filter__filter ul#lang_choices li a, .manage-users-filter__filter ul.survey__tag-list li a {
    color: #666;
    padding: 5px;
    font-size: 13px; }
/* line 82, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter ul.f-dropdown li ul, .manage-users-filter__filter ul.new-survey__list li ul, .manage-users-filter__filter ul#lang_choices li ul, .manage-users-filter__filter ul.survey__tag-list li ul {
    display: none;
    position: absolute;
    left: 80%;
    top: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #F6F6F6;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.5), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
    border-radius: 3px; }
/* line 94, /Users/ds/Documents/echo-static/source/styles/echo/_admin.scss */
.manage-users-filter__filter ul.f-dropdown li:hover ul, .manage-users-filter__filter ul.new-survey__list li:hover ul, .manage-users-filter__filter ul#lang_choices li:hover ul, .manage-users-filter__filter ul.survey__tag-list li:hover ul {
    display: block; }


.content.ng-enter {
    transition:0.5s linear all;
    transform:translateY(-100px);
}
.content.ng-enter.ng-enter-active {
    transform:translateY(0);
}

  div.round {
    border: 2px solid #e2dada;
    border-radius: 5px;
    padding: 6px 4px;
    display: table-cell;
    background: #fafafa;
}
.pii-toggle-container, .pi-text, .pi-info-icon{
    display: table-cell;
    padding: 1px;
    vertical-align: middle;
}
.align-middle{
    vertical-align: middle;
}
.pi-text{
    font-weight: 600;
    color: #666;
    font-size: 0.875rem
}
.role-select{
    width: 100% !important;
}
.pii-info-text{
    font-family: monospace;
}

.manage-user{
    padding-top: 3px !important;
}
.manage-user > div > label{
    color: black;
    font-size: inherit
}
.manage-user > h3{
    margin-top: -3px;
    padding-bottom: 14px;
}
.white{
    color: white;
}
.left-aligned{
    width: 105% !important;
    margin-left: -10px;
}
.pii-star-icon{
    color:#6fc96f;
    font-weight: bold;
}
.submit-btn{
    font-weight: 500;
}