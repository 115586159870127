.open-close-date-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  &.no-margin {
    margin: 0;
  }
}

.moment-picker-container.day-view {
  table tr {
    th:first-child {
      display: none;
    }
    th:nth-child(3) {
      display: none;
    }
    th:hover{
      background: rgba(0,0,0,0);
    }
  }
}

.admin-date-time-title {
  padding-top: 20px;
}

.custom-moment-picker.datetime-picker {
  display: inline-block;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;

  .input-group-addon {
    padding: 6px 6px 6px 9px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: transparent;
    border: none;

    &:first-child {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  input {
    width: 165px;
    height: 33px;
    vertical-align: -1px;
    padding: 6px;
    border: none;
    border-left: 1px solid #ccc;

    &:disabled {
      cursor: not-allowed;
    }
  }

  i.octicon {
    font-family: "eleoticons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    vertical-align: -4px;
    font-size: 19px;

    &.octicon-calendar:before {
      content: "6";
    }

    &.octicon-clock:before {
      content: "8";
    }
  }
}
