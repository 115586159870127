.browse-content {
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 15px;

  .new-survey__list {
    display: none;
    max-width: 200px;
  }

  .browse-content-filter {
    display: none;
  }

  .exit-off-canvas {
    z-index: 5000;
    position: fixed;
  }

  .left {
    font-size: 1.875rem;
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
  }
  .search-filter {
    border-radius: 5px;
    height: 33px;
    line-height: 100%;
    .icon {
      color: #ccc;
      font-size: 1.5rem;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    input {
      color: #666;
      background: transparent;
      font-size: 1.0625rem;
      font-weight: 400;
      height: 29px;
      line-height: 1.0625rem;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      box-shadow: none;
      width: 89%;
    }
    .icon-close {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }
    .f-dropdown {
      max-width: 97%;
      left: 6px !important;
    }
    &.open {
      border: 1px solid #ddd;
      input, .icon-close {
        visibility: visible;
        opacity: 1;
        position: relative;
      }
    }
  }
  .icon {
    &[data-icon="o"] {
      font-size: 1.875rem;
      display: inline-block;
      vertical-align: middle;
    }
    display: inline-block;
    vertical-align: middle;
  }
  input {
    border: 0;
    outline: 0;
    height: 31px;
    line-height: 100%;
    margin: 0;
    display: inline-block;
  }
  .f-dropdown {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: #f7f7f7;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0;
  }
  .medium-5.columns, .medium-8.columns {
    float: right;
  }
  .filter__select-mcc select {
    background-color: white;
    background-image: url("./images/select-down-arrow.svg");
    background-size: 15px 8px;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 2.0625rem;
    line-height: 2.0625rem;
    padding: 0 10px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 240px 13px;
    cursor: pointer;
  }
  .browse-content-filter {
    z-index: 99999;
    position: absolute;
  }
  .tab {
    white-space: nowrap;
    position: relative;
    height: inherit;
    height: 38px;
    position: relative;
    bottom: -11px;
    > .tablinks {
      z-index: 1000;
      top: 0px;
    }
  }
  .tablinks + .tablinks {
    transform: skew(-41deg);
    border-top-left-radius: 0;
    border-top-right-radius: 45px;
    .tablinksInner {
      transform: skew(60deg);
    }
  }
  .tab-container {
    overflow-y: hidden;
    height: 50px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    width: 94%;
    display: inline-block;
    top: 2px;
  }
  .master-tab-container .fa {
    margin-top: 5px;
    color: #6fc96f;
    cursor: pointer;
  }
  .tablinks {
    background-color: #FFFFFF;
    margin-left: 4px;
    margin-top: 2px;
    border: 1px solid #DEDEDE;
    color: #AFAFAF;
    height: 102%;
    margin: 0;
    padding: 0;
    width: 212px;
    outline: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 18px;
    &.active-tab {
      background-color: #6fc96f;
      color: #FFFFFF;
      border: 1px solid #DEDEDE;
      z-index: 1001 !important;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      border-bottom: none;
      top: -2px;
    }
  }
  .tablinksInner {
    background-color: #FFFFFF;
    color: #AFAFAF;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 187px;
    transform: skew(40deg);
    border: 1px solid #DEDEDE;
    border-left: none;
    border-bottom: none;
    border-top: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    border-top-left-radius: 100px;
    margin-left: 30px;
    &.active-tab {
      background-color: #6fc96f;
      color: #FFFFFF;
      border: 1px solid #DEDEDE;
      border-left: none;
      border-bottom: none;
      border-top: none;
      z-index: 25;
      margin-left: 30px;
      border-top-left-radius: 10px;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .too-long-text-tab {
    .tablinksInner {
      &:hover {
        height: 100%;
        position: relative;
        left: 10px;
        width: 182px;
      }
      .tab-text {
        &.tab-text-too-long:hover {
          overflow: visible;
        }
        .tab-text-too-long {
          white-space: nowrap;
          overflow: hidden;
          width: 176px;
          text-overflow: ellipsis;
          position: relative;
          &:hover {
            overflow: visible;
          }
        }
      }
    }
    &.tablinks:hover .tab-text-too-long, .tablinksInner:hover .tab-text-too-long {
      overflow: visible;
    }
    &.tablinks:hover .extended-ellipsis-text {
      line-height: 5px;
      white-space: normal;
      position: relative;
      left: -18px;
      top: -3px;
    }
    .tablinksInner {
      &:hover .extended-ellipsis-text, .tab-text .tab-text-too-long:hover .extended-ellipsis-text {
        line-height: 5px;
        white-space: normal;
        position: relative;
        left: -18px;
        top: -3px;
      }
    }
    &.tablinks:hover {
      position: relative;
      top: -10px;
      height: 125%;
      .tablinksInner {
        height: 100%;
        position: relative;
        left: 9px;
        width: 182px;
      }
    }
  }
  .tab-text-longer {
    &.too-long-text-tab.tablinks:hover {
      top: -3px;
    }
    .tablinksInner:hover {
      .tab-text-container {
        top: 4px;
        left: -7px;
      }
      .tab-text {
        font-size: 10px;
        line-height: 10px;
      }
    }
    &.tablinks:hover {
      .tab-text-container {
        top: 7px;
        left: -2px;
      }
      .tab-text {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
  .tab-text-longest {
    &.too-long-text-tab.tablinks:hover {
      top: -3px;
    }
    .tablinksInner:hover {
      .tab-text-container {
        top: 4px;
        left: -7px;
      }
      .tab-text {
        font-size: 9px;
        line-height: 12px;
      }
    }
    &.tablinks:hover {
      .tab-text-container {
        top: 11px;
        left: 2px;
      }
      .tab-text {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }
  .too-long-text-tab.tab-text-longest {
    &.tablinks:hover .extended-ellipsis-text {
      line-height: 5px;
      white-space: normal;
      position: relative;
      left: -18px;
      top: -8px;
    }
    .tablinksInner {
      &:hover .extended-ellipsis-text, .tab-text .tab-text-too-long:hover .extended-ellipsis-text {
        line-height: 5px;
        white-space: normal;
        position: relative;
        left: -18px;
        top: -8px;
      }
    }
    &.tablinks:hover {
      position: relative;
      top: -14px;
      height: 125%;
    }
  }
  .tab-text {
    font-size: 13px;
    line-height: 17px;
    transform: skew(-40deg);
    border-top-left-radius: 10px;
    padding-right: 20px;
    white-space: nowrap;
  }
  .active-tab .tab-text {
    overflow: visible;
    white-space: nowrap;
    padding-right: 17px;
  }
  .tab-text-container {
    position: relative;
    top: 9px;
  }
  .tablinks .tablinksInner:not(.active-tab) {
    margin-top: 1px;
    border-bottom: 1px solid #e5e5e5;
  }
  .prefix {
    color: #AFAFAF;
    font-family: "Open Sans";
    font-size: 18px;
    line-height: 24px;
    border: none;
    white-space: nowrap;
  }
  .search-filter__wrap {
    width: 68%;
  }
  .filter-text {
    width: 12%;
  }
  .table-header {
    height: 40px;
    width: 100%;
    background-color: #e5e5e5;
    display: table;
    color: #6fc96f;
    .type-col {

      display: table-cell;
      height: 100%;
      padding: 12px;
      padding-left: 20px;
      color: black;
      font-size: 14px;
      line-height: 16px;
    }
    .contact-name-col {
      display: table-cell;
      height: 100%;
      vertical-align: top;
      padding: 12px;
      color: black;
      font-size: 14px;
      line-height: 16px;
    }
    .description-col {
      display: table-cell;
      height: 100%;
      padding: 12px;
      color: black;
      font-size: 14px;
      line-height: 16px;
    }
    .actions-col {
      height: 100%;
      display: table-cell;
      text-align: center;
      padding: 12px;
      padding-right: 20px;
      color: black;
      font-size: 14px;
      line-height: 16px;
    }
    .status-col {
      height: 100%;
      display: table-cell;
      padding: 12px;
      padding-right: 20px;
      color: black;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .target-table {
    z-index: 2000;
    position: relative;
    top: -1px;
  }
  .target-table-row {
    height: 70px;
    width: 100%;
    background-color: #FFFFFF;
    display: table;
    border-bottom: 2px solid #f3f3f3;
    .type-col {
      color: #3D3D3D;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      display: table-cell;
      //height: 100%;
      padding: 12px;
      padding-left: 20px;
    }
    .contact-name-col {
      color: #3D3D3D;
      font-size: 14px;
      line-height: 18px;
      display: table-cell;
      height: 100%;
      padding: 12px 12px 12px 8px;

      .content-name-container {
        display: block; //fallback for non webkit browsers
        display: -webkit-inline-box;
        white-space: initial;
        width: 200px;
        vertical-align: text-top;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .description-col {
      color: #3D3D3D;
      font-size: 14px;
      line-height: 18px;
      display: table-cell;
      height: 100%;
      padding: 12px 12px 12px 8px;

      .desc-text {
        display: block; //fallback for non webkit browsers
        display: -webkit-box;
        white-space: initial;
        max-height: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
    .actions-col {
      color: #999999;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      display: table-cell;
      height: 100%;
      padding: 12px;
      padding-left: 2px;
      text-align: center;
    }
    .status-col {
      color: #3D3D3D;
      font-size: 14px;
      line-height: 16px;
      display: table-cell;
      height: 100%;
      padding: 12px 12px 12px 8px;
    }
    .vertEllipsis {
      cursor: pointer;
      color: #aaa;
    }
  }
  .browse-content__options_dashboard {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .browse-content__options {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .browse-content_certified-contents__options {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .browse-content {
    &.search-filter .icon:before {
      line-height: inherit;
    }
    &.icon:before {
      line-height: 24px;
    }
  }
  .table-data-content-with-icon.icon.mod-table-icon {
    vertical-align: middle;
    margin-top: 0 !important;
  }
  .mcc-left-arrow {
    position: relative;
    float: left;
    font-size: 30px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ddd;
    background-color: white;
    z-index: 20;
    border-top-left-radius: 5px;
    top: 13px;
    width: 3%;
    height: 38px;
  }
  .mcc-right-arrow {
    float: right;
    position: relative;
    top: 13px;
    font-size: 30px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ddd;
    background-color: white;
    z-index: 9999;
    border-top-right-radius: 5px;
    width: 3%;
    height: 38px;
  }
  .hideText {
    visibility: hidden;
    color: white;
  }
  .master-tab-container .disabledArrow {
    color: #e5e5e5;
    cursor: not-allowed;
  }
  .under-five-tabs {
    overflow: initial;
    overflow-y: initial;
  }
  .icon-cert-container {
    display: inline-block;
    width: 20px;
  }
  .hideShortenedEllipsis, .hideExtendedEllipsis {
    display: none;
  }
  .highlighted {
    background: yellow;
  }
  .sort-arrow-icon {
    position: relative;
    top: 3px;
  }
  .no-results-text {
    text-align: center;
    color: #666;
    font-size: 0.9375rem;
    padding: 0.9375rem 0.625rem;
    font-family: "Open Sans", "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  .hide-span {
    position: relative;
    top: -18px;
    left: 50px;
    display: none;
    width: 50px;
    height: 20px;
    cursor: pointer;
  }
  .actionsOpenContainer {
    font-size: 0.875rem;
    color: #666;
    text-align: left;
    width: 91%;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-weight: 400;
  }
  .icon-close {
    top: 4px;
  }
  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  .search-filter input::-ms-clear {
    display: none;
  }
  .action-menu-align-left{
    text-align: left;
  }
  .filter__select--dropdown{
    width: 19%;
  }
  .search-filter-container{
    width: 100%;
  }
  .under-five-tabs-tab-container{
    width: 100%;
  }
}

@media (min-width: 1500px) {
  .browse-content .filter__select-mcc select {
    background-position: 240px 13px;
  }
}

/*target internet explorer 10 and 11*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .browse-content {
    .tab-container .tab button:not(.too-long-text-tab) {
      top: -14px;
    }
    .tablinks .tablinksInner:not(.active-tab) {
      margin-top: 0;
      border-bottom: none;
    }
    .f-dropdown {
      left: 800px;
    }
    .tablinks + .tablinks {
      border-top-right-radius: 35px;
    }
    .too-long-text-tab.tablinks:hover {
      border-top-right-radius: 39px;
    }
    .manage-custom-content__filter .prefix {
      position: relative;
      right: 4px;
    }
    .noTooLongLabs {
      top: 0 !important;
    }
    .tablinks {
      border-top-right-radius: 16px;
    }
  }
}

/*target Edge*/
@supports (-ms-ime-align: auto) {
  //.browse-content {
  //  .tab-container .tab button:not(.too-long-text-tab) {
  //    top: -24px;
  //  }
  //  .tablinks .tablinksInner:not(.active-tab) {
  //    margin-top: 0;
  //    border-bottom: none;
  //  }
  //  .f-dropdown {
  //    display: none;
  //  }
  //  .tablinks + .tablinks {
  //    border-top-right-radius: 38px;
  //  }
  //  .too-long-text-tab.tablinks:hover {
  //    border-top-right-radius: 39px;
  //  }
  //  .manage-custom-content__filter .prefix {
  //    position: relative;
  //    right: 4px;
  //  }
  //  .noTooLongLabs {
  //    top: 0 !important;
  //  }
  //  .tablinks {
  //    border-top-right-radius: 18px;
  //  }
  //}
    .browse-content .tab-container .tab button:not(.too-long-text-tab) {
      top: -22px;
    }

    .browse-content .tablinks .tablinksInner:not(.active-tab) {
      margin-top: 0;
      border-bottom: none;
    }

    .browse-content .f-dropdown {
      left: 800px;
    }

    .browse-content .tablinks + .tablinks {
      border-top-right-radius: 35px;
    }

    .browse-content .too-long-text-tab.tablinks:hover {
      border-top-right-radius: 39px;
    }

    .browse-content .manage-custom-content__filter .prefix {
      position: relative;
      right: 4px;
    }

    .browse-content .noTooLongLabs {
      top: 0 !important;
    }

    .browse-content .tablinks {
      border-top-right-radius: 16px;
    }
}

/*target firefox only*/
@-moz-document url-prefix() {
  .browse-content {
    .tab-container .tab button:not(.too-long-text-tab) {
      top: -4px;
    }
    .tab-container .tab button.too-long-text-tab:hover .tablinks .tablinksInner:not(.active-tab) {
      margin-top: 0;
      border-bottom: none;
    }
    .search-filter input {
      height: 31px;
    }
    .manage-custom-content__filter .prefix {
      position: relative;
      right: 4px;
    }
    .tablinks + .tablinks {
      border-top-right-radius: 36px;
    }
    .too-long-text-tab.tablinks:hover {
      border-top-right-radius: 41px;
    }
    .noTooLongLabs {
      top: 0 !important;
    }
  }
}

/*!*target safari only*/
_::-webkit-full-page-media, _:future, :root .inner-wrap {

  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;

}
