.template-config-error {
  color: #D54230;
  margin: 10px 0;
  font-size: 14px;
}

.survey-header {
  .survey-header__details {
    .publish-distribution-info {
      & > p {
        color: #555;
        font-size: .85rem;
        font-weight: 400;
        line-height: 1.4rem;
        margin: 0.75rem 0;
      }
      & > p:first-child {
        margin-top: 0;
      }
    }
  }
}

.confirm-assign-banner {
  background-color: #FEFEE3;
  height: 50px;
  line-height: 50px;
  width: 100%;
  display: block;
  position: relative;
  font-weight: bold;
  padding: 0 40px;
  font-size: 20px;
  color: #333;

  i {
    font-size: 24px;
    color: #148900;
    &:last-of-type {
      float:right;
      color: #333;
      line-height: 48px;
    }
  }
}

a.publish-template {
  &.linkDisabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.cc-fix > input::-ms-clear {
  display: none !important;
}

.cc-fix {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  max-width: none !important;
}

.ccd-fix {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bc-fix {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

input::-ms-clear {
  display: none;
}

.survey-sidebar__content {
  div.popover-icon.pull-right {
    margin-right: 10px;
    margin-top: -4px;
  }

  .sidebar-title {
    text-indent: 0;
  }
}
