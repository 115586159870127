@charset "UTF-8";

@font-face {
  font-family: "eleoticons";
  src:url("eleoticons/eleoticons.eot");
  src:url("eleoticons/eleoticons.eot?#iefix") format("embedded-opentype"),
    url("eleoticons/eleoticons.woff") format("woff"),
    url("eleoticons/eleoticons.ttf") format("truetype"),
    url("eleoticons/eleoticons.svg#eleoticons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "eleoticons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "eleoticons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "a";
}
.icon-arrow-left:before {
  content: "b";
}
.icon-arrow-right:before {
  content: "c";
}
.icon-arrow-up:before {
  content: "d";
}
.icon-check:before {
  content: "f";
}
.icon-chevron-down:before {
  content: "g";
}
.icon-chevron-left:before {
  content: "h";
}
.icon-chevron-right:before {
  content: "i";
}
.icon-chevron-up:before {
  content: "j";
}
.icon-close:before {
  content: "k";
}
.icon-doc:before {
  content: "l";
}
.icon-eye:before {
  content: "m";
}
.icon-update:before {
  content: "n";
}
.icon-search:before {
  content: "o";
}
.icon-pencil:before {
  content: "p";
}
.icon-offline:before {
  content: "q";
}
.icon-note:before {
  content: "r";
}
.icon-menu:before {
  content: "s";
}
.icon-key:before {
  content: "t";
}
.icon-info:before {
  content: "u";
}
.icon-certified:before {
  content: "e";
}
.icon-phone:before {
  content: "v";
}
.icon-reporting:before {
  content: "w";
}
.icon-settings:before {
  content: "x";
}
.icon-users:before {
  content: "y";
}
.icon-reset:before {
  content: "z";
}
.icon-certified-alt:before {
  content: "A";
}
.icon-cloud-upload:before {
  content: "B";
}
.icon-youtube:before {
  content: "C";
}
.icon-twitter:before {
  content: "D";
}
.icon-facebook:before {
  content: "E";
}
.icon-email-envelope:before {
  content: "F";
}
.icon-source-down-arrow:before {
  content: "G";
}
.icon-linkedin:before {
  content: "H";
}
.icon-copy:before {
  content: "L";
}
.icon-delete:before {
  content: "M";
}
.icon-dropdown:before {
  content: "N";
}
.icon-message:before {
  content: "O";
}
.icon-opinion-scale:before {
  content: "P";
}
.icon-options:before {
  content: "Q";
}
.icon-page-break:before {
  content: "R";
}
.icon-pick-multiple:before {
  content: "S";
}
.icon-pick-one:before {
  content: "T";
}
.icon-question:before {
  content: "U";
}
.icon-text-field:before {
  content: "V";
}
.icon-section:before {
  content: "X";
}
.icon-add:before {
  content: "I";
}
.icon-archive:before {
  content: "J";
}
.icon-branching:before {
  content: "K";
}
.icon-lock:before {
  content: "W";
}
.icon-opinion-5-strongly-agree:before {
  content: "0";
}
.icon-opinion-4-agree:before {
  content: "Z";
}
.icon-opinion-3-neutral:before {
  content: "1";
}
.icon-opinion-2-disagree:before {
  content: "2";
}
.icon-opinion-1-strongly-disagree:before {
  content: "3";
}
.icon-block:before {
  content: "4";
}
.icon-unlock:before {
  content: "5";
}
.icon-calendar:before {
  content: "6";
}
.icon-survey:before {
  content: "Y";
}
.icon-systems:before {
  content: "7";
}
.icon-clock:before {
  content: "8";
}
.icon-minus:before {
  content: "9";
}
