
// .popover-icon {
//   font-size: 4rem;
// }

// .popover-icon .icon-info.mod-icon-large:before {
// 	font-size: 1.5rem;
// }
//
// .popover-icon.mod-aside-popover.mod-centered {
//  	font-size: 13px;
// 	font-family: "Open Sans", Arial, Helvetica, sans-serif;
// 	color: #fff;
// 	margin: 0;
// 	// margin-left: -20px;
// 	// width: 180px;
// }

.tooltip-trigger-icon {
	color: #b3b3b3;
	font-size: 1.25rem;
	vertical-align: middle;
	margin-left: 6px;
}

.tooltip-trigger-icon:focus {
	outline: 0;
}

.tooltip-trigger-icon.mod-table-absolute {
	position: absolute;
	right: -27px;
	top: 3px;
}

.icon.icon-info.mod-inline-info-icon {
	font-size: 1.5rem;
	vertical-align: middle;
	color: #ccc;
}

.popover-icon.mod-aside-popover.mod-centered .joyride-content-wrapper {
	padding: 10px;
}

.popover-icon.mod-aside-popover.mod-open-top-left .joyride-tip-guide {
	margin-left: -140px;
	width: 180px;
}

.popover-icon.mod-aside-popover.mod-open-top-left .joyride-tip-guide .joyride-nub {
	margin-left: 120px;
}

.popover-icon.mod-aside-popover.mod-open-top-left .joyride-content-wrapper {
	padding: 10px;
}

.popover-icon.mod-aside-popover .joyride-tip-guide {
	width: 215px;
}

.popover-icon.mod-aside-popover .joyride-tip-guide p,
.popover-icon.mod-aside-popover .joyride-tip-guide .admin-upload-roster ul,
.admin-upload-roster .popover-icon.mod-aside-popover .joyride-tip-guide ul {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	margin: 0;
}

.user-management .popover-icon,
.add-user-form .popover-icon {
	display: inline-block;
	position: absolute;
}

.popover-icon.mod-above-icon.mod-centered .joyride-tip-guide {
	margin-left: -20px;
}

.popover-icon.mod-above-icon.mod-centered .joyride-tip-guide .joyride-content-wrapper {
	padding: 0.875rem;
}

.popover-icon.mod-above-icon.mod-centered.mod-line-height .joyride-tip-guide {
	width: 110%;
}

.popover-icon.mod-above-icon.mod-centered.mod-line-height .joyride-tip-guide .joyride-content-wrapper p,
.popover-icon.mod-above-icon.mod-centered.mod-line-height .joyride-tip-guide .joyride-content-wrapper .admin-upload-roster ul,
.admin-upload-roster .popover-icon.mod-above-icon.mod-centered.mod-line-height .joyride-tip-guide .joyride-content-wrapper ul {
	line-height: 1.4;
}

.tooltip.tip-top.fade {
	background: rgba(51, 51, 51, 0.72);
	text-align: left;
	.nub {
		color: rgba(51, 51, 51, 0.72);
		opacity: 0.72 !important;
	}
}

.tooltip.tip-top.fade.in span.nub {
	color: rgba(51, 51, 51, 0.72);
	opacity: 0.72 !important;
}

.tooltip.tip-top.fade.in span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-top.fade span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-right.fade {
	background: rgba(51, 51, 51, 0.72);
	text-align: left;
	.nub {
		color: rgba(51, 51, 51, 0.72);
		opacity: 0.72 !important;
	}
}

.tooltip.tip-right.fade.in span.nub {
	color: rgba(51, 51, 51, 0.72);
	opacity: 0.72 !important;
}

.tooltip.tip-right.fade.in span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-right.fade span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-left.fade {
	background: rgba(51, 51, 51, 0.72);
	text-align: left;
	.nub {
		color: rgba(51, 51, 51, 0.72);
		opacity: 0.72 !important;
	}
}

.tooltip.tip-left.fade.in span.nub {
	color: rgba(51, 51, 51, 0.72);
	opacity: 0.72 !important;
}

.tooltip.tip-left.fade.in span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-left.fade span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-bottom.fade {
	background: rgba(51, 51, 51, 0.72);
	text-align: left;
	.nub {
		color: rgba(51, 51, 51, 0.72);
		opacity: 0.72 !important;
	}
}

.tooltip.tip-bottom.fade.in span.nub {
	color: rgba(51, 51, 51, 0.72);
	opacity: 0.72 !important;
}

.tooltip.tip-bottom.fade.in span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}

.tooltip.tip-bottom.fade span {
	font-size: 13px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	opacity: 0.95 !important;
}
