
.manage-users-confirmation-modal {
    padding: 0 0 50px 0;
    width: 495px;
  
    h2 {
      margin: 0;
      background-color: #148900;
      color: white;
      padding: 20px 0 8px;
  
      
  
      span {
        color: white;
        font-style: normal;
        font-size: 24px;
        font-weight: 700;
        vertical-align: 15px;
      }
    }
  
    p {
      padding: 38px 48px 0 48px;
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
  
      .top-text {
        margin-bottom: 22px;
      }
  
      .weighted-text {
        font-weight: 700;
      }
    }
  
    button {
      float: right;
      border-radius: 3px;
      width: 115px;
      height: 43px;
      color: white;
  
      &.cancel {
        background-color:  #148900 !important;
        margin-right: 50px;
        width: auto !important
      }
  
      &.confirm {
        background-color: #D0D0D0 !important;
        box-shadow: none !important;
      }
    }
  }

.manage-users-tooltip-relative-wrapper {
  position: relative;
  margin-left: 5px;
  display: inline-block;
  .manage-users-tooltip-absolute-wrapper {
    position: absolute;
    width: 500px;
    bottom: 0;
    right: 0;
    text-align: right;
    .tooltip {
      top: auto !important; // We have to override the top and left styles placed directly on the element by the
      left: auto !important; // angular-foundation library.
      right: -7px !important;
      bottom: 21px;
      width: 500px !important;  // override for proper width in IE11
      .nub {
        right: 5px;
        left: auto;
        top: auto;
        @media screen and (max-width: 800px) { // We have to rotate the nub 180 degrees and reposition for it to appear
          bottom: -10px;                      // correctly on screens with widths below 800px.
          transform: rotate(0.5turn);
        }
      }
    }
  }
}

.signup__input--text .signup__form--text {
  input {
    line-height: 1.0625rem !important;
  }
}