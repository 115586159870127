.report-status {

  .status_header {
    color: #6fc96f;
    font-style: normal;
  }

  .submitted {
    color: black;
    font-style: normal;
    font-size: 0.85rem;
  }

  a {
    color: #4049BC;
    font-size: 0.85rem;
  }
}

.group-by-item {
  display: inline-block;
  color: #666;
  background-color: #f6f6f6;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 0.3125rem;
  margin: 0.625rem;
  margin-left: 0;
  border-radius: 0.125rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  width: 100%;

  .icon-close {
    color: #ccc;
    float: right;
  }

}

.activity-summary-modal {
  border-radius: 10px;
  border: 1px solid black;
  padding: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40%;


  .row {
    margin: 0;
    max-width: 100%;

    .summary-loading-spinner {
      font-size: 6rem;
      color: black;
      padding: 2vh;

      i {
        padding: 2vh;
      }
    }

    .summary {
      color: black;
      padding: 2vh;
      p {
        margin-bottom: 3rem;
      }
      i {
        color: green;
      }
    }

  }

  .header {
    background-color: #EEEEEE;
    color: black;
    font-size: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px 10px 0 0;

    div:first-child {
      line-height: 2em;
    }

    i {
      font-size: 2em;
      cursor: pointer;
    }
  }


  .latest-activity {
    padding: 20px;
  }

  .tabs-content {
    margin: 0;
  }

  .dashboard-demographic-graph {
    margin-top: 2vh;

    .demo-bar-graph {
      padding: 0.625rem 0;

      .label {
        padding: 0.625rem 0;
      }

      .response-count {
        padding: 0.625rem 0;
      }
    }
  }

  .respondants {
    margin: 0;
  }

  a {
    color: grey;
    font-size: 2rem;
  }
}


button.hollow {
  background-color: Transparent;
  border: 1px solid black;
  color: black;
  margin-bottom: 0;
  font-size: 1rem;
  padding: 1.5vh 2.5vw;
}
